import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LandingHeader } from "../../components/LandingHeader/LandingHeader";
import Loader from "../../images/loader/Render.gif";
import confirm from "../../images/Icon/confirm 1.png";
import { format } from "date-fns";
import "./Qeuedetails.css";
import AllPDF from "../../components/AllPDF/AllPDF";
import BookingPDF from "../../components/AllPDF/BookingPDF";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";
import { AdminHeader } from "../../components/AdminHeader/AdminHeader";
import Ticketed from "./../../components/Queues/Ticketed/Ticketed";

const Queuedetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = secureLocalStorage.getItem("user-info");
  const customerId = user?.data[0]?.customerId;
  const odId = location?.state?.bookingData?.response?.data?.id;
  const orderId = location?.state?.data?.OrderId || odId;
  const [isLoading, setIsLoading] = useState(false);

  const [allData, setAllData] = useState([]);
  const [flightData, setFlightData] = useState([]);
  const [passengerData, setPassengerData] = useState([]);
  const [fareTotal, setFareTotal] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  const url = `https://api.gallpax.com/v1/Duffel/AirRetrieve.php?orderId=${orderId}`;
  useEffect(() => {
    setIsLoading(true);

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setIsLoading(true);
        setAllData(data);
        setPassengerData(data?.Passengers);
        setFlightData(data?.Segments);
        setFareTotal(data?.fares);

        // setInvoiceId(data[0]?.ticketData);
      });
    setIsLoading(false);
  }, []);

  console.log(allData);

  const bookingId =
    location?.state?.bookingData?.bookingId || location?.state.data.BookingId;

  const handleSubmit = async () => {
    let url = "https://api.gallpax.com/v1/AirTicketing/IssueRequest.php";
    let body = JSON.stringify({
      customerId: customerId,
      bookingId: bookingId,
    });
    await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setBtnLoading(true);
        if (data?.status === "success") {
          Swal.fire({
            icon: "success",
            title: `${data?.status}`,
            html: `${data?.message} For any query.Please contact us at <strong>kayesthechill@gmail.com</strong> or Call <strong>$+8801685370455 </strong>`,
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Ok",
          }).then(function () {
            navigate(0);
          });
        } else {
          Swal.fire({
            icon: "error",
            title: `${data?.status}`,
            html: `${data?.message}`,
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Ok",
          }).then(() => {
            navigate(0);
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: err.message,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        }).then(() => {
          navigate(0);
        });
      });
  };

  if (!isLoading) {
    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "70vh",
          width: "70vw",
          marginInline: "auto",
        }}
      >
        <Box
          style={{
            width: "50%",
            height: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Loader}
            alt="loader"
            style={{
              width: "40%",
              objectFit: "center",
            }}
          />
        </Box>
      </Box>
    );
  }
  console.log("location", location);
  return (
    <Box>
      {location?.state?.adminPath === "admin" ? (
        // <AdminHeader />
        <></>
      ) : (
        <LandingHeader />
      )}

      <Container>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={9.5}>
            <Box sx={{ marginTop: "20px" }}>
              {location.state.path === "mybooking" ? null : (
                <Box display="flex" my={3}>
                  <Box marginRight={2}>
                    <img src={confirm} alt="..." />
                  </Box>
                  <Box>
                    <Typography variant="h5" color="#fff">
                      Thank You. Your Booking is Confirmed
                    </Typography>
                    <Typography color="#fff">
                      A Confirmation email has been sent to your provided email
                      address.
                    </Typography>
                  </Box>
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    width: "fit-content",
                    background: "var(--primary-color)",
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: "500",
                    padding: "10px 5px",
                    borderRadius: "4px",
                  }}
                >
                  Booking Id: {bookingId || "BookingId"}
                </Typography>

                <Typography
                  sx={{
                    width: "fit-content",
                    background: "var(--primary-color)",
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: "500",
                    padding: "10px 50px",
                    borderRadius: "4px",
                  }}
                >
                  {allData?.Status || ""}
                </Typography>
              </Box>

              {allData?.Status === "Ticketed" ? (
                ""
              ) : (
                <Typography
                  sx={{
                    my: 3,
                    color: "var(--white)",
                    fontSize: "15px",
                    fontWeight: "500",
                    borderRadius: "4px",
                  }}
                >
                  Time Limit:{" "}
                  {allData?.TimeLimit
                    ? format(
                        new Date(allData?.TimeLimit),
                        "dd MMM yyyy hh:mm a"
                      )
                    : "No Time Limit"}
                </Typography>
              )}
              <Box my={2}>
                <Typography
                  sx={{
                    width: "100%",
                    background: "var(--third-color)",
                    color: "var(--secondary-color)",
                    padding: "2px 5px",
                    borderRadius: "4px",
                  }}
                >
                  Flight Information
                </Typography>
                <Box className="flight-queue-detail">
                  <Box mt={2}>
                    <table width="100%">
                      <tr>
                        <th width="15%">Flight</th>
                        <th width="23%">Departure From</th>
                        <th width="23%">Arrival To</th>
                        <th width="12%">Depart At</th>
                        <th width="12%">Arrive At</th>
                        <th width="15%">Info</th>
                      </tr>

                      {flightData?.length === 0 ? (
                        <Box>Loading...</Box>
                      ) : (
                        <>
                          {flightData?.map((data, index) => (
                            <tr>
                              <td>
                                {data?.MarkettingCarrierName}{" "}
                                {data?.MarkettingCarrier}-
                                {data?.MarkettingCarrierNumber}
                                <br />
                                {data?.AircraftModel}
                              </td>
                              <td>
                                ({data.DepFrom}) {data?.DepAirport}
                              </td>
                              <td>
                                ({data.ArrAirport}) {data?.ArrAirport}
                              </td>
                              <td>
                                {new Date(data?.DepTime).toLocaleString(
                                  "en-uk",
                                  {
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: false,
                                  }
                                )}
                              </td>
                              <td>
                                {new Date(data?.ArrTime).toLocaleString(
                                  "en-uk",
                                  {
                                    daya: "numeric",
                                    month: "short",
                                    year: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: false,
                                  }
                                )}
                              </td>
                              <td>{allData?.CabinClass}</td>
                            </tr>
                          ))}
                        </>
                      )}
                    </table>
                  </Box>
                </Box>
              </Box>

              <Box my={3}>
                <Typography
                  sx={{
                    width: "100%",
                    background: "var(--third-color)",
                    color: "var(--secondary-color)",
                    padding: "2px 5px",
                    borderRadius: "4px",
                  }}
                >
                  Passenger Information
                </Typography>
                <Box mt={2} className="queue-detail-passenger-detail">
                  <div>
                    {passengerData?.length === 0 ? (
                      <>loading...</>
                    ) : (
                      <>
                        {passengerData?.map((data) => (
                          <>
                            <Box
                              p="3px 5px"
                              border="1px solid #DEDEDE"
                              borderRadius="4px"
                              my="2px"
                              display={"flex"}
                              justifyContent={"space-between"}
                              width={"100%"}
                            >
                              <h5
                                style={{
                                  color: "var(--primary-color)",
                                  fontWeight: "500",
                                  fontSize: "15px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {data?.title} {data?.given_name}{" "}
                                {data?.family_name}
                              </h5>
                            </Box>

                            <Box
                              border="1px solid #DEDEDE"
                              borderRadius="4px"
                              p="3px 5px"
                              mb={2}
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={4} md={2}>
                                  <h5>Title</h5>
                                  <h6 style={{ textTransform: "capitalize" }}>
                                    {data?.title}
                                  </h6>
                                </Grid>
                                <Grid item xs={4} md={2}>
                                  <h5>First Name</h5>
                                  <h6 style={{ textTransform: "capitalize" }}>
                                    {data?.given_name}{" "}
                                  </h6>
                                </Grid>
                                <Grid item xs={4} md={2}>
                                  <h5>Last Name</h5>
                                  <h6 style={{ textTransform: "capitalize" }}>
                                    {data?.family_name}
                                  </h6>
                                </Grid>

                                <Grid item xs={4} md={2}>
                                  <h5>Date of Birth</h5>
                                  <h6>
                                    {data?.born_on
                                      ? format(
                                          new Date(data?.born_on),
                                          "dd MMM yyyy"
                                        )
                                      : "Date of Birth"}
                                  </h6>
                                </Grid>

                                <Grid item xs={4} md={2}>
                                  <h5>Gender</h5>
                                  <h6>
                                    {data?.gender === "m" ? "Male" : "Female"}
                                  </h6>
                                </Grid>

                                <Grid item xs={4} md={2}>
                                  <h5>Pax Type</h5>
                                  <h6 style={{ textTransform: "capitalize" }}>
                                    {data?.type}
                                  </h6>
                                </Grid>
                              </Grid>
                            </Box>
                          </>
                        ))}
                      </>
                    )}
                  </div>
                </Box>
                {/* passenger information end */}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={2.5}>
            {allData?.Status === "Hold" && (
              <>
                {!btnLoading ? (
                  <Typography
                    sx={{
                      cursor: "pointer",
                      color: "#fff",
                      bgcolor: "var(--primary-color)",
                      py: 1,
                      textAlign: "center",
                      borderRadius: "10px",
                    }}
                    onClick={handleSubmit}
                  >
                    Make Payment
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      cursor: "pointer",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    Wait For Response
                  </Typography>
                )}
              </>
            )}
            {allData?.Status === "Issue In Processing" && (
              <Typography
                sx={{
                  cursor: "pointer",
                  color: "#fff",
                  bgcolor: "var(--primary-color)",
                  py: 1,
                  textAlign: "center",
                }}
              >
                Wait For Ticketed
              </Typography>
            )}

            <Box boxShadow="0 0 3px 3px white" px={1} my={2}>
              <Typography
                sx={{
                  color: "var(--primary-color)",
                  fontSize: 22,
                  fontWeight: 500,
                }}
              >
                Price Breakdown
              </Typography>
              <Stack direction="row" justifyContent="space-between">
                <Box>
                  <Typography
                    sx={{ color: "var(--white)", fontSize: 14, my: 2 }}
                  >
                    Total Passenger :
                  </Typography>
                  <Typography
                    sx={{ color: "var(--white)", fontSize: 14, my: 2 }}
                  >
                    Total Base Fare :
                  </Typography>
                  <Typography
                    sx={{ color: "var(--white)", fontSize: 14, my: 2 }}
                  >
                    Total Taxs :
                  </Typography>
                  <Typography
                    sx={{ color: "var(--white)", fontSize: 14, my: 2 }}
                  >
                    Total Fare :
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    sx={{ color: "var(--white)", fontSize: 14, my: 2 }}
                  >
                    {passengerData?.length}
                  </Typography>
                  <Typography
                    sx={{ color: "var(--white)", fontSize: 14, my: 2 }}
                  >
                    {allData?.BaseFare} {allData?.Currency}
                  </Typography>
                  <Typography
                    sx={{ color: "var(--white)", fontSize: 14, my: 2 }}
                  >
                    {allData?.Taxes} {allData?.Currency}
                  </Typography>
                  <Typography
                    sx={{ color: "var(--white)", fontSize: 14, my: 2 }}
                  >
                    {allData?.TotalFare} {allData?.Currency}
                  </Typography>
                </Box>
              </Stack>
            </Box>
            {/* <Box boxShadow="0 0 3px 3px white" px={1} my={2}>
              <Typography
                sx={{
                  color: "var(--primary-color)",
                  fontSize: 22,
                  fontWeight: 500,
                }}
              >
                Fare Rules
              </Typography>
              {Object?.keys(allData?.FareRules)?.map((data) => (
                <Box>
                  {console.log(data.refund_before_departure?.penalty_amount)}
                  <Typography>
                    Refund {data?.refund_before_departure?.penalty_amount}
                    {data?.refund_before_departure?.penalty_currency}
                  </Typography>
                  <Typography>
                    Change {data?.change_before_departure?.penalty_amount}
                    {data?.refund_before_departure?.penalty_currency}
                  </Typography>
                </Box>
              ))}
            </Box> */}
            <Box boxShadow="0 0 3px 3px white" px={1} my={2}>
              <Typography
                sx={{
                  color: "var(--primary-color)",
                  fontSize: 22,
                  fontWeight: 500,
                }}
              >
                Download PDF
              </Typography>
              {/* <AllPDF
                  isLoading={isLoading}
                  allData={allData}
                  flightData={flightData}
                  passengerData={passengerData}
                /> */}
              {allData?.Status !== "Ticketed" && (
                <Typography>
                  <BookingPDF
                    copy="Booking Copy"
                    isLoading={isLoading}
                    allData={allData}
                    flightData={flightData}
                    passengerData={passengerData}
                  />
                </Typography>
              )}
              {allData?.Status === "Ticketed" && (
                <Typography>
                  <BookingPDF
                    copy="Ticket Copy"
                    isLoading={isLoading}
                    allData={allData}
                    flightData={flightData}
                    passengerData={passengerData}
                  />
                </Typography>
              )}
              {/* <Typography
                sx={{
                  mt: 2,
                  cursor: "pointer",
                  color: "#fff",
                  bgcolor: "var(--primary-color)",
                  py: 1,
                  textAlign: "center",
                  borderRadius: "10px",
                }}
              >
                Booking Copy
              </Typography>
              <Typography
                sx={{
                  mt: 2,
                  cursor: "pointer",
                  color: "#fff",
                  bgcolor: "var(--primary-color)",
                  py: 1,
                  textAlign: "center",
                  borderRadius: "10px",
                }}
              >
                Ticket Copy
              </Typography> */}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Queuedetails;
