import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/system";
import React from "react";
import Roundway from "../Roundway/Roundway";
// import Multicity from "./../../MultiCity/Multicity";
import "./FlightSearchBox.css";
import Oneway from "./../Oneway/Oneway";
import Multicity from "../MultiCity/Multicity";

const FlightSearchBox = ({
  type,
  setType,
  value,
  setValue,
  fromSearchText,
  setFromSearchText,
  toSearchText,
  setToSearchText,
  from,
  setFrom,
  to,
  setTo,
  faddress,
  setfaddress,
  toAddress,
  setToAddress,
  fromSendData,
  setFromSendData,
  toSendData,
  setToSendData,
  adultCount,
  setAdultCount,
  childCount,
  setChildCount,
  infant,
  setInfant,
  result,
  setResult,
  className,
  setClassName,
  setChangeState,
  changeState,
  changeFrom,
  setChangeFrom,
  // searchData,
  // setSearchData,
}) => {
  const handleClassName = (event) => {
    setClassName(event.target.value);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // For Multicity
  const [searchData, setSearchData] = React.useState({
    Adult: adultCount,
    Child: childCount,
    Infant: infant,
    CityCount: 2,
    Segment: [
      {
        id: 0,
        openFrom: false,
        DepFrom: fromSendData.trim(),
        depAddress: faddress,
        depFromText: fromSearchText,
        ArrTo: toSendData.trim(),
        arrAddress: toAddress,
        arrToText: toSearchText,
        openTo: false,
        DepDate: new Date(from).toLocaleDateString("sv"),
        openDate: false,
        open: false,
      },
      {
        id: 1,
        openFrom: false,
        DepFrom: toSendData.trim(),
        depAddress: toAddress,
        depFromText: toSearchText,
        ArrTo: "DXB",
        arrAddress: "Dubai",
        arrToText: "Dubai Intl Airport (DXB)",
        openTo: false,
        DepDate: new Date().toLocaleDateString("sv"),
        openDate: false,
        open: false,
      },
    ],
  });

  return (
    <Box
      className="home-search-box-2"
      sx={{ width: "100%", typography: "body1" }}
    >
      <TabContext value={value}>
        <Box
          sx={{
            bgcolor: "transparent",
            color: "#FFF",
            width: {
              lg: "fit-content",
              md: "fit-content",
              sm: "100%",
              xs: "100%",
            },
            margin: "0px auto",
          }}
          display="flex"
          justifyContent="center"
        >
          <TabList
            value={value}
            onChange={handleChange}
            aria-label="Dashboard Tabs"
            variant="scrollable"
            className="tab-list-parent"
          >
            <Tab
              label="One Way"
              value="oneway"
              sx={{
                p: { xs: "5px 10px", md: "5px 50px" },
                fontSize: { xs: "11px", sm: "15px" },
              }}
            />

            <Tab
              label="Round Way"
              value="return"
              sx={{
                p: { xs: "5px 10px", md: "5px 50px" },
                fontSize: { xs: "11px", sm: "15px" },
              }}
            />

            <Tab
              label="Multi City"
              value="multicity"
              sx={{
                p: { xs: "5px 10px", md: "5px 50px" },
                fontSize: { xs: "11px", sm: "15px" },
              }}
            />
          </TabList>
        </Box>
        <TabPanel value="oneway">
          <Oneway
            tripType={value}
            iconColor={"#DC143C"}
            bgColor={"#fff"}
            bordercolor={"#003566"}
            faddress={faddress}
            fromSendData={fromSendData}
            setFromSendData={setFromSendData}
            toSendData={toSendData}
            setToSendData={setToSendData}
            setfaddress={setfaddress}
            toAddress={toAddress}
            setToAddress={setToAddress}
            fromSearchText={fromSearchText}
            setFromSearchText={setFromSearchText}
            toSearchText={toSearchText}
            setToSearchText={setToSearchText}
            setValue={setValue}
            adultCount={adultCount}
            setAdultCount={setAdultCount}
            childCount={childCount}
            setChildCount={setChildCount}
            infant={infant}
            setInfant={setInfant}
            result={result}
            setResult={setResult}
            className={className}
            handleClassName={handleClassName}
            to={to}
            setTo={setTo}
            from={from}
            setFrom={setFrom}
            setChangeState={setChangeState}
            changeState={changeState}
            changeFrom={changeFrom}
            setChangeFrom={setChangeFrom}
          />
        </TabPanel>
        <TabPanel value="return">
          <Roundway
            tripType={value}
            iconColor={"#DC143C"}
            bgColor={"#fff"}
            bordercolor={"#003566"}
            faddress={faddress}
            setfaddress={setfaddress}
            toAddress={toAddress}
            setToAddress={setToAddress}
            fromSendData={fromSendData}
            setFromSendData={setFromSendData}
            toSendData={toSendData}
            setToSendData={setToSendData}
            fromSearchText={fromSearchText}
            setFromSearchText={setFromSearchText}
            toSearchText={toSearchText}
            setToSearchText={setToSearchText}
            setValue={setValue}
            adultCount={adultCount}
            setAdultCount={setAdultCount}
            childCount={childCount}
            setChildCount={setChildCount}
            infant={infant}
            setInfant={setInfant}
            result={result}
            setResult={setResult}
            className={className}
            handleClassName={handleClassName}
            to={to}
            setTo={setTo}
            from={from}
            setFrom={setFrom}
            setChangeState={setChangeState}
            changeState={changeState}
            changeFrom={changeFrom}
            setChangeFrom={setChangeFrom}
          />
        </TabPanel>
        <TabPanel value="multicity">
          <Multicity
            tripType={value}
            iconColor={"#DC143C"}
            bgColor={"#fff"}
            bordercolor={"#003566"}
            faddress={faddress}
            setfaddress={setfaddress}
            toAddress={toAddress}
            setToAddress={setToAddress}
            fromSendData={fromSendData}
            setFromSendData={setFromSendData}
            toSendData={toSendData}
            setToSendData={setToSendData}
            fromSearchText={fromSearchText}
            setFromSearchText={setFromSearchText}
            toSearchText={toSearchText}
            setToSearchText={setToSearchText}
            setValue={setValue}
            adultCount={adultCount}
            setAdultCount={setAdultCount}
            childCount={childCount}
            setChildCount={setChildCount}
            infant={infant}
            setInfant={setInfant}
            result={result}
            setResult={setResult}
            className={className}
            handleClassName={handleClassName}
            to={to}
            setTo={setTo}
            from={from}
            setFrom={setFrom}
            setChangeState={setChangeState}
            changeState={changeState}
            changeFrom={changeFrom}
            setChangeFrom={setChangeFrom}
            searchData={searchData}
            setSearchData={setSearchData}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default FlightSearchBox;
