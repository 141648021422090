import axios from "axios";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const useAuthentication = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [agentId, setAgentId] = useState("");
  const [websitelink, setWebsitelink] = useState("");

  // User login

  const loginUser = (loginData, location, navigate) => {
    setIsLoading(false);
    secureLocalStorage.setItem("state", loginData);
    let body = JSON.stringify({
      email: loginData.email,
      password: loginData.password,
    });
    let url = `https://api.gallpax.com/v1/Auth/index.php?signin`;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(true);
        if (data.status === "success") {
          secureLocalStorage.setItem("user-info", data);
          const destination = location?.state?.from || "/";
          navigate(destination);
        } else {
          secureLocalStorage.removeItem("user-info");
          setError(data.message);
        }
      })
      .finally(() => setIsLoading(true));
  };

  useEffect(() => {
    // const interval = setInterval(() => {
    const state = secureLocalStorage.getItem("state");
    let url = `https://api.gallpax.com/v1/Auth/index.php?signin`;
    if (state !== null) {
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: state.email,
          password: state.password,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          secureLocalStorage.setItem("user-info", data);
        });
    }
    // }, 1000);
    // return () => clearInterval(interval);
  }, []);

  // Admin login
  const adminLogin = (loginData, location, navigate) => {
    setIsLoading(false);
    secureLocalStorage.setItem("state", loginData);
    let url = `https://api.gallpax.com/v1/Auth/index.php?adminlogin`;
    let body = JSON.stringify({
      email: loginData.email.trim(),
      password: loginData.password.trim(),
    });

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(true);
        if (data?.status === "success") {
          secureLocalStorage.setItem("admin-info", data);
          const destination = location?.state?.from || "/admin/dashboard";
          navigate(destination);
        } else {
          secureLocalStorage.removeItem("admin-info");
          setError(data.message);
          navigate("/");
        }
      })
      .finally(() => setIsLoading(true));
  };

  const logout = () => {
    secureLocalStorage.removeItem("user-info");
    secureLocalStorage.removeItem("admin-info");
    secureLocalStorage.removeItem("state");
    navigate("/");
    window.location.reload();
  };
  const adminlogout = () => {
    secureLocalStorage.removeItem("admin-info");
    secureLocalStorage.removeItem("state");
    navigate("/admin");
    window.location.reload();
  };

  return {
    adminLogin,
    loginUser,
    logout,
    adminlogout,
    isLoading,
    error,
  };
};

export default useAuthentication;
