/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Button, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { NavLink } from "react-router-dom";
import { FaFacebookSquare } from "react-icons/fa";
import { FaWhatsappSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import Logo from "../../images/Logo.PNG";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import LocationOnIcon from "@mui/icons-material/LocationOn";
// import payment from "../../images/payment.PNG";
import Payment from "../../images/imgPayment.png";
import "./Footer.css";
import { Stack } from "@mui/material";

const Footer = () => {
  const date = new Date().getFullYear();

  return (
    <Box mt={"10vh"} mb={"50px"} borderTop="5px solid #FF914C">
      <Box className="footer-bgs" mt={"50px"}>
        <Container>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={6} md={3} mb={1}>
              <Box mb={2}>
                <img src={Logo} alt="Travel Sense logo" width="180px" />
              </Box>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "var(--white)",
                  display: "flex",
                  align: "center",
                  // gap: 1,
                }}
              >
                <CallIcon sx={{ fontSize: 20 }} />
                &nbsp;+971&nbsp;509517784/ +8801685370455{" "}
              </Typography>
              <Typography
                sx={{
                  mt: 1,
                  fontSize: "14px",
                  color: "var(--white)",
                  display: "flex",
                  align: "center",
                }}
              >
                <EmailIcon sx={{ fontSize: 20 }} />
                &nbsp; kayesthechill@gmail.com{" "}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "var(--white)",
                  my: 1,
                  display: "flex",
                  align: "center",
                }}
              >
                <LocationOnIcon />
                &nbsp; Dubai, United Arab Emirates
              </Typography>
              <Stack direction="row" spacing={1} align="center">
                <a
                  href="https://www.facebook.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebookSquare
                    style={{
                      fontSize: "30px",
                      color: "var(--white)",
                    }}
                  />
                </a>
                <a
                  href="https://wa.me/+971509517784"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaWhatsappSquare
                    style={{
                      fontSize: "30px",
                      color: "var(--white)",
                    }}
                  />
                </a>
                <a
                  href="https://www.linkedin.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedin
                    style={{
                      fontSize: "30px",
                      color: "var(--white)",
                    }}
                  />
                </a>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={6} md={2}>
              <Box>
                <Typography
                  style={{
                    fontSize: "20px",
                    marginBottom: "10px",
                    fontWeight: 500,
                    color: "var(--white)",
                  }}
                >
                  Quick Links
                </Typography>
                <Typography style={{ fontSize: "14px", color: "var(--white)" }}>
                  <NavLink
                    to="/"
                    style={{
                      textDecoration: "none",
                      color: "var(--white)",
                      fontSize: "14px",
                    }}
                  >
                    <NavLink
                      to="/"
                      style={{
                        textDecoration: "none",
                        color: "var(--white)",
                        fontSize: "14px",
                      }}
                    >
                      Payment Method
                    </NavLink>
                  </NavLink>
                </Typography>
                <Typography style={{ fontSize: "14px", color: "var(--white)" }}>
                  <NavLink
                    to="/"
                    style={{
                      textDecoration: "none",
                      color: "var(--white)",
                      fontSize: "14px",
                    }}
                  >
                    Terms and condition
                  </NavLink>
                </Typography>
                <Typography style={{ fontSize: "14px", color: "var(--white)" }}>
                  <NavLink
                    to="/"
                    style={{
                      textDecoration: "none",
                      color: "var(--white)",
                      fontSize: "14px",
                    }}
                  >
                    Privacy policy
                  </NavLink>
                </Typography>
                <Typography style={{ fontSize: "14px", color: "var(--white)" }}>
                  <NavLink
                    to="/"
                    style={{
                      textDecoration: "none",
                      color: "var(--white)",
                      fontSize: "14px",
                    }}
                  >
                    Refund Policy
                  </NavLink>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4} md={2}>
              <Box>
                <Typography
                  style={{
                    fontSize: "20px",
                    marginBottom: "10px",
                    fontWeight: 500,
                    color: "var(--white)",
                  }}
                >
                  About us
                </Typography>
                <Typography style={{ fontSize: "14px", color: "var(--white)" }}>
                  <NavLink
                    to="/"
                    style={{
                      textDecoration: "none",
                      color: "var(--white)",
                      fontSize: "14px",
                    }}
                  >
                    <NavLink
                      to="/"
                      style={{
                        textDecoration: "none",
                        color: "var(--white)",
                        fontSize: "14px",
                      }}
                    >
                      Career
                    </NavLink>
                  </NavLink>
                </Typography>
                <Typography style={{ fontSize: "14px", color: "var(--white)" }}>
                  <NavLink
                    to="/"
                    style={{
                      textDecoration: "none",
                      color: "var(--white)",
                      fontSize: "14px",
                    }}
                  >
                    Services
                  </NavLink>
                </Typography>
                <Typography style={{ fontSize: "14px", color: "var(--white)" }}>
                  <NavLink
                    to="/"
                    style={{
                      textDecoration: "none",
                      color: "var(--white)",
                      fontSize: "14px",
                    }}
                  >
                    Opportunities
                  </NavLink>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4} md={2}>
              <Box>
                <Typography
                  style={{
                    fontSize: "20px",
                    marginBottom: "10px",
                    fontWeight: 500,
                    color: "var(--white)",
                  }}
                >
                  Company
                </Typography>
                <Typography style={{ fontSize: "14px", color: "var(--white)" }}>
                  <NavLink
                    to="/"
                    style={{
                      textDecoration: "none",
                      color: "var(--white)",
                      fontSize: "14px",
                    }}
                  >
                    <NavLink
                      to="/"
                      style={{
                        textDecoration: "none",
                        color: "var(--white)",
                        fontSize: "14px",
                      }}
                    >
                      Join Us
                    </NavLink>
                  </NavLink>
                </Typography>
                <Typography style={{ fontSize: "14px", color: "var(--white)" }}>
                  <NavLink
                    to="/"
                    style={{
                      textDecoration: "none",
                      color: "var(--white)",
                      fontSize: "14px",
                    }}
                  >
                    Supports
                  </NavLink>
                </Typography>
                <Typography style={{ fontSize: "14px", color: "var(--white)" }}>
                  <NavLink
                    to="/"
                    style={{
                      textDecoration: "none",
                      color: "var(--white)",
                      fontSize: "14px",
                    }}
                  >
                    FAQs
                  </NavLink>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Box>
                <Typography
                  style={{
                    fontSize: "20px",
                    marginBottom: "10px",
                    fontWeight: 500,
                    color: "var(--white)",
                  }}
                >
                  Newsletter
                </Typography>
                <Typography
                  sx={{ fontSize: "14px", color: "var(--white)", mb: 1 }}
                >
                  Subsrcibe us to get update about new offer
                </Typography>
                <Box>
                  <input
                    placeholder="enter your email "
                    style={{
                      border: "2px solid #fff",
                      padding: "8px",
                      borderRadius: "5px",
                      width: "90%",
                      backgroundColor: "transparent",
                      color: "#fff",
                      fontSize: "14px",
                    }}
                  />
                  <Button
                    sx={{
                      mt: 1,
                      bgcolor: "var(--primary-color)",
                      color: "#fff",
                      "&:hover": {
                        bgcolor: "var(--primary-color)",
                      },
                    }}
                  >
                    SUBSCRIBE
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>

        <Container>
          <hr
            style={{
              margin: "20px 0px 10px",
              width: "100%",
              overflow: "hidden",
            }}
          />
          <Box textAlign="center">
            <Box>
              {/* <img src={Payment} alt="Travel Sense logo" width="80%" /> */}
            </Box>
            <Typography
              style={{
                color: "var(--white)",
                fontWeight: "normal",
                // textAlign: "right",
                fontSize: "14px",
              }}
              noWrap
            >
              &copy; Copyright {date} by Travel Sense Travel Develop and Designed by
              <span style={{ color: "var(--primary-color)" }}>
                {" "}
                SixSence Software
              </span>
            </Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
