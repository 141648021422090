import { Box, Typography } from "@mui/material";
import React from "react";
import Footer from "../../components/Footer/Footer";
import HomeSearchBox from "../../components/HomeSearchBox/HomeSearchBox";
import { LandingHeader } from "../../components/LandingHeader/LandingHeader";
import "./home.css";


const Home = () => {
  return (
    <Box>
      <Box mb={2}>
        <LandingHeader />
      </Box>
     
      <Typography
        sx={{
          mt: 5,
          color: "#fff",
          fontSize: { xs: "20px", sm: "25px", md: "3.2vw" },
          textAlign: "center",
        }}
      >
        Let's live in our Own Dreams.
      </Typography>
      <Box mt={"20px"} mb={"40vh"}>
        <HomeSearchBox />
      </Box>
      <Box>
        <Footer />
      </Box>
    </Box>
  );
};

export default Home;
