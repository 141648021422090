import {
  Box,
  IconButton,
  MenuItem,
  Typography,
  Menu,
  ClickAwayListener,
  Link,
  Grid,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Container } from "@mui/system";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Logo from "../../images/Logo.PNG";
import Avatar from "@mui/material/Avatar";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import "./LandingHeader.css";
import secureLocalStorage from "react-secure-storage";
import useAuthentication from "../../hooks/useAuthentication";

export const LandingHeader = () => {
  const user = secureLocalStorage.getItem("user-info");
  const { loginUser } = useAuthentication();
  const { logout } = useAuthentication();
  const location = useLocation();
  const navigate = useNavigate();
  // todo: for mobile device

  return (
    <Box>
      <Box sx={{ background: "var(--secondary-color)", my: 3 }}>
        <Container>
          <Grid container spaceing={2} justifyContent="space-between">
            <Grid item margin={{ xs: "auto", sm: "0" }}>
              <Box
                sx={{
                  background: "transparent",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/")}
              >
                <img src={Logo} alt="Travel Sense" width="180px" />
              </Box>
            </Grid>
            <Grid item margin={{ xs: "auto", sm: "0" }}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
                mt={{ xs: 2, sm: 0 }}
              >
                <Typography underline="none">
                  {/* <a href="https://wa.me/+8801685370455" target="_blank"> */}
                  <a
                    href="https://wa.me/+971509517784"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <Box
                      sx={{
                        color: "#fff",
                        display: { xs: "flex" },
                        alignItems: "center",
                      }}
                    >
                      <WhatsAppIcon sx={{ fontSize: 20 }} />
                      &nbsp;Contact&nbsp;Expert{" "}
                    </Box>
                  </a>
                </Typography>

                {user?.status === "success" ? (
                  <Stack direction="row" spacing={2}>
                    <NavLink
                      to="/profile"
                      style={{
                        textDecoration: "none",
                        width: "fit-content",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "16px",
                        fontWeight: 400,
                      }}
                    >
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Avatar
                          src="/broken-image.jpg"
                          sx={{
                            width: 35,
                            height: 35,
                            backgroundColor: "var(--primary-color)",
                          }}
                        />
                        {/* <Typography sx={{ color: "#fff", fontSize: 16 }}>
                          Profile
                        </Typography> */}
                      </Stack>
                      {/* </Box> */}
                    </NavLink>
                    <NavLink
                      to="/"
                      style={{
                        textDecoration: "none",
                        width: "fit-content",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "16px",
                        fontWeight: 400,
                      }}
                      onClick={logout}
                    >
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "transparent",
                          color: "var(--white)",
                          padding: "5px 20px",
                          border: "2px solid var(--white)",
                          borderRadius: "5px",
                        }}
                      >
                        Logout
                      </Box>
                    </NavLink>
                  </Stack>
                ) : (
                  <>
                    <NavLink
                      to="/signin"
                      style={{
                        textDecoration: "none",
                        width: "fit-content",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "16px",
                        fontWeight: 400,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "transparent",
                          color: "var(--primary-color)",
                          padding: { xs: "2px 5px", md: "5px 20px" },
                          border: "2px solid var(--primary-color)",
                          borderRadius: "5px",
                        }}
                      >
                        Sign&nbsp;In
                      </Box>
                    </NavLink>
                    <NavLink
                      to="/signup"
                      style={{
                        textDecoration: "none",
                        width: "fit-content",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "16px",
                        fontWeight: 400,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "var(--primary-color)",
                          color: "#fff",
                          padding: { xs: "2px 5px", md: "5px 20px" },
                          border: "2px solid var(--primary-color)",
                          borderRadius: "5px",
                        }}
                      >
                        Sign&nbsp;Up
                      </Box>
                    </NavLink>
                  </>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};
