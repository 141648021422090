import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import PeopleIcon from "@mui/icons-material/People";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LanguageIcon from "@mui/icons-material/Language";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useNavigate } from "react-router-dom";
import TabContext from "@material-ui/lab/TabContext";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import { format } from "date-fns";
import Logo from "../../../images/Logo.PNG";
import secureLocalStorage from "react-secure-storage";

import "./LiveDashboard.css";

const LiveDashboard = () => {
  const user = secureLocalStorage.getItem("admin-info");
  console.log(user);
  const [desire3, setDesire3] = useState(0);
  const navigate = useNavigate();

  const [bookingData, setBookingData] = useState([]);
  const [totalDepositAmount, setTotalDepositAmount] = useState([]);
  const [totalCustomer, setTotalCustomer] = useState([]);
  const [totalBooking, setTotalBooking] = useState([]);
  const [depositData, setDepositData] = useState([]);
  const [pendingDepositData, setPendingDepositData] = useState([]);
  const [approveDepositData, setApproveDepositData] = useState([]);
  const [rejectDepositData, setRejectDepositData] = useState([]);

  const [todayData, setTodayData] = useState([]);

  const d = new Date();

  useEffect(() => {
    fetch(`https://api.gallpax.com/v1/Stats/Dashboard.php`)
      .then((res) => res.json())
      .then((data) => {
        setTodayData(data);
        setBookingData(data?.TotalBookingData);
        setTotalDepositAmount(data?.TotalDepositAmount);
        setTotalCustomer(data?.TotalCustomer);
        setTotalBooking(data?.TotalBookingData.length);
        setDepositData(data?.TotalDepositData);

        const pendingData = data?.TotalDepositData.filter((data) => {
          return data?.status === "pending";
        });

        setPendingDepositData(pendingData);

        const approveData = data?.TotalDepositData.filter((data) => {
          return data?.status === "approved";
        });
        setApproveDepositData(approveData);

        const rejectData = data?.TotalDepositData.filter((data) => {
          return data?.status === "rejected";
        });
        setRejectDepositData(rejectData);
      });
  }, []);

  const sendToQueuesDetails = (data) => {
    navigate("/admin/qdetails", {
      state: {
        data,
        path: "mybooking",
        adminPath: "admin",
      },
    });
  };

  return (
    <Box
      className="dashboard-live"
      style={{
        backgroundColor: "var(--secondary-color)",
        height: "150vh",
      }}
    >
      <Container>
        {/* admin Header */}

        <Box py={4}>
          <Grid
            container
            spaceing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Box
                sx={{
                  background: "transparent",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/")}
              >
                <img src={Logo} alt="Travel Sense" width="180px" />
              </Box>
            </Grid>
            <Grid item>
              <Box>
                <Typography
                  style={{
                    color: "#fff",
                    background: "var(--bgcolor)",
                    padding: "8px 10px",
                    width: "200px",
                  }}
                >
                  Dashboard
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box
              style={{
                background: "var(--bgcolor)",
                padding: "10px 0px 15px 15px",
                borderRadius: "5px",
              }}
            >
              <Typography
                style={{ color: "var(--primary-color)", fontWeight: "500" }}
              >
                Today Booking
              </Typography>
              <Typography
                style={{
                  color: "var(--primary-color)",
                  fontWeight: "500",
                  fontSize: "25px",
                }}
              >
                {todayData?.TodayBooking !== null
                  ? todayData?.TodayBooking
                  : "0"}
              </Typography>
              <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                <Typography
                  style={{
                    background: "var(--primary-color)",
                    width: "150px",
                    padding: "3px 5px",
                    fontSize: "12px",
                    color: "black",
                  }}
                >
                  {d
                    ? format(new Date(d.toString()), "dd MMM yy hh:mm a")
                    : "Booking Time"}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              style={{
                background: "var(--bgcolor)",
                padding: "10px 0px 15px 15px",
                borderRadius: "5px",
              }}
            >
              <Typography
                style={{ color: "var(--primary-color)", fontWeight: "500" }}
              >
                Today Total Deposit
              </Typography>
              <Typography
                style={{
                  color: "var(--primary-color)",
                  fontWeight: "500",
                  fontSize: "25px",
                }}
              >
                {todayData?.TodayDeposit !== null
                  ? todayData?.TodayDeposit
                  : "0"}
              </Typography>
              <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                <Typography
                  style={{
                    background: "var(--primary-color)",
                    width: "150px",
                    padding: "3px 5px",
                    fontSize: "12px",
                    color: "black",
                  }}
                >
                  {d
                    ? format(new Date(d.toString()), "dd MMM yy hh:mm a")
                    : "Booking Time"}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              style={{
                background: "var(--bgcolor)",
                padding: "10px 0px 15px 15px",
                borderRadius: "5px",
              }}
            >
              <Typography
                style={{ color: "var(--primary-color)", fontWeight: "500" }}
              >
                Today Total Sale
              </Typography>
              <Typography
                style={{
                  color: "var(--primary-color)",
                  fontWeight: "500",
                  fontSize: "25px",
                }}
              >
                {todayData?.TodaySell !== null ? todayData?.TodaySell : "0"}
              </Typography>
              <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                <Typography
                  style={{
                    background: "var(--primary-color)",
                    width: "150px",
                    padding: "3px 5px",
                    fontSize: "12px",
                    color: "black",
                  }}
                >
                  {d
                    ? format(new Date(d.toString()), "dd MMM yy hh:mm a")
                    : "Booking Time"}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* ---------------  Air Ticket Live Booking -------------- */}

        <Grid container spacing={2} mt={4}>
          <Grid item xs={12} md={12}>
            <Box className="balance-transaction">
              <Typography style={{ color: "#fff" }}>
                Air Ticket Live Booking
              </Typography>
              <table style={{ background: "transparent" }}>
                <tr>
                  <th>Booking Id</th>
                  <th>Customer Id</th>
                  <th>Status</th>
                  <th>Route</th>
                  <th>PNR</th>
                  <th>Book At</th>
                  <th>PAX</th>
                  <th>Sold Price</th>

                  {/* <th>Contact </th> */}
                </tr>

                {bookingData.map((data) => (
                  <tr>
                    <td>
                      <Typography
                        sx={{
                          bgcolor: "var(--white)",
                          color: "var(--secondary-color)",
                          cursor: "pointer",
                        }}
                        onClick={() => sendToQueuesDetails(data)}
                      >
                        {data?.BookingId}
                      </Typography>
                    </td>
                    <td>{data?.CustomerId}</td>

                    <td>{data?.Status}</td>
                    <td>
                      {data?.DepFrom} - {data?.ArrTo}
                    </td>
                    <td>{data?.BookingRef}</td>
                    <td>
                      {data?.created_at
                        ? format(
                            new Date(data?.created_at.toString()),
                            "dd MMM yy hh:mm a"
                          )
                        : "Booking Time"}
                    </td>
                    <td>{data?.TotalPax}</td>
                    <td>{data?.SellPrice} BTN</td>
                    {/* <td style={{}}>
                <a href={`#`}>
                  <PhoneIcon
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "20px",
                      marginRight: "5px",
                    }}
                  />
                </a>
                <a href={`#`} target="_blank" rel="noreferrer">
                  <WhatsAppIcon
                    style={{
                      color: "green",
                      fontSize: "21px",
                      marginRight: "5px",
                    }}
                  />
                </a>
              </td> */}
                  </tr>
                ))}
              </table>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={4}>
          <Grid item xs={12} md={7}>
            <Box>
              <Typography
                my={1}
                style={{
                  color: "var(--white)",
                  fontSize: "16px",
                  fontFamily: "poppins",
                  fontWeight: "500",
                }}
              >
                Payment Management
              </Typography>
              <Box className="liveDashboard">
                <Box className="parent-liveDashboard ">
                  <Box>
                    <Tabs
                      selectedIndex={desire3}
                      onSelect={(index) => setDesire3(index)}
                    >
                      <TabList
                        style={{
                          paddingLeft: "0px",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "10px",
                          marginBottom: "5px",
                        }}
                      >
                        <Box style={{ display: "flex", gap: "15px" }}>
                          <Tab>All {depositData.length}</Tab>
                          <Tab>Pending 50</Tab>
                          <Tab>Approved 50</Tab>
                          <Tab>Rejected 50</Tab>
                        </Box>
                      </TabList>

                      <TabPanel>
                        <TabContext>
                          <Box className="balance-transaction">
                            <table style={{ background: "transparent" }}>
                              <tr>
                                <th>Deposit Id</th>
                                <th>Status</th>
                                <th>User Name</th>
                                <th>Method</th>
                                <th>Attachment</th>
                              </tr>

                              {depositData.map((data) => (
                                <tr>
                                  <td>{data?.depositId}</td>
                                  <td>{data?.status}</td>
                                  <td>{data?.sender}</td>
                                  <td>{data?.paymentmethod} </td>
                                  <td>
                                    <a
                                      id="anchor"
                                      href={`https://cdn.flyfarint.com/WL/${data?.customerId}/Deposit/${data?.attachment}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </table>
                          </Box>
                        </TabContext>
                      </TabPanel>
                      <TabPanel>
                        <TabContext>
                          <Box className="balance-transaction">
                            <table style={{ background: "transparent" }}>
                              <tr>
                                <th>Deposit Id</th>
                                <th>Status</th>
                                <th>User Name</th>
                                <th>Method</th>
                                <th>Attachment</th>
                              </tr>
                              {pendingDepositData.map((data) => (
                                <tr>
                                  <td>{data?.depositId}</td>
                                  <td>{data?.status}</td>
                                  <td>{data?.sender}</td>
                                  <td>{data?.paymentmethod} </td>
                                  <td>
                                    <a href={data?.depositId}>View</a>
                                  </td>
                                </tr>
                              ))}
                            </table>
                          </Box>
                        </TabContext>
                      </TabPanel>
                      <TabPanel>
                        <TabContext>
                          <Box className="balance-transaction">
                            <table style={{ background: "transparent" }}>
                              <tr>
                                <th>Deposit Id</th>
                                <th>Status</th>
                                <th>User Name</th>
                                <th>Method</th>
                                <th>Attachment</th>
                              </tr>
                              {approveDepositData.map((data) => (
                                <tr>
                                  <td>{data?.depositId}</td>
                                  <td>{data?.status}</td>
                                  <td>{data?.sender}</td>
                                  <td>{data?.paymentmethod} </td>
                                  <td>
                                    <a href={data?.depositId}>View</a>
                                  </td>
                                </tr>
                              ))}
                            </table>
                          </Box>
                        </TabContext>
                      </TabPanel>
                      <TabPanel>
                        <TabContext>
                          <Box className="balance-transaction">
                            <table style={{ background: "transparent" }}>
                              <tr>
                                <th>Deposit Id</th>
                                <th>Status</th>
                                <th>User Name</th>
                                <th>Method</th>
                                <th>Attachment</th>
                              </tr>
                              {rejectDepositData.map((data) => (
                                <tr>
                                  <td>{data?.depositId}</td>
                                  <td>{data?.status}</td>
                                  <td>{data?.sender}</td>
                                  <td>{data?.paymentmethod} </td>
                                  <td>
                                    <a href={data?.depositId}>View</a>
                                  </td>
                                </tr>
                              ))}
                            </table>
                          </Box>
                        </TabContext>
                      </TabPanel>
                    </Tabs>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={5} mt={{ xs: 2, sm: 2, md: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3.3}>
                <Box
                  style={{
                    background: "var(--bgcolor)",
                    padding: "10px 10px",
                    borderRadius: "5px",
                  }}
                >
                  <Typography
                    style={{
                      color: "#9C9797",
                      fontWeight: "500",
                      fontSize: "10px",
                      textAlign: "right",
                    }}
                  >
                    Total
                  </Typography>
                  <Typography
                    style={{
                      color: "var(--primary-color)",
                      fontWeight: "500",
                      fontSize: "12px",
                      textAlign: "right",
                    }}
                  >
                    {/* Total <br></br>  */}
                    Deposit
                  </Typography>
                  <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Typography
                      style={{
                        padding: "3px 5px",
                        fontSize: `${
                          totalDepositAmount?.length > 9 ? "14px" : "18px"
                        }`,
                        color: "#fff",
                      }}
                    >
                      {totalDepositAmount}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={3.3}>
                <Box
                  style={{
                    background: "var(--bgcolor)",
                    padding: "10px 10px",
                    borderRadius: "5px",
                  }}
                >
                  <Typography
                    style={{
                      color: "#9C9797",
                      fontWeight: "500",
                      fontSize: "10px",
                      textAlign: "right",
                    }}
                  >
                    Total
                  </Typography>
                  <Typography
                    style={{
                      color: "var(--primary-color)",
                      fontWeight: "500",
                      fontSize: "12px",
                      textAlign: "right",
                    }}
                  >
                    Customer <br></br>
                  </Typography>
                  <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Typography
                      style={{
                        padding: "3px 5px",
                        fontSize: "18px",
                        color: "#fff",
                      }}
                    >
                      {totalCustomer}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={3.3}>
                <Box
                  style={{
                    background: "var(--bgcolor)",
                    padding: "10px 10px",
                    borderRadius: "5px",
                  }}
                >
                  <Typography
                    style={{
                      color: "#9C9797",
                      fontWeight: "500",
                      fontSize: "10px",
                      textAlign: "right",
                    }}
                  >
                    Total
                  </Typography>
                  <Typography
                    style={{
                      color: "var(--primary-color)",
                      fontWeight: "500",
                      fontSize: "12px",
                      textAlign: "right",
                    }}
                  >
                    Booking
                  </Typography>
                  <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Typography
                      style={{
                        padding: "3px 5px",
                        fontSize: "18px",
                        color: "#fff",
                      }}
                    >
                      {totalBooking}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LiveDashboard;
