import { Box, Grid, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import { TextField } from "@mui/material";
import { Button } from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { NavLink, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const AddDeposit = () => {
  const navigate = useNavigate();
  const users = secureLocalStorage.getItem("user-info");
  let customerId = users?.data[0]?.customerId;

  // let customerId = users?.data[0]?.customerId;

  const [isLoading, setIsLoading] = useState(true);

  const [method, setMethod] = useState("");
  const [sender, setSender] = useState("");
  const [reciver, setReciver] = useState("");
  const [reference, setReference] = useState("");
  const [amount, setAmount] = useState("");
  const [file, setFile] = useState("");
  const [bankName, setBankName] = useState("");
  const [trId, setTrId] = useState("");

  const onChangeFile = (e) => {
    setFile(e.target.files[0]);
  };

  console.log(sender, reciver, reference, amount, bankName, trId, method, file);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(false);
    const requestOptions = new FormData();
    requestOptions.append("file", file);

    try {
      let res = await axios.post(
        `https://api.gallpax.com/v1/Deposit/addDeposit.php?customerId=${customerId}&sender=${sender}&receiver=${reciver}&way=${method}&method=${bankName}&transactionId=${trId}&amount=${amount}&ref=${reference}`,
        requestOptions,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res.status === 200) {
        setIsLoading(false);
        Swal.fire({
          icon: "success",
          title: "Deposit Successfully!",
          html: "Your Deposit request is submitted successfully Please wait for a response, if you do not receive any email, please contact us at <strong> kayesthechill@gmail.com or +8801685370455.</strong>",
          button: "Done!",
        }).then(function () {
          setIsLoading(true);
          navigate(0);
        });
      } else {
        Swal({
          icon: "error",
          title: "Deposit Failed!",
          html: "Please contact us at <strong> kayesthechill@gmail.com or +8801685370455.</strong>",
          button: "Done!",
        }).then(function () {
          setIsLoading(true);
          navigate(0);
        });
      }
    } catch (err) {
      Swal({
        icon: "error",
        title: "Deposit Failed!",
        html: "Please contact us at <strong> kayesthechill@gmail.com or +8801685370455.</strong>",
        button: "Done!",
      }).then(function () {
        setIsLoading(true);
        navigate(0);
      });
    }
    e.target.reset();
  };
  return (
    <div>
      <Box
        sx={{
          mt: 4,
          width: "100%",
          minHeight: "50vh",
        }}
      >
        <form onSubmit={handleSubmit}>
          <Box>
            <Grid container columnSpacing={4} rowSpacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography color="#fff">Payment Method</Typography>
                <Box style={{ marginTop: "5px" }}>
                  <select
                    required
                    onChange={(e) => setMethod(e.target.value)}
                    style={{
                      height: "40px",
                      width: "calc(100% - 20px)",
                      border: "none",
                      outline: "none",
                      borderRadius: "0px",
                      padding: "0px 10px",
                      color: "var(--white)",
                      background: "rgba(255, 168, 77, 0.25)",
                      fontSize: "16px",
                    }}
                  >
                    <option
                      value=""
                      style={{
                        color: "var(--white)",
                        background: "var(--secondary-color)",
                        fontSize: "16px",
                      }}
                    >
                      Select Method
                    </option>

                    <option
                      value="Bank"
                      style={{
                        color: "var(--white)",
                        background: "var(--secondary-color)",
                        fontSize: "16px",
                      }}
                    >
                      Bank
                    </option>

                    <option
                      value="Cash"
                      style={{
                        color: "var(--white)",
                        background: "var(--secondary-color)",
                        fontSize: "16px",
                      }}
                    >
                      Cash
                    </option>
                  </select>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography color="#fff">
                  {method === "Bank" ? "Sender Account Number" : "Sender Name"}
                </Typography>
                <Box style={{ marginTop: "5px" }}>
                  <input
                    required
                    type="text"
                    placeholder="Send"
                    onChange={(e) => setSender(e.target.value)}
                    style={{
                      height: "40px",
                      width: "calc(100% - 20px)",
                      border: "none",
                      outline: "none",
                      borderRadius: "0px",
                      padding: "0px 10px",
                      color: "var(--white)",
                      background: "rgba(255, 168, 77, 0.25)",
                      fontSize: "16px",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography color="#fff">
                  {" "}
                  {method === "Bank"
                    ? "Receiver Account Number"
                    : "Sender Name"}
                </Typography>
                <Box style={{ marginTop: "5px" }}>
                  <input
                    required
                    type="text"
                    placeholder="Received"
                    onChange={(e) => setReciver(e.target.value)}
                    style={{
                      height: "40px",
                      width: "calc(100% - 20px)",
                      border: "none",
                      outline: "none",
                      borderRadius: "0px",
                      padding: "0px 10px",
                      color: "var(--white)",
                      background: "rgba(255, 168, 77, 0.25)",
                      fontSize: "16px",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography color="#fff">Reference</Typography>
                <Box style={{ marginTop: "5px" }}>
                  <input
                    required
                    type="text"
                    placeholder="Reference"
                    onChange={(e) => setReference(e.target.value)}
                    style={{
                      height: "40px",
                      width: "calc(100% - 20px)",
                      border: "none",
                      outline: "none",
                      borderRadius: "0px",
                      padding: "0px 10px",
                      color: "var(--white)",
                      background: "rgba(255, 168, 77, 0.25)",
                      fontSize: "16px",
                    }}
                  />
                </Box>
              </Grid>
              {method === "Bank" && (
                <>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography color="#fff">Bank Name</Typography>
                    <Box style={{ marginTop: "5px" }}>
                      <input
                        required
                        type="text"
                        placeholder="Bank Name"
                        onChange={(e) => setBankName(e.target.value)}
                        style={{
                          height: "40px",
                          width: "calc(100% - 20px)",
                          border: "none",
                          outline: "none",
                          borderRadius: "0px",
                          padding: "0px 10px",
                          color: "var(--white)",
                          background: "rgba(255, 168, 77, 0.25)",
                          fontSize: "16px",
                        }}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Typography color="#fff">Transection Id</Typography>
                    <Box style={{ marginTop: "5px" }}>
                      <input
                        required
                        type="text"
                        placeholder="Transection Id"
                        onChange={(e) => setTrId(e.target.value)}
                        style={{
                          height: "40px",
                          width: "calc(100% - 20px)",
                          border: "none",
                          outline: "none",
                          borderRadius: "0px",
                          padding: "0px 10px",
                          color: "var(--white)",
                          background: "rgba(255, 168, 77, 0.25)",
                          fontSize: "16px",
                        }}
                      />
                    </Box>
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={6} md={4}>
                <Typography color="#fff">Enter Amount</Typography>
                <Box style={{ marginTop: "5px" }}>
                  <input
                    required
                    type="number"
                    placeholder="Enter amount"
                    onChange={(e) => setAmount(e.target.value)}
                    style={{
                      height: "40px",
                      width: "calc(100% - 20px)",
                      border: "none",
                      outline: "none",
                      borderRadius: "0px",
                      padding: "0px 10px",
                      color: "var(--white)",
                      background: "rgba(255, 168, 77, 0.25)",
                      fontSize: "16px",
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Typography color="#fff">Transection Copy</Typography>
                <Box style={{ marginTop: "5px" }} className="input-File1">
                  <input
                    required
                    style={{
                      background: "rgba(255, 168, 77, 0.25)",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      padding: "5px 10px",
                      boxSizing: "border-box",
                      height: "40px",
                      width: "calc(100% - 20px)",
                    }}
                    onChange={onChangeFile}
                    type="file"
                    title="Choose a Image png and jpg please"
                    accept="image/*"
                  />
                </Box>
              </Grid>
            </Grid>
            <Box>
              <Button
                sx={{
                  fontFamily: "poppins",
                  fontWeight: "400",
                  fontSize: "14px",
                  textTransform: "capitalize",
                  borderRadius: "2px",
                  background: "var(--primary-color)",
                  color: "#FFFFFF",
                  // width: "fit-content",

                  width: { xs: "100%", sm: "30%" },
                  mt: { xs: 2, sm: 5 },
                  "&:hover": {
                    backgroundColor: "var(--primary-color)",
                  },
                }}
                type="submit"
              >
                {isLoading ? (
                  "Send Deposit Request"
                ) : (
                  <CircularProgress
                    size="1.5rem"
                    sx={{
                      color: "#fff",
                    }}
                  />
                )}
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </div>
  );
};

export default AddDeposit;
