import {
  Box,
  ClickAwayListener,
  Container,
  Pagination,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { Grid, Button } from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import Swal from "sweetalert2";
import Loader from "../../images/loader/Render.gif";
import { format } from "date-fns";
import secureLocalStorage from "react-secure-storage";
import Search from "../../images/undraw/undraw_web_search_re_efla.svg";
import Commission from "../../components/Commission";
import AirlinesNameSlider from "../../components/AirlinesNameSlider/AirlinesNameSlider";
import RoundSingleFlight from "../../components/SingleFlight/RoundSingleFlight";
import RoundFilterDrawer from "../../components/RoundFilterDrawer";
import RoundWayFilter from "../../components/RoundWayFilter";
import styled from "@emotion/styled";
import FlightSearchBox from "../../components/FlightSearchBox/FlightSearchBox";
import SessionTimer from "../../components/Shared/SessionTimer/SessionTimer";
import RoundPreloader from "../../components/Preloader/RoundPreloader";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import { RoundWayFakeData } from "../../components/RoundWayFakeData";
import OneWayFilter from "../../components/OneWayFilter";
import Header from "../../components/Header/Header";
import Footer from "./../../components/Footer/Footer";
import { LandingHeader } from "../../components/LandingHeader/LandingHeader";

const modalStyle = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "70vw", md: "90vw", sm: "90vw", xs: "90vw" },
  height: "fit-content",
  padding: "20px",
  background: "#2F2F31",
  borderRadius: "10px",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
};

const HtmlTooltip = styled(({ className, ...propss }) => (
  <Tooltip {...propss} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#dc143c",
    maxWidth: 220,
    fontSize: "20px",
    borderRadius: "8px 0px 8px 0px",
  },
}));

const RoundSearchResult = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const requiredSearchData =
    location.state !== null
      ? location.state
      : secureLocalStorage.getItem("search-data");

  const {
    fromSendData,
    toSendData,
    departureDate,
    returningDate,
    adultCount,
    childCount,
    infant,
    tripType,
    faddress,
    toAddress,
    fromSearchText,
    toSearchText,
    className,
  } = requiredSearchData;

  const [options, setOptions] = useState({
    showCalendarAvailability: false,
    directFlightOnly: false,
    includeCheckedBaggage: false,
  });

  //fetch data form localStorage
  const commissionData = secureLocalStorage.getItem("commissionData");
  //all states that i have to send to modify search
  const [type, setType] = React.useState("flight");
  const [value, setValue] = React.useState(tripType);
  const [roundWayFromSearchText, setRoundWayFromSearchText] =
    useState(fromSearchText);
  const [roundWayToSearchText, setRoundWayToSearchText] =
    useState(toSearchText);
  const now = useRef(new Date(departureDate));
  const returnNow = useRef(new Date(returningDate));
  const [from, setFrom] = useState(now.current);
  const [to, setTo] = useState(returnNow.current);
  const [fromSearchDate, setFromSearchDate] = useState(departureDate);
  const [toSearchDate, setToSearchDate] = useState(returningDate);

  const [roundWayFaddress, setRoundWayFaddress] = useState(faddress);
  const [roundWayToAddress, setRoundWayToAddress] = useState(toAddress);
  const [roundWayFromSendData, setRoundWayFromSendData] =
    useState(fromSendData);
  const [roundWayToSendData, setRoundWayToSendData] = useState(toSendData);
  const [roundWayAdultCount, setRoundWayAdultCount] = useState(adultCount);
  const [roundWayChildCount, setRoundWayChildCount] = useState(childCount);
  const [roundWayInfant, setRoundWayInfant] = useState(infant);
  const [result, setResult] = useState(adultCount + childCount + infant);
  const [roundWayClassName, setRoundWayClassName] = useState(className);
  const [isPrevClicked, setIsPrevCliked] = useState(false);
  const [isNextClicked, setIsNextCliked] = useState(false);
  //end

  //CM Box States
  const [customerFare, setCustomerFare] = useState(true);
  const [agentFarePrice, setAgentFarePrice] = useState(true);
  const [commisionFarePrice, setCommisionFarePrice] = useState(true);
  // const [openCm, setOpenCm] = useState(false);
  // const [defaultCommissionRate, setDefaultCommissionRate] = useState(true);
  // const [defaultCommissionRateAmount, setDefaultCommissionRateAmount] =
  //   useState(0);
  // const [isChange, setIsChange] = useState(false);
  //todo:end

  //todo:all flight and nxt and previous day data states
  const [isLoaded, setIsLoaded] = useState(false);
  // const [datas, setDatas] = useState(RoundWayFakeData);
  const [datas, setDatas] = useState([]);
  const [data2, setData2] = useState([]);
  // const [data2, setData2] = useState(RoundWayFakeData);
  const [noData, setNoData] = useState("No Data");
  const [modifyOpen, setModifyOpen] = useState(false);
  const modifyHandleOpen = () => setModifyOpen(true);
  const modifyHandleClose = () => setModifyOpen(false);
  //todo: state for retrigger useEffect
  const [changeState, setChangeState] = useState(null);
  //todo: End for retrigger useEffect
  //todo: state for from date change
  const [changeFrom, setChangeFrom] = useState(false);
  //todo: End state for from date change
  // todo:Sets the state of the const for the given page and state.
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  let size = 30;
  // todo: previous day and next day date variables
  let tomorrowDepartureDate = new Date(fromSearchDate);
  let tomorrowReturnDate = new Date(toSearchDate);
  tomorrowDepartureDate.setDate(tomorrowDepartureDate.getDate() + 1);
  tomorrowReturnDate.setDate(tomorrowReturnDate.getDate() + 1);
  let yesterdayDepartureDate = new Date(fromSearchDate);
  let yesterdayReturnDate = new Date(toSearchDate);
  yesterdayDepartureDate.setDate(yesterdayDepartureDate.getDate() - 1);
  yesterdayReturnDate.setDate(yesterdayReturnDate.getDate() - 1);
  // Handle a page change.
  const handlePageChange = (event, value) => {
    setPage(value);
    setData2(datas?.slice((value - 1) * size, value * size));
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  let body = {
    Segment: [
      {
        DepFrom: `${fromSendData?.replace(/\s+/g, "")}`,
        ArrTo: toSendData,
        DepDate: `${new Date(departureDate).toLocaleDateString("sv")}`,
      },
      {
        DepFrom: toSendData,
        ArrTo: `${fromSendData?.replace(/\s+/g, "")}`,
        DepDate: `${new Date(returningDate).toLocaleDateString("sv")}`,
      },
    ],
    tripType: tripType,
    Adult: adultCount,
    Child: childCount,
    Infant: infant,
    Class: "economy",
  };
  console.log(body);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.gallpax.com/v1/Duffel/AirSearchv1.php",
          {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        if (data.length !== 0) {
          setIsLoaded(true);
          const uniqueData = data;
          const count = uniqueData.length;
          const pageNumber = Math.ceil(count / size);
          setPageCount(pageNumber);
          setDatas(uniqueData);
          setData2(uniqueData);
        } else {
          Swal.fire({
            imageUrl: Search,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
            title: "No Flights Found",
            confirmButtonText: "Search Again...",
            confirmButtonColor: "var(--primary-color)",
          }).then(function () {
            navigate("/");
          });
        }
      } catch (error) {
        Swal.fire({
          imageUrl: Search,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: "No Flights Found",
          confirmButtonText: "Search Again...",
          confirmButtonColor: "var(--primary-color)",
        }).then(function () {
          navigate("/");
        });
      }
    };
    fetchData();
  }, [
    changeState,
    size,
    departureDate,
    fromSendData,
    toSendData,
    adultCount,
    childCount,
    infant,
    tripType,
    navigate,
  ]);

  // if (!isLoaded) {
  //   return (
  //     <RoundPreloader
  //       departureDate={departureDate}
  //       returningDate={returningDate}
  //       isNextClicked={isNextClicked}
  //       isPrevClicked={isPrevClicked}
  //       fromSearchDate={fromSearchDate}
  //       toSearchDate={toSearchDate}
  //       fromSendData={fromSendData}
  //       toSendData={toSendData}
  //       className={className}
  //       adultCount={adultCount}
  //       childCount={childCount}
  //       infant={infant}
  //     />
  //   );
  // }

  return (
    <Box>
      <LandingHeader />{" "}
      <Container>
        <Box mt={4}>
          <Grid container columnSpacing={2}>
            {/* //todo: filter*/}
            <Grid
              item
              sx={{
                p: 0,
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "block",
                },
              }}
              lg={2.7}
              width="100%"
            >
              {isLoaded ? (
                <OneWayFilter
                  data={datas}
                  setData={setDatas}
                  filteredData={data2}
                  setfilteredData={setData2}
                  noData={noData}
                  setNoData={setNoData}
                  departureDate={departureDate}
                  setFrom={setFrom}
                />
              ) : (
                <Box
                  style={{
                    height: "100%",
                    width: "100%",
                    margin: "10px 0px",
                  }}
                >
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={"100%"}
                  />
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={9.3}>
              {/* //todo: Flight Search Result section */}
              <Grid container>
                <Grid className="modify-search" container columnSpacing={2}>
                  <Grid item md={8} mb={2}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <FlightTakeoffIcon
                        style={{
                          width: "25px",
                          height: "25px",
                          padding: "5px",
                          backgroundColor: "var(--primary-color)",
                          color: "var(--white)",
                          borderRadius: "100%",
                        }}
                      />
                      <Typography
                        sx={{
                          color: "var(--white)",
                          fontSize: { xs: 12, sm: 18, md: 22 },
                          mx: 1,
                        }}
                      >
                        Flight Search Result
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        color: "var(--white)",
                        fontSize: { xs: 12, sm: 14, md: 15 },
                      }}
                    >
                      {fromSearchText.trim()} <span>-</span>{" "}
                      {toSearchText.trim()}
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--gray)",
                        fontSize: "13px",
                      }}
                    >
                      {tripType === "oneway"
                        ? "One Way"
                        : tripType === "return"
                        ? "Return"
                        : "Multi City"}{" "}
                      Flight<span> | </span>{" "}
                      {format(
                        new Date(
                          isNextClicked || isPrevClicked
                            ? fromSearchDate
                            : departureDate
                        ),
                        "dd MMM yyyy"
                      )}
                      <span> | </span>
                      {format(
                        new Date(
                          isNextClicked || isPrevClicked
                            ? toSearchDate
                            : returningDate
                        ),
                        "dd MMM yyyy"
                      )}
                      <span> | </span>
                      {adultCount > 0 && `Adult(${adultCount})`}
                      {childCount > 0 && `Children(${childCount})`}
                      {infant > 0 && `Infant(${infant})`}
                    </Typography>
                  </Grid>
                  <Grid item md={4} display="flex" justifyContent="flex-end">
                    {/*  //todo:modify search  */}
                    <Box>
                      <Button
                        onClick={modifyHandleOpen}
                        style={{
                          backgroundcolor: "var(--white)",
                          color: "var(--white)",
                          padding: "5px 10px",
                          cursor: "pointer",
                        }}
                      >
                        Modify Search
                      </Button>

                      <Modal open={modifyOpen} onClose={modifyHandleClose}>
                        <Container>
                          <Box sx={modalStyle}>
                            <FlightSearchBox
                              options={options}
                              setOptions={setOptions}
                              type={type}
                              setType={setType}
                              value={value}
                              setValue={setValue}
                              fromSearchText={roundWayFromSearchText}
                              setFromSearchText={setRoundWayFromSearchText}
                              toSearchText={roundWayToSearchText}
                              setToSearchText={setRoundWayToSearchText}
                              from={from}
                              setFrom={setFrom}
                              to={to}
                              setTo={setTo}
                              faddress={roundWayFaddress}
                              setfaddress={setRoundWayFaddress}
                              toAddress={roundWayToAddress}
                              setToAddress={setRoundWayToAddress}
                              fromSendData={roundWayFromSendData}
                              setFromSendData={setRoundWayFromSendData}
                              toSendData={roundWayToSendData}
                              setToSendData={setRoundWayToSendData}
                              adultCount={roundWayAdultCount}
                              setAdultCount={setRoundWayAdultCount}
                              childCount={roundWayChildCount}
                              setChildCount={setRoundWayChildCount}
                              infant={roundWayInfant}
                              setInfant={setRoundWayInfant}
                              result={result}
                              setResult={setResult}
                              className={roundWayClassName}
                              setClassName={setRoundWayClassName}
                              changeState={changeState}
                              setChangeState={setChangeState}
                              changeFrom={changeFrom}
                              setChangeFrom={setChangeFrom}
                            />
                          </Box>
                        </Container>
                      </Modal>
                    </Box>
                    {/* //todo: filter drawer for mobile */}
                  </Grid>
                </Grid>
                {/* //todo: AirLine Slider */}

                {/* //todo:Search Result Section */}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {isLoaded
                    ? data2?.slice(0, size)?.map((roundTrip, index) => {
                        return (
                          <RoundSingleFlight
                            key={index}
                            flightData={roundTrip}
                            adultCount={adultCount}
                            childCount={childCount}
                            infant={infant}
                            from={fromSendData}
                            to={toSendData}
                            fromAddress={faddress}
                            toAddress={toAddress}
                            tripType={tripType}
                            departureDate={departureDate}
                            returingDate={returningDate}
                            agentFarePrice={agentFarePrice}
                            setAgentFarePrice={setAgentFarePrice}
                            commisionFarePrice={commisionFarePrice}
                            setCommisionFarePrice={setCommisionFarePrice}
                            customerFare={customerFare}
                            setCustomerFare={setCustomerFare}
                          />
                        );
                      })
                    : [...new Array(5)].map((data, index) => (
                        <Box
                          key={index}
                          style={{
                            width: "100%",
                            height: "150px",
                            margin: "10px 0px",
                            borderRadius: "5px",
                            overFlow: "hidden",
                          }}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={"100%"}
                          />
                        </Box>
                      ))}
                </Grid>
                {/* //todo: Pagination section */}
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box
                    sx={{
                      width: "100%",
                      my: 3,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Stack spacing={2}>
                      <Pagination
                        count={pageCount}
                        onChange={handlePageChange}
                        shape="rounded"
                      />
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default RoundSearchResult;
