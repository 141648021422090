import {
  Box,
  ClickAwayListener,
  Grid,
  Typography,
  Button,
  Collapse,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { addDays, format } from "date-fns";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { AiOutlineSwap } from "react-icons/ai";
import { IoIosPaperPlane } from "react-icons/io";
import Swal from "sweetalert2";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import secureLocalStorage from "react-secure-storage";
import { useEffect } from "react";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SearchIcon from "@mui/icons-material/Search";
import GroupsIcon from "@mui/icons-material/Groups";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import flightData from "../flightData";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto var(--secondary-color)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "var(--primary-color)",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "var(--secondary-color)",
  },
});

// Inspired by blueprintjs
function BpRadio(props) {
  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}
const Multicity = ({
  tripType,
  faddress,
  setfaddress,
  toAddress,
  setToAddress,
  fromSearchText,
  setFromSearchText,
  fromSendData,
  setFromSendData,
  toSendData,
  setToSendData,
  toSearchText,
  setToSearchText,
  departureDate,
  setDepartureDate,
  setValue,
  adultCount,
  setAdultCount,
  childCount,
  setChildCount,
  infant,
  setInfant,
  result,
  setResult,
  className,
  handleClassName,
  from,
  setFrom,
  to,
  setTo,
  setChangeState,
  changeState,
  changeFrom,
  setChangeFrom,
  searchData,
  setSearchData,
}) => {
  const data = flightData; // json data from flight Data
  const navigate = useNavigate();
  // todo: multiCity search Body

  const addCity = () => {
    const tempSearchData = [...searchData.Segment];
    tempSearchData.push({
      id: tempSearchData.length,
      DepFrom: tempSearchData[tempSearchData.length - 1].ArrTo,
      depFromText: tempSearchData[tempSearchData.length - 1].arrToText,
      ArrTo: "DXB",
      arrToText: "Dubai Intl Airport (DXB)",
      openTo: false,
      DepDate: new Date().toLocaleDateString("sv"),
      openDate: false,
      open: false,
    });
    setSearchData({
      ...searchData,
      Segment: tempSearchData,
      CityCount: tempSearchData.length,
    });
  };
  const removeCity = (id) => {
    const tempSearchData = searchData.Segment.filter((item) => item.id !== id);
    setSearchData({
      ...searchData,
      Segment: tempSearchData,
      CityCount: tempSearchData.length,
    });
  };
  // todo: end multiCity search Body

  const initialData = [
    {
      code: "BKK",
      name: "Suvarnabhumi Intl Airport",
      Address: "Bangkok,THAILAND",
    },
    { code: "PER", name: "Perth Intl Airport", Address: "Perth,AUSTRALIA" },

    {
      code: "KUL",
      name: "Kuala Lumpur Intl Airport",
      Address: "Kuala Lumpur,MALAYSIA",
    },
    {
      code: "SIN",
      name: "Changi Intl Airport",
      Address: "Singapore,SINGAPORE",
    },
    {
      code: "SYD",
      name: "Sydney Kingsford Smith Intl Airport",
      Address: "Sydney,AUSTRALIA",
    },
  ];
  //todo: users section
  const [users, setUsers] = useState("");
  useEffect(() => {
    const users = secureLocalStorage.getItem("user-info");
    if (users) {
      setUsers(users);
    }
  }, []);
  // todo: end of users section
  //todo: is Click state
  const [click, setClick] = useState(false);
  //todo: end of click state
  const [fromSuggest, setFromSuggest] = useState(initialData);
  const [toSuggest, setToSuggest] = useState(initialData);

  const [open, setOpen] = useState(false);
  const [openDate, setOpenDate] = useState(false);

  const [openFrom, setOpenFrom] = useState(false);
  const [openTo, setOpenTo] = useState(false);

  // Opens the dialog when the user clicks.
  const handleClickOpen = (index, segment) => {
    const tempSearchData = [...searchData.Segment];
    tempSearchData[index] = {
      ...tempSearchData[index],
      openTo: false,
      openDate: false,
      open: !segment.open,
    };
    setSearchData({ ...searchData, Segment: tempSearchData });
    setOpen((prev) => !prev);
    setOpenFrom(false);
    setOpenTo(false);
    setOpenDate(false);
  };

  // Closes the child process.
  const handleClose = (index) => {
    const tempSearchData = [...searchData.Segment];
    tempSearchData[index] = {
      ...tempSearchData[index],
      openTo: false,
      openDate: false,
      open: false,
    };
    setSearchData({ ...searchData, Segment: tempSearchData });
    setOpen(false);
    setOpenFrom(false);
    setOpenTo(false);
    setOpenDate(false);
    setResult(adultCount + childCount + infant);
  };

  // Sets the number of children.
  function adultInclement(e) {
    e.preventDefault();
    if (adultCount < 9 - (childCount + infant)) {
      setAdultCount(adultCount + 1);
      setSearchData({ ...searchData, adultCount: adultCount + 1 });
    }
  }

  // Decrement the count of children.
  function adultDecrement(e) {
    e.preventDefault();
    if (adultCount > 1) {
      setAdultCount(adultCount - 1);
      setSearchData({ ...searchData, adultCount: adultCount - 1 });
      if (infant === adultCount) {
        if (infant > 1) {
          setInfant(infant - 1);
          setSearchData({ ...searchData, infant: infant - 1 });
        }
      }
    }
  }

  function childInclement(e) {
    e.preventDefault();
    if (childCount < 9 - (adultCount + infant)) {
      setChildCount(childCount + 1);
      setSearchData({ ...searchData, childCount: childCount + 1 });
    }
  }

  function childDecrement(e) {
    e.preventDefault();
    if (childCount > 0) {
      setChildCount(childCount - 1);
      setSearchData({ ...searchData, childCount: childCount - 1 });
    }
  }

  // Increment the default value if the value is not a child.
  function infantIncrement(e) {
    e.preventDefault();
    if (infant < 9 - (adultCount + childCount)) {
      if (infant < adultCount) {
        setInfant(infant + 1);
        setSearchData({ ...searchData, infantCount: infant + 1 });
      }
    }
  }

  // Decrement the infant by 1.
  function infantDecrement(e) {
    e.preventDefault();
    if (infant > 0) {
      setInfant(infant - 1);
      setSearchData({ ...searchData, infantCount: infant - 1 });
    }
  }

  const formOnChange = (e) => {
    setOpen(false);
    const searchvalue = e.target.value;

    if (searchvalue.length > 2) {
      const suggestion = data.filter((item) =>
        item.code.toLowerCase().includes(searchvalue.toLowerCase())
      );
      setFromSuggest(suggestion);
      if (suggestion.length === 0) {
        const suggestion = data.filter(
          (item) =>
            item.code.toLowerCase().includes(searchvalue.toLowerCase()) ||
            item.Address.toLowerCase().includes(searchvalue.toLowerCase())
        );
        setFromSuggest(suggestion);
      }
    } else {
      setFromSuggest(initialData);
    }
  };

  const fromGetSuggetion = (index) => {
    const fromSuggestedText = (name, code, address) => {
      const tempSearchData = [...searchData.Segment];
      tempSearchData[index] = {
        ...tempSearchData[index],
        DepFrom: code.trim(),
        depFromText: `${name} (${code.trim()})`,
        depAddress: address,
        openFrom: false,
        openTo: true,
      };
      setSearchData({ ...searchData, Segment: tempSearchData });
      setFromSendData(code);
      setFromSearchText(`${name} (${code})`);
      setFromSuggest([]);
      setfaddress(address);
      setOpen(false);
      setOpenFrom(false);
      setOpenTo(true);
    };
    return (
      <Box
        style={{
          height: "fit-content",
          position: "relative",
          width: "100%",
          zIndex: "100",
        }}
      >
        <Box
          sx={{
            maxHeight: "230px",
            overflowY: "auto",
            background: "#fff",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
            "&::-webkit-scrollbar": { width: "5px" },
          }}
        >
          {fromSuggest.length !== 0 ? (
            fromSuggest.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    paddingLeft: "20px",
                    paddingRight: "10px",
                    backgroundColor: "var( --secondary-color)",
                    transition: "all .5s ease-in-out",
                    "&:hover": {
                      backgroundColor: "var(--primary-color)",
                      color: "#fff",
                    },
                    "&:hover .address": { color: "#fff" },
                  }}
                >
                  <Box
                    sx={{
                      margin: "0px 0px",
                      padding: "5px 0px",
                      cursor: "pointer",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                    onClick={() => {
                      fromSuggestedText(
                        ` ${item.name}`,
                        ` ${item.code} `,
                        `${item.Address}`
                      );
                    }} //suggest to display name select with multiple data pass parameter
                  >
                    <Box>
                      <Typography
                        className="address"
                        sx={{
                          fontSize: "12px",
                          color: "var(--primary-color)",
                          display: "block",
                          textAlign: "left",
                          fontWeight: "500",
                        }}
                      >
                        {item.Address}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "11px",
                          display: "block",
                          textAlign: "left",
                          color: "#fff",
                        }}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "13px",
                          display: "block",
                          textAlign: "left",
                          paddingRight: "5px",
                          color: "#999",
                          fontWeight: 400,
                        }}
                      >
                        {item.code}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box>
              <Typography
                variant="subtitle-2"
                style={{
                  color: "var(--primary-color)",
                  fontWidth: "bold",
                  paddingLeft: "10px",
                }}
              >
                Not found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };
  const toOnChange = (e) => {
    const searchvalue = e.target.value;
    if (searchvalue.length > 2) {
      const suggestion = data.filter((item) =>
        item.code.toLowerCase().includes(searchvalue.toLowerCase())
      );
      setToSuggest(suggestion);
      if (suggestion.length === 0) {
        const suggestion = data.filter(
          (item) =>
            item.code.toLowerCase().includes(searchvalue.toLowerCase()) ||
            item.Address.toLowerCase().includes(searchvalue.toLowerCase())
        );
        setToSuggest(suggestion);
      }
    } else {
      setToSuggest(initialData);
    }
  };

  const toGetSuggetion = (index) => {
    const toSuggestedText = (name, code, address) => {
      const tempSearchData = [...searchData.Segment];
      if (index === tempSearchData.length - 1) {
        tempSearchData[index] = {
          ...tempSearchData[index],
          ArrTo: code.trim(),
          arrToText: `${name} (${code.trim()})`,
          arrAddress: address,
          openFrom: false,
          openTo: false,
          openDate: true,
        };
      } else {
        tempSearchData[index] = {
          ...tempSearchData[index],
          ArrTo: code.trim(),
          arrAddress: address,
          arrToText: `${name} (${code.trim()})`,
          openFrom: false,
          openTo: false,
          openDate: true,
        };
        tempSearchData[index + 1] = {
          ...tempSearchData[index + 1],
          DepFrom: code.trim(),
          depFromText: `${name} (${code.trim()})`,
          depAddress: address,
        };
      }
      setSearchData({ ...searchData, Segment: tempSearchData });
      setToSendData(code);
      setToSearchText(`${name} (${code})`);
      setToSuggest([]);
      setToAddress(address);
      setOpenTo(false);
      setTimeout(() => setOpenDate(true), 200);
    };
    return (
      <Box
        style={{
          height: "fit-content",
          position: "relative",
          width: "100%",
          zIndex: "100",
        }}
      >
        <Box
          sx={{
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
            maxHeight: "230px",
            overflowY: "auto",
            background: "#fff",
            "&::-webkit-scrollbar": { width: "5px" },
          }}
        >
          {toSuggest.length !== 0 ? (
            toSuggest.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    paddingLeft: "20px",
                    paddingRight: "5px",
                    backgroundColor: "var( --secondary-color)",
                    transition: "all .5s ease-in-out",
                    "&:hover": {
                      backgroundColor: "var(--primary-color)",
                      color: "#fff",
                    },
                    "&:hover .address": { color: "#fff" },
                  }}
                >
                  <Box
                    sx={{
                      margin: "0px 0px",
                      padding: "5px 0px",
                      cursor: "pointer",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                    onClick={() =>
                      toSuggestedText(
                        ` ${item.name}`,
                        `${item.code}`,
                        `${item.Address}`
                      )
                    } //suggest to display name select with multiple data pass parameter
                  >
                    <Box>
                      <Typography
                        className="address"
                        sx={{
                          fontSize: "12px",
                          color: "var(--primary-color)",
                          display: "block",
                          textAlign: "left",
                          fontWeight: "500",
                        }}
                      >
                        {item.Address}
                      </Typography>
                      <span
                        style={{
                          fontSize: "11px",
                          display: "block",
                          color: "#fff",
                          textAlign: "left",
                        }}
                      >
                        {item.name}
                      </span>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13px",
                          paddingRight: "10px",
                          display: "block",
                          textAlign: "left",
                          color: "#999",
                          fontWeight: 400,
                        }}
                      >
                        {item.code}
                      </span>
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box>
              <Typography
                variant="subtitle2"
                style={{
                  color: "var(--primary-color)",
                  fontWidth: "bold",
                  paddingLeft: "10px",
                }}
              >
                Not found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const handleSelect = (date, index) => {
    const tempSearchData = [...searchData.Segment];
    tempSearchData[index] = {
      ...tempSearchData[index],
      DepDate: new Date(date).toLocaleDateString("sv"),
      openDate: false,
      openFrom: false,
      openTo: false,
    };
    setSearchData({ ...searchData, Segment: tempSearchData });
    setFrom(date);
    setTo(addDays(date, 3));
    setOpenDate(false);
    index === 0 && setOpen(true);
  };

  const handleClickAway = (index) => {
    setOpenFrom(false);
    setOpenTo(false);
    setOpenDate(false);
    setOpen(false);
    setResult(adultCount + childCount + infant);
    const tempSegment = [...searchData.Segment];
    tempSegment[index] = {
      ...tempSegment[index],
      openFrom: false,
      openTo: false,
      openDate: false,
      open: false,
    };
    setSearchData({
      ...searchData,
      Segment: tempSegment,
    });
  };

  async function handleSearch(e) {
    e.preventDefault();
    //todo: setChangeState for reTrigger useEffect
    setChangeState((prev) => !prev);
    //todo: setChangeState for reTrigger useEffect end

    // todo: setClick to true to start loader
    setClick(true);
    secureLocalStorage.setItem("multi-city", {
      faddress,
      toAddress,
      fromSearchText,
      toSearchText,
      departureDate: format(new Date(from), "dd MMM yy"),
      adultCount,
      childCount,
      infant,
      tripType,
      fromSendData,
      toSendData,
      className,
      searchData,
      changeState,
    });

    navigate("/user/multicityaftersearch", {
      state: {
        faddress,
        toAddress,
        fromSearchText,
        toSearchText,
        departureDate: format(new Date(from), "dd MMM yy"),
        adultCount,
        childCount,
        infant,
        tripType,
        fromSendData,
        toSendData,
        className,
        searchData,
        changeState,
      },
    });
  }

  return (
    <Box style={{ position: "relative", marginTop: "20px" }}>
      <form onSubmit={handleSearch}>
        <Grid container rowGap={1}>
          {searchData?.Segment?.map((segment, index, arr) => (
            <ClickAwayListener onClickAway={() => handleClickAway(index)}>
              <Grid
                key={index}
                sx={{
                  height: "fit-content",
                  width: "100%",
                  position: "relative",
                  mt: { md: "0px", sm: "5px", xs: "5px" },
                  background: "#fff",
                }}
                container
                alignItems="center"
                rowSpacing={{ lg: 0, md: 0, sm: 1, xs: 1 }}
                columnSpacing={0}
              >
                <Grid
                  container
                  alignContent="center"
                  md={7}
                  sx={{
                    borderRight: "1px solid var(--border-color)",
                    borderRadius: "5px 0px 0px 5px",
                    height: "100%",
                  }}
                >
                  {/* //todo: Departure City section */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    sx={{
                      position: "relative",
                      borderRight: "1px solid var(--border-color)",
                      padding: "5px 0px 0px 15px",
                      height: "82px",
                    }}
                  >
                    <Box
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        height: "100%",
                      }}
                      onClick={() => {
                        const tempSegment = [...searchData.Segment];
                        tempSegment[index] = {
                          ...tempSegment[index],
                          openFrom: !segment.openFrom,
                          openTo: false,
                          openDate: false,
                        };
                        setSearchData({
                          ...searchData,
                          Segment: tempSegment,
                        });
                        setOpenFrom((prev) => !prev);
                        setOpenTo(false);
                        setOpenDate(false);
                        setOpen(false);
                      }}
                    >
                      <Box
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          // justifyContent: "center",
                          // alignItems: "start",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "var(--gray)",
                            fontSize: "14px",
                            fontWeight: "500",
                            width: "100%",
                          }}
                        >
                          From
                        </Typography>
                        <Typography
                          style={{
                            color: "var(--primary-color)",
                            width: "100%",
                            fontSize: "18px",
                            fontWeight: "500",
                          }}
                          noWrap
                        >
                          {segment.depAddress?.split(",")[0]}
                        </Typography>
                        <Typography
                          style={{
                            color: "var(--secondary-color)",
                            width: "100%",
                            fontSize: "14px",
                            fontWeight: "normal",
                          }}
                          noWrap
                        >
                          {segment.depFromText}
                        </Typography>
                      </Box>
                    </Box>
                    <Collapse
                      in={segment.openFrom}
                      timeout="auto"
                      unmountOnExit
                      sx={{ width: "100%" }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: "90%",
                          left: "0",
                          width: "96%",
                          backgroundColor: "var( --secondary-color)",
                          height: "fit-content",
                          borderBottom: "1px solid var(  --gray)",
                          borderLeft: "1px solid var(  --gray)",
                          borderRight: "2px solid var(  --gray)",
                          borderRadius: "0px 0px 5px 5px",
                          zIndex: "999",
                          padding: "5px 5px 0",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "var(--secondary-color)",
                            zIndex: 10,
                          }}
                          backgroundColor="#fff"
                        >
                          <input
                            autoComplete="off"
                            autoFocus
                            onChange={formOnChange}
                            className="customPlaceholder"
                            placeholder="Search a airport..."
                            style={{
                              color: "var(--secondary-color)",
                              fontWeight: 500,
                              paddingLeft: "20px",
                              width: "100%",
                              height: "40px",
                              backgroundColor: "transparent",
                              border: "none",
                              outline: "none",
                            }}
                          />
                        </Box>
                        <Box>{fromGetSuggetion(index)}</Box>
                      </Box>
                    </Collapse>
                  </Grid>
                  {/* //todo: Arrival City section */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    sx={{
                      position: "relative",
                      padding: "5px 0px 0px 15px",
                      height: "82px",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        width: "100%",
                        height: "100%",
                      }}
                      onClick={() => {
                        const tempSegment = [...searchData.Segment];
                        tempSegment[index] = {
                          ...tempSegment[index],
                          openFrom: false,
                          openTo: !segment.openTo,
                          openDate: false,
                        };
                        setSearchData({
                          ...searchData,
                          Segment: tempSegment,
                        });
                        setOpenFrom(false);
                        setOpenTo((prev) => !prev);
                        setOpenDate(false);
                        setOpen(false);
                      }}
                    >
                      <Box style={{ width: "100%", height: "100%" }}>
                        <Typography
                          sx={{
                            color: "var(--gray)",
                            fontWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          To
                        </Typography>
                        <Typography
                          sx={{
                            color: "var(--primary-color)",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          {segment.arrAddress?.split(",")[0]}
                        </Typography>
                        <Typography
                          sx={{
                            width: "100%",
                            fontSize: "14px",
                            color: "var(--secondary-color)",
                            fontWeight: "normal",
                          }}
                        >
                          {segment.arrToText}
                        </Typography>
                      </Box>
                    </Box>
                    <Collapse
                      in={segment.openTo}
                      timeout="auto"
                      unmountOnExit
                      sx={{ width: "100%" }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: "90%",
                          left: "0",
                          width: "96%",
                          backgroundColor: "var( --secondary-color)",
                          height: "fit-content",
                          borderBottom: "1px solid var(  --gray)",
                          borderLeft: "1px solid var(  --gray)",
                          borderRight: "2px solid var(  --gray)",
                          borderRadius: "0px 0px 5px 5px",
                          zIndex: "999",
                          padding: "5px 5px 0",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "var(--secondary-color)",
                            zIndex: 10,
                          }}
                          backgroundColor="#fff"
                        >
                          <input
                            autoComplete="off"
                            autoFocus
                            onChange={toOnChange}
                            className="customPlaceholder"
                            placeholder="Search a airport..."
                            style={{
                              color: "var(--secondary-color)",
                              fontWeight: 500,
                              paddingLeft: "20px",
                              width: "100%",
                              height: "40px",
                              backgroundColor: "transparent",
                              border: "none",
                              outline: "none",
                            }}
                          />
                        </Box>
                        <Box>{toGetSuggetion(index)}</Box>
                      </Box>
                    </Collapse>
                  </Grid>
                </Grid>
                {/* //todo:Travel Date */}
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={3}
                  sx={{
                    borderRight: "1px solid var(--border-color)",
                    position: "relative",
                    padding: "5px 0px 0px 15px",
                    height: "82px",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        height: "100%",
                        width: { xs: "100%", sm: "50%" },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const tempSearchData = [...searchData.Segment];
                        tempSearchData[index] = {
                          ...tempSearchData[index],
                          openFrom: false,
                          openTo: false,
                          openDate: !segment.openDate,
                        };
                        setSearchData({
                          ...searchData,
                          Segment: tempSearchData,
                        });
                        setTimeout(() => setOpenDate((prev) => !prev), 200);
                        setOpenFrom(false);
                        setOpenTo(false);
                        setOpen(false);
                      }}
                    >
                      <Typography
                        sx={{
                          color: "var(--gray)",
                          fontSize: "14px",
                          fontWeight: "500",
                          width: "100%",
                          paddingTop: "5px",
                        }}
                      >
                        Travel Date &#10507;
                      </Typography>
                      <Typography
                        sx={{
                          color: "var(--primary-color)",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >{`${format(
                        new Date(segment?.DepDate),
                        "dd MMM yy"
                      )}`}</Typography>

                      <Typography
                        sx={{
                          color: "var(--secondary-color)",
                          fontSize: "12px",
                          fontWeight: "normal",
                        }}
                      >
                        {`${format(new Date(segment?.DepDate), "dd MMM yy")}`}
                      </Typography>
                    </Box>
                    <Tooltip title={"Click To Go Round Way"}>
                      <Box
                        sx={{
                          height: "100%",
                          width: "50%",
                          cursor: "pointer",
                          display: { xs: "none", sm: "block" },
                        }}
                        onClick={() => setValue("return")}
                      >
                        <Typography
                          sx={{
                            color: "var(--gray)",
                            fontSize: "14px",
                            fontWeight: "500",
                            width: "100%",
                            paddingTop: "5px",
                          }}
                        >
                          Return Date &#10507;
                        </Typography>
                        <Typography
                          sx={{
                            color: "var(--secondary-color)",
                            fontSize: "14px",
                            fontWeight: "normal",
                          }}
                        >
                          Add +
                        </Typography>

                        <Typography
                          sx={{
                            color: "var(--secondary-color)",
                            fontSize: "14px",
                            fontWeight: "normal",
                          }}
                        >
                          Return Date
                        </Typography>
                      </Box>
                    </Tooltip>
                  </Box>
                  {segment.openDate && (
                    <Box
                      sx={{
                        display: {
                          md: "flex",
                          sm: "block",
                          xs: "block",
                        },
                        background: "#fff",
                        flexFlow: "column nowrap",
                      }}
                      className="new-dashboard-calendar"
                    >
                      <Typography
                        sx={{
                          fontSize: "14px !important",
                          color: "var(--primary-color) !important",
                        }}
                      >
                        Travel Date
                      </Typography>
                      {/* <Calendar
                        color="var(--primary-color)"
                        date={new Date(searchData[index]?.Date)}
                        onChange={(date) => handleSelect(date, index)}
                        months={1}
                        minDate={
                          index === 0
                            ? new Date()
                            : new Date(arr[index - 1]?.Date)
                        }
                      /> */}
                      <Calendar
                        color="var(--primary-color)"
                        // date={new Date(from)}
                        onChange={(date) => handleSelect(date, index)}
                        months={1}
                        minDate={new Date()}
                        className={"dashboard-calendar"}
                      />
                    </Box>
                  )}
                </Grid>

                {/* //todo: Passenger Box section */}
                {index === 0 ? (
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={2}
                    sx={{
                      position: "relative",
                      height: "82px",
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: {
                            md: "start",
                            sm: "center",
                            xs: "center",
                          },
                          alignItems: "center",
                        }}
                        onClick={() => handleClickOpen(index, segment)}
                      >
                        <Box
                          sx={{
                            justifyContent: "start",
                            color: "#000",
                            display: "block",
                            padding: "5px 0px 0px 15px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "var(--gray)",
                              fontWeight: "500",
                              fontSize: "14px",
                              textTransform: "capitalize",
                              textAlign: "left",
                            }}
                          >
                            Travelers & Class
                          </Typography>
                          <Typography
                            sx={{
                              width: "100%",
                              color: "var(--primary-color)",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            {result} Traveler
                          </Typography>
                          <Typography
                            sx={{
                              width: "100%",
                              color: "var(--secondary-color)",
                              fontSize: "12px",
                              fontWeight: "500",
                            }}
                          >
                            {className}
                          </Typography>
                        </Box>
                      </Box>

                      {segment.open && (
                        <Box>
                          <Box
                            className="dialog-box"
                            sx={{
                              backgroundColor: "#FFF",
                              padding: "5px 10px 20px 10px",
                              overflow: "hidden",
                              width: "285px",
                              zIndex: "999",
                            }}
                          >
                            <Box className="passenger-h3">
                              <h3>Passenger</h3>
                            </Box>
                            <Box className="dialog-flex">
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "20px",
                                  alignItems: "center",
                                }}
                                className="dialog-content"
                              >
                                <Box className="dialog-content">
                                  <h5>{adultCount}</h5>
                                </Box>
                                <Box>
                                  <h5>Adult</h5>
                                  <span style={{ fontSize: "13px" }}>
                                    12+ yrs
                                  </span>
                                </Box>
                              </Box>
                              <Box className="incre-decre">
                                <button onClick={adultDecrement}>-</button>

                                <button onClick={adultInclement}>+</button>
                              </Box>
                            </Box>

                            <Box className="dialog-flex-child">
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "20px",
                                  alignItems: "center",
                                }}
                                className="dialog-content"
                              >
                                <Box className="dialog-content">
                                  <h5>{childCount}</h5>
                                </Box>
                                <Box>
                                  <h5>Children</h5>
                                  <span style={{ fontSize: "13px" }}>
                                    2- less than 12 yrs
                                  </span>
                                </Box>
                              </Box>
                              <Box className="incre-decre">
                                <button onClick={childDecrement}>-</button>

                                <button onClick={childInclement}>+</button>
                              </Box>
                            </Box>

                            <Box className="dialog-flex-infant">
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "20px",
                                  alignItems: "center",
                                }}
                                className="dialog-content"
                              >
                                <Box className="dialog-content">
                                  <h5>{infant}</h5>
                                </Box>
                                <Box>
                                  <h5>Infant</h5>
                                  <span style={{ fontSize: "13px" }}>
                                    0 - 23 month{" "}
                                  </span>
                                </Box>
                              </Box>
                              <Box className="incre-decre">
                                <button onClick={infantDecrement}>-</button>

                                <button onClick={infantIncrement}>+</button>
                              </Box>
                            </Box>
                            <Box className="hr-line"></Box>
                            <Box className="new-passengerBtn">
                              <Box>
                                <FormControl>
                                  <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={className}
                                    row
                                    onChange={handleClassName}
                                  >
                                    <FormControlLabel
                                      value="Economy"
                                      control={<BpRadio />}
                                      label="Economy"
                                      sx={{
                                        mr: "21px",
                                      }}
                                    />
                                    <FormControlLabel
                                      value="Business"
                                      control={<BpRadio />}
                                      label="Business"
                                    />
                                    <FormControlLabel
                                      value="First Class"
                                      control={<BpRadio />}
                                      label="First Class"
                                    />
                                    <FormControlLabel
                                      value="Premium Economy "
                                      control={<BpRadio />}
                                      label="Premium Economy"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Box>
                              <Button
                                id="passengerSaveBtn"
                                size="small"
                                variant="contained"
                                color="error"
                                onClick={handleClose}
                                className="shine-effect"
                                sx={{
                                  bgcolor: "var(--primary-color)",
                                  "&:hover": {
                                    background: "var(--primary-color)",
                                  },
                                }}
                              >
                                DONE
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      )}
                      {/* </Grow> */}
                    </Box>
                  </Grid>
                ) : (
                  <Grid
                    md={2}
                    sm={6}
                    xs={6}
                    justifyContent="center"
                    alignItems={"center"}
                  >
                    <Box
                      sx={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <Button
                        title="Add City"
                        disabled={arr.length > 4 ? true : false}
                        sx={{
                          fontSize: "14px",
                          height: "fit-content",
                          width: "fit-content",
                          padding: "5px 20px",
                          backgroundColor: "var(--primary-color)",
                          color: "#fff",
                          textTransform: "capitalize",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          "&:hover": {
                            backgroundColor: "var(--primary-color)",
                            cursor: "pointer",
                          },
                        }}
                        onClick={addCity}
                      >
                        <AddIcon />
                      </Button>
                      <Button
                        title="Remove City"
                        disabled={arr.length === 2 ? true : false}
                        sx={{
                          fontSize: "14px",
                          padding: "5px 20px",
                          height: "fit-content",
                          width: "fit-content",
                          backgroundColor: "var(--tomato-color)",
                          color: "#fff",
                          textTransform: "capitalize",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          "&:hover": {
                            backgroundColor: "var(--tomato-color)",
                            cursor: "pointer",
                          },
                        }}
                        onClick={() => removeCity(segment.id)}
                      >
                        <RemoveIcon />
                      </Button>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </ClickAwayListener>
          ))}
          {/* //todo: Search Button */}
          <Grid md={12} lg={12} sm={12} xs={12} style={{ height: "100%" }}>
            <Box
              sx={{
                height: { lg: "100%", md: "100%", sm: "70%", xs: "70%" },
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              mt={2}
            >
              <Button
                type="submit"
                className="shine-effect"
                sx={{
                  fontSize: "16px",
                  height: {
                    md: "40px",
                    sm: "40px",
                    xs: "40px",
                  },
                  width: {
                    md: "50%",
                    sm: "100%",
                    xs: "100%",
                  },
                  backgroundColor: "var(--primary-color)",
                  color: "#fff",
                  textTransform: "capitalize",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                  "&:hover": {
                    backgroundColor: "var(--primary-color)",
                    cursor: "pointer",
                  },
                }}
              >
                <IoIosPaperPlane style={{ fontSize: "30px" }} />
                {click ? "Wait..." : "Search"}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Multicity;
