import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useState, useEffect } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import AccCreated from "../../images/undraw/undraw_happy_announcement_re_tsm0.svg";
import ServerDown from "../../images/undraw/undraw_server_down_s-4-lk.svg";
import Invalid from "../../images/undraw/undraw_warning_re_eoyh.svg";
import "./SingUp.css";
import Footer from "../Footer/Footer";
import { LandingHeader } from "../LandingHeader/LandingHeader";

const SignUp = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const [companyname, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [state, setState] = useState({
    password: "",
    cPassword: "",
  });
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("975");
  const [checked, setChecked] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const registerinput = {
    firstname: fname,
    lastname: lname,
    phone: contactNumber,
    email: email,
    password: state.password,
    address: address,
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    //console.log(JSON.stringify(registerinput));
    fetch("https://api.gallpax.com/v1/Auth/index.php?signup", {
      method: "POST",
      body: JSON.stringify(registerinput),
    })
      .then((res) => res.json())
      .then((registeredData) => {
        //console.log(registeredData);
        if (registeredData.status === "success") {
          setIsLoading(false);
          Swal.fire({
            imageUrl: AccCreated,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
            title: "Account Created Successfully!",
            html: `Thank You <strong>${registerinput.firstname} ${registerinput.lastname}.</strong> Your Account is Successfully Created.`,
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Ok",
          }).then(function () {
            navigate("/signin");
          });
        } else {
          setIsLoading(false);
          Swal.fire({
            imageUrl: ServerDown,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
            title: `${registeredData?.message}`,
            html: "<strong>Please contact us at kayesthechill@gmail.com or +8801685370455.</strong>",
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Please Try Again!",
          }).then(function () {
            navigate("/signin");
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          imageUrl: ServerDown,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: `${err}`,
          html: "<strong>Please contact us at kayesthechill@gmail.com or +8801685370455.</strong>",
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Please Try Again!",
        }).then(function () {
          navigate("0");
        });
      });
    e.target.reset();
  };

  return (
    <Box>
      <LandingHeader />
      <Container>
        <Box
          style={{
            width: "100%",
            minHeight: "50vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box className="register-Box">
            <Box
              my={2}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  color: "var(--secondary-color)",
                  fontSize: "30px",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                Sign Up
              </Typography>
            </Box>

            <form onSubmit={handleSubmit} autocomplete="off">
              <Box width={{ xs: "100%", sm: "80%", md: "50%" }} margin="auto">
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    className="input-field"
                    sx={{ paddingTop: "15px !important" }}
                  >
                    <Box className="signUp">
                      <input
                        required
                        type="text"
                        id="fname"
                        maxLength={20}
                        placeholder="First Name"
                        name="fname"
                        value={fname}
                        autocomplete="off"
                        onChange={(e) => {
                          const result = e.target.value;
                          setFname(result);
                        }}
                        style={{
                          borderRadius: "3px",
                          background: "rgba(255, 168, 77, 0.25)",
                        }}
                      />
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{ paddingTop: "15px !important" }}
                  >
                    <Box className="signUp">
                      <input
                        required
                        type="text"
                        id="lname"
                        name="lname"
                        maxLength={20}
                        placeholder="Last Name"
                        autocomplete="off"
                        onChange={(e) => {
                          const result = e.target.value;
                          setLname(result);
                        }}
                        value={lname}
                        style={{
                          borderRadius: "3px",
                          background: "rgba(255, 168, 77, 0.25)",
                        }}
                      />
                    </Box>
                  </Grid>

                  {/* <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    className="input-field"
                    sx={{ paddingTop: "15px !important" }}
                  >
                    <Box className="signUp">
                      <input
                        required
                        type="text"
                        id="companyname"
                        placeholder="Company Name"
                        name="companyname"
                        autocomplete="off"
                        onChange={(e) => {
                          const result = e.target.value;
                          setCompanyName(result);
                        }}
                        value={companyname}
                        style={{
                          borderRadius: "3px",
                          background: "rgba(255, 168, 77, 0.25)",
                        }}
                      />
                    </Box>
                  </Grid> */}

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    className="input-field"
                    sx={{ paddingTop: "15px !important" }}
                  >
                    <Box className="signUp">
                      <input
                        required
                        type="email"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{
                          borderRadius: "3px",
                          background: "rgba(255, 168, 77, 0.25)",
                        }}
                      />
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    className="input-field"
                    sx={{
                      paddingTop: "15px !important",
                    }}
                  >
                    <Box
                      style={{
                        background: "rgba(255, 168, 77, 0.25)",
                        height: "38px",
                      }}
                    >
                      <PhoneInput
                        required
                        country={"btn"}
                        placeholder="Contact Number"
                        name="contactNumber"
                        id="contactNumber"
                        value={contactNumber}
                        onChange={(phone) => {
                          setContactNumber(phone);
                        }}
                        style={{ color: "#222222" }}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    // sm={6}
                    // md={6}
                    className="input-field"
                    sx={{ paddingTop: "15px !important" }}
                  >
                    <Box className="signUp">
                      <input
                        required
                        id="address"
                        type="text"
                        placeholder="Address"
                        name="address"
                        autoComplete="off"
                        onChange={(e) => setAddress(e.target.value)}
                        style={{
                          borderRadius: "3px",
                          background: "rgba(255, 168, 77, 0.25)",
                        }}
                      />
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    className="input-field password-00"
                    sx={{ paddingTop: "15px !important" }}
                  >
                    <Box
                      position={"relative"}
                      display="flex"
                      alignItems="center"
                      className="signUp"
                    >
                      <input
                        required
                        name="password"
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Password "
                        autocomplete="current-password"
                        id="password"
                        value={state.password}
                        onChange={handleChange}
                        style={{
                          borderRadius: "3px",
                          background: "rgba(255, 168, 77, 0.25)",
                        }}
                      />
                      <Box
                        position={"absolute"}
                        onClick={handleClickShowPassword}
                        mt="7px"
                        right="20px"
                      >
                        {showPassword ? (
                          <Visibility className="fonticon09" />
                        ) : (
                          <VisibilityOff className="fonticon09" />
                        )}
                      </Box>
                    </Box>
                    <Box
                      pl={1}
                      color="red"
                      style={{ fontSize: "14px" }}
                      margin="10px 0px"
                    >
                      {state.password.length < 8
                        ? "*Password at least 8 characters"
                        : null}
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    className="input-field password-00"
                    sx={{ paddingTop: "15px !important" }}
                  >
                    <Box
                      position={"relative"}
                      display="flex"
                      alignItems="center"
                      className="signUp"
                    >
                      <input
                        required
                        name="confirmPassword"
                        type={showPassword ? "text" : "password"}
                        placeholder="Confirm Password "
                        id="cPassword"
                        value={state.cPassword}
                        onChange={handleChange}
                        style={{
                          borderRadius: "3px",
                          background: "rgba(255, 168, 77, 0.25)",
                        }}
                      />
                      <Box
                        position={"absolute"}
                        onClick={handleClickShowPassword}
                        mt="7px"
                        right="20px"
                      >
                        {showPassword ? (
                          <Visibility className="fonticon09" />
                        ) : (
                          <VisibilityOff className="fonticon09" />
                        )}
                      </Box>
                    </Box>
                    <Box color="red">
                      {state.password === state.cPassword
                        ? ""
                        : "Passwords do not match"}
                    </Box>
                  </Grid>

                  <br />
                </Grid>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  margin="15px 5px"
                  fontSize={"10px"}
                >
                  <FormGroup>
                    <FormControlLabel
                      sx={{ color: "var(--primary-color)" }}
                      type="checkbox"
                      control={
                        <Checkbox
                          sx={{ color: "#fff" }}
                          checked={checked}
                          onClick={() => setChecked((prev) => !prev)}
                        />
                      }
                      label="Agree Terms and Conditions"
                    />
                  </FormGroup>
                  <Link
                    style={{ color: "var(--primary-color)", fontSize: "15px" }}
                    href="/terms"
                    target="_blank"
                    variant="caption text"
                  >
                    Terms & Condition
                  </Link>
                </Box>

                <Button
                  type="submit"
                  disabled={isLoading ? true : false}
                  sx={{
                    width: "100%",
                    height: "40px",
                    background: "var(--primary-color)",
                    color: "var(--white)",
                    "&:hover": {
                      background: "var(--primary-color)",
                      color: "var(--white)",
                    },
                  }}
                >
                  {isLoading ? (
                    <CircularProgress
                      style={{ height: "20px", width: "20px" }}
                    />
                  ) : (
                    "Register"
                  )}
                </Button>
              </Box>
            </form>

            <Box
              className="register-footer"
              margin="auto"
              textAlign="center"
              mt={2}
            >
              <Typography sx={{ color: "#fff" }}>
                Already have an account?
                <button
                  onClick={() => {
                    navigate("/signin");
                  }}
                >
                  Login Now
                </button>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default SignUp;
