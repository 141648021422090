import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import WorkIcon from "@mui/icons-material/Work";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Box,
  Typography,
  Container,
  CircularProgress,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AiFillCaretDown } from "react-icons/ai";
import commaNumber from "comma-number";
import Swal from "sweetalert2";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CircleIcon from "@mui/icons-material/Circle";
import seat1 from "../../images/Icon/bag.svg";
import bag from "../../images/Icon/seat.svg";
import anemy from "../../images/anemy.png";
import FlightUserInfo from "../../components/FlightUserinfo/FlightUserInfo";
import FlightUserInfoFlyHub from "../../components/FlightUserinfo/FlightUserInfoFlyHub";
import FlightUserInfoSabre from "../../components/FlightUserinfo/FlightUserInfoSabre";
import Loader from "../../images/loader/Render.gif";
import NotFound from "../../images/undraw/undraw_not_found_re_bh2e.svg";
import FlightInfoDetails from "../../components/FlightInfoDetails/FlightInfoDetails";
import FlightIcon from "@mui/icons-material/Flight";
import "./FlightInformation.css";
import { format } from "date-fns";
import moment from "moment";
import Header from "../../components/Header/Header";
import { useQuery } from "react-query";
import FlightPassengerInfo from "./FlightPassengerInfo";
import { LandingHeader } from "../../components/LandingHeader/LandingHeader";
import secureLocalStorage from "react-secure-storage";

const HtmlTooltip = styled(({ className, ...propss }) => (
  <Tooltip {...propss} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "crimson",
    maxWidth: 220,
    fontSize: "5px",
    borderRadius: "8px 0px 8px 0px",
  },
}));

const FlightInformation = (props) => {
  const location = useLocation();
  const OfferId = secureLocalStorage.getItem("Offerid");
  console.log("OfferIdx", OfferId);

  const [loadData, setLoadData] = useState([]);
  const {
    adultCount,
    childCount,
    infant,
    fromAddress,
    toAddress,
    tripType,
    from,
    to,
  } = OfferId;
  const [isLoaded, setIsLoaded] = useState(true);
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [flightData, setFlightData] = useState();
  // const flightData = location?.state?.flightData;
  // const tripType = location?.state?.tripType;
  // const offerid = flightData?.OfferId;
  // const { fromAddress, toAddress, from, to } = location?.state;

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((prevProgress) =>
  //       prevProgress >= 100 ? 0 : prevProgress + 10
  //     );
  //   }, 800);

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const url = `https://api.gallpax.com/v1/Duffel/AirPrice.php?offerId=${OfferId?.offerId}`;
  console.log(url);

  useEffect(() => {
    // setIsLoading(true);

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        setLoadData(data);
        setFlightData(data);
        setIsLoading(true);
        console.log(data);
      })
      .catch((error) => {
        setIsError(error);
        setIsLoading(true);
      });
  }, []);

  console.log("flightData", flightData);

  if (!isLoading) {
    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "70vh",
          width: "70vw",
          marginInline: "auto",
        }}
      >
        <Box
          style={{
            width: "50%",
            height: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Loader}
            alt="loader"
            style={{
              width: "40%",
              objectFit: "center",
            }}
          />
        </Box>
      </Box>
    );
  }

  if (isError) {
    return (
      <div>
        {Swal.fire({
          // imageUrl: Search,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: "No Flights Found",
          confirmButtonText: "Search Again...",
          confirmButtonColor: "var(--primary-color)",
        }).then(function () {
          navigate(-1);
        })}
      </div>
    );
  }

  return (
    <Box>
      <LandingHeader />
      <Container>
        <Grid container mt={5} columnSpacing={2}>
          <Grid item xs={12} sm={12} md={8} lg={8.5}>
            <Box>
              <Typography
                sx={{
                  fontSize: "22px",
                  color: "var(--primary-color)",
                  fontWeight: 500,
                }}
              >
                Flight Information Details
              </Typography>
              <Box bgcolor="var(--secondary-color)" my={3}>
                <Box>
                  <Box my={3}>
                    <Typography
                      sx={{ fontSize: { xs: "12px", sm: "20px" } }}
                      color="var(--white)"
                      fontWeight={500}
                    >
                      {/* {flightData?.Segments[0]?.DepCountry?.split(" ,")[0]}
                      {" - "}
                      {
                        flightData?.Segments[
                          flightData?.Segments?.length - 1
                        ]?.ArrCountry?.split(" ,")[0]
                      } */}
                      {fromAddress || ""}
                      {" - "}
                      {toAddress || ""}
                    </Typography>
                    <Typography sx={{ color: "#fff", my: 1, fontSize: 14 }}>
                      {tripType} Flight <span>|</span>{" "}
                      {adultCount > 0 && `Adult(${adultCount})`}
                      {childCount > 0 && `Children(${childCount})`}
                      {infant > 0 && `Infant(${infant})`} <span>|</span>{" "}
                      {format(
                        new Date(flightData?.Segments[0]?.DepTime),
                        "dd MMM yyyy"
                      )}
                      {" | "}
                      {format(
                        new Date(flightData?.Segments[0]?.ArrTime),
                        "dd MMM yyyy"
                      )}
                    </Typography>
                  </Box>
                  <Box>
                    <Box bgcolor="var(--bgcolor)" padding="8px 20px">
                      <Grid
                        container
                        justifyContent="space-between"
                        borderBottom="2px solid var(--primary-color)"
                      >
                        <Grid item>
                          <Typography
                            sx={{
                              color: "var(--white)",
                              fontSize: "14px",
                              fontWeight: 500,
                            }}
                          >
                            Depart:{" "}
                            <span
                              style={{
                                color: "var(--primary-color)",
                              }}
                            >
                              {format(
                                new Date(flightData?.Segments[0]?.DepTime),
                                "dd MMM yyyy"
                              )}
                            </span>{" "}
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--primary-color)",
                              fontSize: "14px",
                            }}
                          >
                            {flightData?.Segments?.length === 1 ? (
                              <>Non</>
                            ) : (
                              <>{flightData?.Segments?.length - 1} </>
                            )}
                            Stop
                            {/* {flightData?.flightduration}{" "} */}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={{ color: "var(--white)" }}>
                            {flightData?.Segments[0]?.DepCountry?.split(",")[0]}
                            {" - "}
                            {
                              flightData?.Segments[
                                flightData?.Segments?.length - 1
                              ]?.ArrCountry?.split(",")[0]
                            }
                          </Typography>
                        </Grid>
                      </Grid>

                      {flightData?.Segments.map((data, i, arr) => (
                        <Box my={2}>
                          <Grid container spacing={2}>
                            <Grid item sm={6} md={2.5}>
                              <Box className="career-image-logo">
                                <img
                                  src={`https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/${data?.MarkettingCarrier}.svg`}
                                  alt="flight logo"
                                  width="100%"
                                  height="100%"
                                />
                              </Box>
                              <Typography
                                sx={{
                                  color: "var(--primary-color)",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                }}
                              >
                                {data?.MarkettingCarrierName}
                                <br />
                                <span
                                  style={{
                                    color: "var(--fontcolor)",
                                  }}
                                >
                                  {data?.MarkettingCarrier}
                                  {"-"}
                                  {data?.MarkettingCarrierNumber}
                                </span>
                              </Typography>
                            </Grid>
                            <Grid item sm={6} md={3.5}>
                              <Typography>
                                <span
                                  style={{
                                    color: "var(--primary-color)",
                                    fontSize: "20px",
                                  }}
                                >
                                  {data?.DepCountry?.split(" ,")[0]},{" "}
                                </span>
                                <span
                                  style={{
                                    color: "var(--primary-color)",
                                    fontSize: "13px",
                                  }}
                                >
                                  {data?.DepFrom}
                                </span>
                                <br />
                                <Tooltip title={`${data?.DepAirport}`}>
                                  <Typography
                                    style={{
                                      color: "var(--white)",
                                      fontSize: "13px",
                                    }}
                                    noWrap
                                  >
                                    {data?.DepAirport}
                                  </Typography>
                                </Tooltip>

                                <span
                                  style={{
                                    color: "var(--fontcolor)",
                                    fontSize: "12px",
                                  }}
                                >
                                  {format(
                                    new Date(data?.DepTime?.toString()),
                                    "dd MMM yyyy hh:mm a"
                                  )}
                                </span>
                              </Typography>
                            </Grid>
                            <Grid item sm={6} md={2.5} margin="auto">
                              <Box textAlign="center">
                                <Tooltip title="Flight Duration">
                                  <Typography
                                    sx={{
                                      color: "var(--primary-color)",
                                      fontWeight: 500,
                                      fontSize: {
                                        xs: "10px",
                                        md: "12px",
                                      },
                                    }}
                                  >
                                    {moment
                                      .utc(
                                        moment(
                                          data?.DepTime,
                                          "YYYY-MM-DDTHH:mm:ss"
                                        ).diff(
                                          moment(
                                            data?.ArrTime,
                                            "YYYY-MM-DDTHH:mm:ss"
                                          )
                                        )
                                      )
                                      .format("HH:mm:ss")}
                                  </Typography>
                                </Tooltip>
                                <Box className="stop-bar-parent">
                                  <CircleIcon
                                    sx={{
                                      color: "var(--transit)",
                                      fontSize: "15px",
                                    }}
                                  />
                                  <Box className="stop-bar-line-details"></Box>
                                  <CircleIcon
                                    sx={{
                                      color: "var(--transit)",
                                      fontSize: "15px",
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item sm={6} md={3.5}>
                              <Typography>
                                <span
                                  style={{
                                    color: "var(--primary-color)",
                                    fontSize: "20px",
                                  }}
                                >
                                  {data?.ArrCountry?.split(" ,")[0]},{" "}
                                </span>
                                <span
                                  style={{
                                    color: "var(--primary-color)",
                                    fontSize: "13px",
                                  }}
                                >
                                  {data?.ArrTo}
                                </span>
                                <br />

                                <Tooltip title={`${data?.ArrAirport}`}>
                                  <Typography
                                    style={{
                                      color: "var(--white)",
                                      fontSize: "13px",
                                    }}
                                    noWrap
                                  >
                                    {data?.ArrAirport}
                                  </Typography>
                                </Tooltip>

                                <span
                                  style={{
                                    color: "var(--fontcolor)",
                                    fontSize: "12px",
                                  }}
                                >
                                  {format(
                                    new Date(data?.ArrTime?.toString()),
                                    "dd MMM yyyy hh:mm a"
                                  )}
                                </span>
                              </Typography>
                            </Grid>
                          </Grid>
                          <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="space-between"
                          >
                            <Typography
                              sx={{
                                color: "var(--white)",
                                fontSize: 10,
                                textAlign: "left",
                              }}
                            >
                              Model: {data?.AircraftModel}
                            </Typography>
                            <Typography
                              sx={{
                                color: "var(--white)",
                                fontSize: 10,
                                textAlign: "left",
                              }}
                            >
                              operated by{": "}
                              {data?.OperatingCarrierName}
                            </Typography>{" "}
                          </Stack>
                        </Box>
                      ))}
                    </Box>
                  </Box>

                  {/* -------new end */}
                </Box>
              </Box>
            </Box>
            <Box>
              <FlightPassengerInfo
                offerId={OfferId?.offerId}
                loadData={loadData}
                flightData={flightData}
                tripType={tripType}
                adultCount={adultCount}
                childCount={childCount}
                infant={infant}
                fromAddress={fromAddress}
                toAddress={toAddress}
                from={from}
                to={to}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3.5}>
            <Box>
              <Typography color="#fff">Price Breacdown</Typography>
              <FlightInfoDetails
                loadData={loadData}
                flightData={flightData}
                tripType={tripType}
                adultCount={adultCount}
                childCount={childCount}
                infant={infant}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default FlightInformation;
