import {
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Calendar } from "react-date-range";
import { format } from "date-fns";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import secureLocalStorage from "react-secure-storage";
import CountryList from "../../components/CountryList";

import bookingSuccess from "../../images/undraw/undraw_travel_booking_re_6umu.svg";
import noFareFound from "../../images/undraw/undraw_not_found_re_bh2e.svg";
import serverError from "../../images/undraw/undraw_server_down_s-4-lk.svg";
import BookingFailed from "../../images/undraw/undraw_cancel_re_pkdm.svg";

const FlightPassengerInfo = ({
  loadData,
  flightData,
  tripType,
  adultCount,
  childCount,
  infant,
  from,
  to,
  fromAddress,
  toAddress,
  offerId,
}) => {
  const userData = flightData?.Passengers;
  console.log("loaddata", loadData);
  console.log("flightData", flightData);
  console.log("userData", userData);
  let passengersData = [];
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  userData.map((data) => passengersData.push({ id: data.id, type: data.type }));

  console.log(passengersData);
  //todo: user information
  const users = secureLocalStorage.getItem("user-info");
  const customerId = users?.data[0]?.customerId;
  //todo: end of user information
  const [userPhoneNumber, setUserPhoneNumber] = useState(
    "+8801685370455" || "+975"
  );
  const [email, setEmail] = useState("kayesthechill@gmail.com" || "");

  // todo: date validation
  function addMonths(date, months) {
    date.setMonth(date.getMonth() + months);
    return date;
  }
  let dateAfterSixMonths = addMonths(
    new Date(flightData.Segments[0].DepTime),
    6
  );
  let dateBeforeTwelveYears = addMonths(
    new Date(flightData.Segments[0].DepTime),
    -144
  );
  let dateBeforeTwoYears = addMonths(
    new Date(flightData.Segments[0].DepTime),
    -24
  );
  // todo:end

  const [focused, setFocused] = useState(false);
  const handleFocus = (e) => {
    setFocused(true);
  };

  const [flightPassengerData, setFlightPassengerData] = useState({
    adult: [...new Array(adultCount)].map((item, index) => {
      return {
        type: "ADT",
        given_name: "",
        family_name: "",
        title: "",
        gender: "",
        born_on: format(new Date(), "dd MMM yyyy"),
        openDate: false,
        openPassExDate: false,
        id:
          passengersData?.filter((item) => item?.type === "adult")[index]?.id ||
          "",
        infant_passenger_id:
          passengersData?.filter(
            (item) => item?.type === "infant_without_seat"
          )[index]?.id || "",
        email: email,
        phone_number: userPhoneNumber,
        // apassNation: "BD",
        // apassNo: "",
        // apassEx: format(new Date(dateAfterSixMonths), "dd MMM yyyy"),
        // new Date(dateAfterSixMonths).toLocaleDateString("sv"),
        // format(new Date(dateAfterSixMonths), "dd MMM yyyy"),
      };
    }),
    child: [...new Array(childCount)].map((item, index) => {
      return {
        type: "CNN",
        given_name: "",
        family_name: "",
        title: "",
        gender: "",
        born_on: format(new Date(), "dd MMM yyyy"),
        openDate: false,
        openPassExDate: false,
        id:
          passengersData?.filter((item) => item?.type === "child")[index]?.id ||
          "",
        email: email,
        phone_number: userPhoneNumber,
        // cpassNation: "BD",
        // cpassEx: format(new Date(dateAfterSixMonths), "dd MMM yyyy"),
        // cpassNo: "",
      };
    }),
    infant: [...new Array(infant)].map((item, index) => {
      return {
        type: "INF",
        given_name: "",
        family_name: "",
        gender: "",
        born_on: format(new Date(), "dd MMM yyyy"),
        openDate: false,
        openPassExDate: false,
        id:
          passengersData?.filter(
            (item) => item?.type === "infant_without_seat"
          )[index]?.id || "",
        email: email,
        phone_number: userPhoneNumber,
        // ipassNation: "BD",
        // ipassNo: "",
        // ipassEx: new Date(dateAfterSixMonths).toLocaleDateString("sv"),
      };
    }),
    adultCount: adultCount,
    childCount: childCount,
    infantCount: infant,
    email: email,
    phone_number: userPhoneNumber,
    customerId: customerId,
    offerId: offerId,
  });

  const handleOnChange = (e, type, index) => {
    if (type === "ADT") {
      const value = e.target.value;
      const field = e.target.name;
      //copying data to temp variable so that we do not directly mutate original state
      const tempFlightData = [...flightPassengerData.adult];
      // -1 check to see if we found that object in working hours
      if (index !== -1) {
        tempFlightData[index] = {
          ...tempFlightData[index], //keeping existing values in object
          [field]: value, //here property can be "price" or "description"
        };
      }
      setFlightPassengerData({
        ...flightPassengerData,
        adult: tempFlightData,
      });
    }
    if (type === "CNN") {
      const value = e.target.value;
      const field = e.target.name;
      //copying data to temp variable so that we do not directly mutate original state
      const tempFlightData = [...flightPassengerData.child];
      // -1 check to see if we found that object in working hours
      if (index !== -1) {
        tempFlightData[index] = {
          ...tempFlightData[index], //keeping existing values in object
          [field]: value, //here property can be "price" or "description"
        };
      }
      setFlightPassengerData({
        ...flightPassengerData,
        child: tempFlightData,
      });
    }
    if (type === "INF") {
      const value = e.target.value;
      const field = e.target.name;
      //copying data to temp variable so that we do not directly mutate original state
      const tempFlightData = [...flightPassengerData.infant];
      // -1 check to see if we found that object in working hours
      if (index !== -1) {
        tempFlightData[index] = {
          ...tempFlightData[index], //keeping existing values in object
          [field]: value, //here property can be "price" or "description"
        };
      }
      setFlightPassengerData({
        ...flightPassengerData,
        infant: tempFlightData,
      });
    }
    e.preventDefault();
  };

  // Change the user s email.
  const handleEmailChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const newPassengerData = { ...flightPassengerData };
    newPassengerData[field] = value;
    setFlightPassengerData(newPassengerData);
    setEmail(e.target.value);
  };

  const handleOpenDateState = (type, index, item) => {
    if (type === "ADT") {
      const tempFlightData = [...flightPassengerData.adult];
      tempFlightData[index] = {
        ...tempFlightData[index],
        openDate: !item.openDate,
        openPassExDate: false,
      };
      setFlightPassengerData({
        ...flightPassengerData,
        adult: tempFlightData,
      });
    } else if (type === "CNN") {
      const tempFlightData = [...flightPassengerData.child];
      tempFlightData[index] = {
        ...tempFlightData[index],
        openDate: !item.openDate,
        openPassExDate: false,
      };
      setFlightPassengerData({
        ...flightPassengerData,
        child: tempFlightData,
      });
    } else {
      const tempFlightData = [...flightPassengerData.infant];
      tempFlightData[index] = {
        ...tempFlightData[index],
        openDate: !item.openDate,
        openPassExDate: false,
      };
      setFlightPassengerData({
        ...flightPassengerData,
        infant: tempFlightData,
      });
    }
  };
  const handleOpenPassDateState = (type, index, item) => {
    if (type === "ADT") {
      const tempFlightData = [...flightPassengerData.adult];
      tempFlightData[index] = {
        ...tempFlightData[index],
        openDate: false,
        openPassExDate: !item.openPassExDate,
      };
      setFlightPassengerData({
        ...flightPassengerData,
        adult: tempFlightData,
      });
    } else if (type === "CNN") {
      const tempFlightData = [...flightPassengerData.child];
      tempFlightData[index] = {
        ...tempFlightData[index],
        openDate: false,
        openPassExDate: !item.openPassExDate,
      };
      setFlightPassengerData({
        ...flightPassengerData,
        child: tempFlightData,
      });
    } else {
      const tempFlightData = [...flightPassengerData.infant];
      tempFlightData[index] = {
        ...tempFlightData[index],
        openDate: false,
        openPassExDate: !item.openPassExDate,
      };
      setFlightPassengerData({
        ...flightPassengerData,
        infant: tempFlightData,
      });
    }
  };

  //   let updateFlightPassenger: { ...flightPassengerData };
  let Passenger = [
    ...flightPassengerData?.adult,
    ...flightPassengerData?.child,
    ...flightPassengerData?.infant,
  ];
  let body = {
    Passenger,
    adultCount: adultCount,
    childCount: childCount,
    infantCount: infant,
    email: email,
    phone_number: userPhoneNumber,
    customerId: customerId,
    offerId: offerId,
    depfrom: from,
    arrto: to,
    triptype: tripType,
  };

  // let body = { updateFlightPassenger: { ...flightPassengerData } };

  console.log(flightPassengerData);
  console.log(body);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoaded(false);
    e.target.reset();

    let url = "https://api.gallpax.com/v1/Duffel/AirBooking.php";
    await fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === "success") {
          Swal.fire({
            imageUrl: bookingSuccess,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
            title: "Success",
            html: "<strong>Thank you for booking with Travel Sense.Travel Please make the payment within 10 minutes, otherwise, your booking request will be automatically canceled.",
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Ok",
          }).then(function () {
            setIsLoaded(true);
            navigate("/user/queudetails", {
              state: {
                bookingData: data,
                sabreOneway: body,
                OrderId: data?.response?.data?.id,
              },
            });
          });
        } else {
          Swal.fire({
            imageUrl: noFareFound,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
            title: "No Fare Available",
            html: `For any query.Please contact us at <strong>kayesthechill@gmail.com</strong> or Call <strong>+8801685370455 </strong>`,
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Please Try Another Flights.",
          }).then(function () {
            setIsLoaded(true);
            navigate(-1);
          });
        }
      })

      .catch((err) => {
        console.log(err.message);
        Swal.fire({
          imageUrl: noFareFound,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: "No Fare Available",
          html: `For any query.Please contact us at <strong>kayesthechill@gmail.com</strong> or Call <strong>+8801685370455</strong>`,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Please Try Another Flights.",
        }).then(function () {
          setIsLoaded(true);
          navigate(-1);
        });
      });
  };

  return (
    <div className="passenger-label">
      <form onSubmit={handleSubmit}>
        {flightPassengerData.adult.map((item, index) => {
          return (
            <Box key={index}>
              <Box className="adult-info">
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} sm={12}>
                    <Box mt={2}>
                      <Typography
                        sx={{
                          p: "5px 40px",
                          bgcolor: "var(--btn-bg)",
                          borderRadius: "20px",
                          width: "100%",
                          textAlign: "center",
                          color: "var(--white)",
                        }}
                      >
                        Adult - {index + 1}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ padding: "26px 0px" }}>
                  {/*//todo: auto fil travelers */}

                  <Grid item xs={12} md={6} lg={4}>
                    <label htmlFor="gender">Select Title</label>
                    <select
                      className="user-info-select"
                      required
                      name="title"
                      id="title"
                      autoFocus="true"
                      value={item.title}
                      onChange={(e) => handleOnChange(e, item.type, index)}
                    >
                      <option value="">Title</option>
                      <option value="mr">Mr</option>
                      <option value="mrs">MRS</option>
                      <option value="ms">MS</option>
                      <option value="miss">MISS</option>
                      <option value="dr">DR</option>
                    </select>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <label htmlFor="given_name">Given Name / First Name</label>
                    <input
                      required
                      onBlur={handleFocus}
                      focused={focused.toString()}
                      onChange={(e) => handleOnChange(e, item.type, index)}
                      type="text"
                      name="given_name"
                      id="given_name"
                      value={item.given_name}
                      placeholder="Given Name / First Name"
                      pattern="[a-zA-Z\s]+"
                      style={{ textTransform: "uppercase" }}
                    />
                    <span
                      className="form-validation-span"
                      style={{
                        color: "red",
                        fontSize: "14px",
                      }}
                    >
                      *No Special Character
                    </span>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <label htmlFor="family_name">Surname / Last Name</label>
                    <input
                      required
                      focused={focused.toString()}
                      onBlur={handleFocus}
                      onChange={(e) => handleOnChange(e, item.type, index)}
                      type="text"
                      name="family_name"
                      id="family_name"
                      pattern="[a-zA-Z\s]+"
                      value={item.family_name}
                      placeholder="Surname / Last Name"
                      style={{ textTransform: "uppercase" }}
                    />
                    <span
                      className="form-validation-span"
                      style={{
                        color: "red",
                        fontSize: "14px",
                      }}
                    >
                      *No Special Character
                    </span>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <label htmlFor="gender">Select Gender</label>
                    <select
                      className="user-info-select"
                      required
                      name="gender"
                      id="gender"
                      autoFocus="true"
                      value={item.gender}
                      onChange={(e) => handleOnChange(e, item.type, index)}
                    >
                      <option value="">Gender</option>
                      <option value="m">Male</option>
                      <option value="f">Female</option>
                    </select>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={4}
                    style={{ position: "relative" }}
                  >
                    <label htmlFor="born_on">Date of Birth</label>

                    <input
                      required
                      type="text"
                      name="born_on"
                      id="born_on"
                      value={format(new Date(item.born_on), "dd MMM yyyy")}
                      onClick={() =>
                        handleOpenDateState(item.type, index, item)
                      }
                    />
                    {item.openDate && (
                      <Calendar
                        color={"#003566"}
                        // date={new Date(item.born_on)}
                        onChange={(date) => {
                          const tempFlightData = [...flightPassengerData.adult];
                          tempFlightData[index] = {
                            ...tempFlightData[index],
                            born_on: new Date(date).toLocaleDateString("sv"),
                            openDate: false,
                          };
                          setFlightPassengerData({
                            ...flightPassengerData,
                            adult: tempFlightData,
                          });
                        }}
                        months={1}
                        maxDate={new Date(dateBeforeTwelveYears)}
                        className="user-info-calendar"
                      />
                    )}
                    <span
                      style={{
                        color: "red",
                        fontSize: "14px",
                      }}
                    >
                      *Age Should be 12+
                    </span>
                  </Grid>

                  {/* <Grid item xs={12} md={6} lg={4}>
                    <label htmlFor="apassNation">Select Nationality</label>
                    <select
                      className="user-info-select"
                      required
                      name="apassNation"
                      id="apassNation"
                      selected={item.apassNation}
                      onChange={(e) => handleOnChange(e, item.type, index)}
                      value={item.apassNation}
                    >
                      <option value="">Select Nationality</option>
                      {CountryList.map((country) => {
                        return (
                          <option value={country.code}>{country.name}</option>
                        );
                      })}
                    </select>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <label htmlFor="apassNo">Passport Number</label>
                    <input
                      required
                      focused={focused.toString()}
                      onBlur={handleFocus}
                      type="text"
                      name="apassNo"
                      id="apassNo"
                      placeholder="xx-xxxxxxx"
                      pattern="^[a-zA-Z0-9]*$"
                      value={item.apassNo}
                      onChange={(e) => handleOnChange(e, item.type, index)}
                      style={{ textTransform: "uppercase" }}
                    />
                    <span
                      className="form-validation-span"
                      style={{
                        color: "red",
                        fontSize: "14px",
                      }}
                    >
                      *Only Uppercase and number
                    </span>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={4}
                    sx={{ position: "relative" }}
                  >
                    <label htmlFor="apassEx">Passport Expire Date</label>

                    <input
                      required
                      type="text"
                      name="apassEx"
                      id="apassEx"
                      value={format(new Date(item.apassEx), "dd MMM yyyy")}
                      onClick={() =>
                        handleOpenPassDateState(item.type, index, item)
                      }
                    />
                    {item.openPassExDate && (
                      <Calendar
                        color={"#003566"}
                        onChange={(date) => {
                          const tempFlightData = [...flightPassengerData.adult];
                          tempFlightData[index] = {
                            ...tempFlightData[index],
                            apassEx: new Date(date).toLocaleDateString("sv"),
                            openPassExDate: false,
                          };
                          setFlightPassengerData({
                            ...flightPassengerData,
                            adult: tempFlightData,
                          });
                        }}
                        months={1}
                        minDate={new Date()}
                        className="user-info-calendar"
                      />
                    )}
                  </Grid> */}
                </Grid>
              </Box>
            </Box>
          );
        })}
        {/*  Adult Details end  */}

        {/* Child details */}

        {flightPassengerData.child.map((item, index) => (
          <Box>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={12}>
                <Box mt={2}>
                  <Typography
                    sx={{
                      p: "5px 40px",
                      bgcolor: "var(--btn-bg)",
                      borderRadius: "20px",
                      width: "100%",
                      textAlign: "center",
                      color: "var(--white)",
                    }}
                  >
                    Child - {index + 1}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Box className="adult-info">
              <Grid container spacing={2} sx={{ padding: "26px 0px" }}>
                {/*//todo: auto fil travelers */}

                <Grid item xs={12} md={6} lg={4}>
                  <label htmlFor="gender">Select Title</label>
                  <select
                    className="user-info-select"
                    required
                    name="title"
                    id="title"
                    autoFocus="true"
                    value={item.title}
                    onChange={(e) => handleOnChange(e, item.type, index)}
                  >
                    <option value="">Title</option>
                    <option value="mr">Mr</option>
                    <option value="mrs">MRS</option>
                    <option value="ms">MS</option>
                    <option value="miss">MISS</option>
                  </select>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <label htmlFor="given_name">Given Name / First Name</label>
                  <input
                    required
                    focused={focused.toString()}
                    onBlur={handleFocus}
                    type="text"
                    name="given_name"
                    id="given_name"
                    value={item.given_name}
                    placeholder="Given Name / First Name"
                    pattern="[a-zA-Z\s]+"
                    onChange={(e) => handleOnChange(e, item.type, index)}
                    style={{ textTransform: "uppercase" }}
                  />
                  <span
                    className="form-validation-span"
                    style={{
                      color: "red",
                      fontSize: "14px",
                    }}
                  >
                    *No Special Character
                  </span>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <label htmlFor="family_name">Surname / Last Name</label>
                  <input
                    required
                    focused={focused.toString()}
                    onBlur={handleFocus}
                    type="text"
                    name="family_name"
                    id="family_name"
                    pattern="[a-zA-Z\s]+"
                    value={item.family_name}
                    placeholder="Surname / Last Name"
                    onChange={(e) => handleOnChange(e, item.type, index)}
                    style={{ textTransform: "uppercase" }}
                  />
                  <span
                    className="form-validation-span"
                    style={{
                      color: "red",
                      fontSize: "14px",
                    }}
                  >
                    *No Special Character
                  </span>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <label htmlFor="gender">Select Gender</label>
                  <select
                    className="user-info-select"
                    required
                    onBlur={handleFocus}
                    onChange={(e) => handleOnChange(e, item.type, index)}
                    name="gender"
                    id="gender"
                    value={item.gender}
                  >
                    <option value="">Gender</option>
                    <option value="m">Male</option>
                    <option value="f">Female</option>
                  </select>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  style={{ position: "relative" }}
                >
                  <label htmlFor="born_on">Date of Birth</label>

                  <input
                    required
                    type="text"
                    id="born_on"
                    value={format(new Date(item.born_on), "dd MMM yyyy")}
                    onClick={() => handleOpenDateState(item.type, index, item)}
                  />
                  {item.openDate && (
                    <Calendar
                      color={"#003566"}
                      // date={new Date(item.born_on)}
                      onChange={(date) => {
                        const tempFlightData = [...flightPassengerData.child];
                        tempFlightData[index] = {
                          ...tempFlightData[index],
                          born_on: new Date(date).toLocaleDateString("sv"),
                          openDate: false,
                        };
                        setFlightPassengerData({
                          ...flightPassengerData,
                          child: tempFlightData,
                        });
                      }}
                      months={1}
                      minDate={new Date(dateBeforeTwelveYears)}
                      maxDate={new Date(dateBeforeTwoYears)}
                      className="user-info-calendar"
                    />
                  )}

                  <span
                    style={{
                      color: "red",
                      fontSize: "14px",
                    }}
                  >
                    *Age must be 2 to 12 years
                  </span>
                </Grid>
                {/* <Grid item xs={12} md={6} lg={4}>
                  <label htmlFor="cpassNation">Select Nationality</label>
                  <select
                    className="user-info-select"
                    required
                    name="cpassNation"
                    id="cpassNation"
                    onChange={(e) => handleOnChange(e, item.type, index)}
                    value={item.cpassNation}
                  >
                    <option value="">Select Nationality</option>

                    {CountryList.map((country) => {
                      return (
                        <option value={country.code}>{country.name}</option>
                      );
                    })}
                  </select>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <label htmlFor="cpassNo">Passport Number</label>
                  <input
                    required
                    onBlur={handleFocus}
                    focused={focused.toString()}
                    onChange={(e) => handleOnChange(e, item.type, index)}
                    type="text"
                    name="cpassNo"
                    id="cpassNo"
                    pattern="^[a-zA-Z0-9]*$"
                    placeholder="xx-xxxxxxx"
                    value={item.cpassNo}
                    style={{ textTransform: "uppercase" }}
                  />
                  <span
                    className="form-validation-span"
                    style={{ color: "red", fontSize: "14px" }}
                  >
                    *Only Uppercase and number
                  </span>
                </Grid>
                <Grid item xs={12} md={6} lg={4} sx={{ position: "relative" }}>
                  <label htmlFor="cpassEx">Passport Expire Date</label>

                  <input
                    required
                    type="text"
                    name="cpassEx"
                    id="cpassEx"
                    value={format(new Date(item.cpassEx), "dd MMM yyyy")}
                    onClick={() =>
                      handleOpenPassDateState(item.type, index, item)
                    }
                  />
                  {item.openPassExDate && (
                    <Calendar
                      color={"#003566"}
                      onChange={(date) => {
                        const tempFlightData = [...flightPassengerData.child];
                        tempFlightData[index] = {
                          ...tempFlightData[index],
                          cpassEx: new Date(date).toLocaleDateString("sv"),
                          openPassExDate: false,
                        };
                        setFlightPassengerData({
                          ...flightPassengerData,
                          child: tempFlightData,
                        });
                      }}
                      months={1}
                      className="user-info-calendar"
                      minDate={new Date()}
                    />
                  )}
                </Grid> */}
              </Grid>
            </Box>
          </Box>
        ))}
        {/* Child details end*/}

        {/* infant details start  */}
        {flightPassengerData.infant.map((item, index) => (
          <Box>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={12}>
                <Box mt={2}>
                  <Typography
                    sx={{
                      p: "5px 40px",
                      bgcolor: "var(--btn-bg)",
                      borderRadius: "20px",
                      width: "100%",
                      textAlign: "center",
                      color: "var(--white)",
                    }}
                  >
                    Infant - {index + 1}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Box className="adult-info">
              <Grid container spacing={2} sx={{ padding: "26px 0px" }}>
                {/*//todo: auto fil travelers */}

                <Grid item xs={12} md={6} lg={4}>
                  <label htmlFor="gender">Select Title</label>
                  <select
                    className="user-info-select"
                    required
                    name="title"
                    id="title"
                    autoFocus="true"
                    value={item.title}
                    onChange={(e) => handleOnChange(e, item.type, index)}
                  >
                    <option value="">Title</option>
                    <option value="mr">Mr</option>
                    <option value="mrs">MRS</option>
                    <option value="ms">MS</option>
                    <option value="miss">MISS</option>
                  </select>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <label htmlFor="given_name">Given Name / First Name</label>
                  <input
                    required
                    focused={focused.toString()}
                    onBlur={handleFocus}
                    type="text"
                    name="given_name"
                    id="given_name"
                    value={item.given_name}
                    placeholder="Given Name / First Name"
                    pattern="[a-zA-Z\s]+"
                    onChange={(e) => handleOnChange(e, item.type, index)}
                    style={{ textTransform: "uppercase" }}
                  />
                  <span
                    className="form-validation-span"
                    style={{ color: "red", fontSize: "14px" }}
                  >
                    *No Special Character
                  </span>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <label htmlFor="family_name">Surname / Last Name</label>
                  <input
                    required
                    focused={focused.toString()}
                    onBlur={handleFocus}
                    type="text"
                    name="family_name"
                    id="family_name"
                    pattern="[a-zA-Z\s]+"
                    value={item.family_name}
                    placeholder="Surname / Last Name"
                    onChange={(e) => handleOnChange(e, item.type, index)}
                    style={{ textTransform: "uppercase" }}
                  />
                  <span
                    className="form-validation-span"
                    style={{ color: "red", fontSize: "14px" }}
                  >
                    *No Special Character
                  </span>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <label htmlFor="gender">Select Gender</label>
                  <select
                    className="user-info-select"
                    required
                    name="gender"
                    id="gender"
                    onChange={(e) => handleOnChange(e, item.type, index)}
                    value={item.gender}
                  >
                    <option value="">Gender</option>
                    <option value="m">Male</option>
                    <option value="f">Female</option>
                  </select>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  style={{ position: "relative" }}
                >
                  <label htmlFor="born_on">Date of Birth</label>

                  <input
                    required
                    type="text"
                    id="born_on"
                    value={format(new Date(item.born_on), "dd MMM yyyy")}
                    onClick={() => handleOpenDateState(item.type, index, item)}
                  />
                  {item.openDate && (
                    <Calendar
                      color={"#003566"}
                      onChange={(date) => {
                        const tempFlightData = [...flightPassengerData.infant];
                        tempFlightData[index] = {
                          ...tempFlightData[index],
                          born_on: new Date(date).toLocaleDateString("sv"),
                          openDate: false,
                        };
                        setFlightPassengerData({
                          ...flightPassengerData,
                          infant: tempFlightData,
                        });
                      }}
                      months={1}
                      minDate={new Date(dateBeforeTwoYears)}
                      maxDate={new Date()}
                      className="user-info-calendar"
                    />
                  )}
                  <span style={{ color: "red", fontSize: "14px" }}>
                    *Age should be less then 2 years
                  </span>
                </Grid>

                {/* <Grid item xs={12} md={6} lg={4}>
                  <label htmlFor="ipassNation">Select Nationality</label>
                  <select
                    className="user-info-select"
                    required
                    name="ipassNation"
                    id="ipassNation"
                    value={item.ipassNation}
                    onChange={(e) => handleOnChange(e, item.type, index)}
                  >
                    <option value="">Select Nationality</option>
                    {CountryList.map((country) => {
                      return (
                        <option value={country.code}>{country.name}</option>
                      );
                    })}
                  </select>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <label htmlFor="ipassNo">Passport Number</label>
                  <input
                    required
                    focused={focused.toString()}
                    onBlur={handleFocus}
                    type="text"
                    name="ipassNo"
                    id="ipassNo"
                    pattern="^[a-zA-Z0-9]*$"
                    placeholder="xx-xxxxxxx"
                    value={item.ipassNo}
                    onChange={(e) => handleOnChange(e, item.type, index)}
                    style={{ textTransform: "uppercase" }}
                  />
                  <span
                    className="form-validation-span"
                    style={{ color: "red", fontSize: "14px" }}
                  >
                    *Only Uppercase and number
                  </span>
                </Grid>
                <Grid item xs={12} md={6} lg={4} sx={{ position: "relative" }}>
                  <label htmlFor="ipassEx">Passport Expire Date</label>

                  <input
                    required
                    type="text"
                    id="ipassEx"
                    value={format(new Date(item.ipassEx), "dd MMM yyyy")}
                    onClick={() =>
                      handleOpenPassDateState(item.type, index, item)
                    }
                  />
                  {item.openPassExDate && (
                    <Calendar
                      color={"#003566"}
                      onChange={(date) => {
                        const tempFlightData = [...flightPassengerData.infant];
                        tempFlightData[index] = {
                          ...tempFlightData[index],
                          ipassEx: new Date(date).toLocaleDateString("sv"),
                          openPassExDate: false,
                        };
                        setFlightPassengerData({
                          ...flightPassengerData,
                          infant: tempFlightData,
                        });
                      }}
                      months={1}
                      className="user-info-calendar"
                      minDate={new Date()}
                    />
                  )}
                </Grid> */}
              </Grid>
            </Box>
          </Box>
        ))}
        {/* infant details end  */}

        <Box mt={3}>
          <Typography color="#fff">
            Contact Details (Airlines will send updates to this contact)
          </Typography>
          <Box className="adult-info" sx={{ mt: 2 }}>
            <Grid container spacing={5}>
              <Grid item xs={12} md={6} lg={4}>
                <label htmlFor="passengerEmail">Your Email</label>
                <input
                  required
                  focused={focused.toString()}
                  onBlur={handleFocus}
                  type="email"
                  name="passengerEmail"
                  id="passengerEmail"
                  value={email}
                  placeholder="example@example.com"
                  onChange={(e) => {
                    setFlightPassengerData({
                      ...flightPassengerData,
                      email: e.target.value,
                    });
                    setEmail(e.target.value);
                  }}
                  // onChange={(e) => setEmail(e.target.value)}
                />
                <span
                  className="form-validation-span"
                  style={{ color: "red", fontSize: "14px" }}
                >
                  *Enter a valid email
                </span>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <label htmlFor="contactpersonphonenumber">Phone Number</label>
                <PhoneInput
                  required
                  country={"btn"}
                  name="contactpersonphonenumber"
                  id="contactpersonphonenumber"
                  value={userPhoneNumber}
                  onChange={(phone) => {
                    setFlightPassengerData({
                      ...flightPassengerData,
                      phone_number: phone,
                    });
                    setUserPhoneNumber(phone);
                  }}
                  // onChange={(e) => setUserPhoneNumber(e.target.value)}
                  style={{
                    width: "100%",
                  }}
                />
                <span
                  className="form-validation-span"
                  style={{ color: "red", fontSize: "14px" }}
                >
                  *Enter a valid phone number
                </span>
              </Grid>{" "}
              <Grid item xs={12} md={12} lg={12}>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="By booking this ticket i agree to Travel Sense terms & conditions"
                />
              </Grid>
            </Grid>
          </Box>
          <Box
            mb={5}
            className="booking-btn"
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button
              type="submit"
              style={{
                backgroundColor: "var(--primary-color)",
                color: "#fff",
                fontSize: "14px",
                height: "40px",
              }}
            >
              Book & Hold
            </button>
          </Box>
        </Box>
      </form>
    </div>
  );
};

export default FlightPassengerInfo;
