import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import AuthProvider from "./components/Contexts/AuthProvider";
import BookingRoute from "./components/Admin/BookingManagement/BookingRoute";

import Deposite from "./pages/Deposite/Deposite";
import AddDeposite from "./components/Deposit/AddDeposite";
import BookingDetails from "./components/Admin/BookingDetails/BookingDetails";
import SearchResult from "./pages/SearchReslut/SearchResult";
import FlightInformation from "./pages/FligthInformation/FlightInformation";
import RoundSearchResult from "./pages/SearchReslut/RoundSearchResult";
import RoundFlightUserInfo from "./components/FlightUserinfo/RoundFlightUserInfo";
import Customer from "./pages/Customer/Customer";
import PaymentManagement from "./pages/PaymentManagement/PaymentManagement";
import SignUp from "./components/SignUp/SignUp";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import MultiCitySearchResult from "./components/MultiCitySearchResult/MultiCitySearchResult";
import Dashboard from "./components/Dashboard/Dashboard";
// import Congratulation from "./components/Congratulation/Congratulation";
import NotFound from "./components/NotFound/NotFound";
import AdminLogin from "./components/AdminLogin/AdminLogin";
import SignIn from "./components/SignIn/SignIn";
import AdminAddBank from "./components/BankAccount/AdminAddBank";
import AdminAccount from "./components/Account/AdminAccount";
import { Box } from "@mui/material";
import "./App.css";
import AdminDashboardSideBar from "./components/AdminDashboardSideBar/AdminDashboardSideBar";
import Markup from "./components/Markup/FlightMarkUp/Markup";
import MarkUpDetails from "./components/MarkUpDetails/FlightMarkUpDetails/MarkUpDetails";
import AdminTransectionReport from "./components/AdminReport/AdminTransectionReport";
import AdminLedgerReport from "./components/AdminReport/AdminLedgerReport";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProfileTabbar from "./components/Profile/ProfileTabbar/ProfileTabbar";
import MyBooking from "./components/Profile/MyBooking.js/MyBooking";
import Payment from "./components/Profile/Payment/Payment";
import ChangePassword from "./components/Profile/ChangePassword";
import MyProfile from "./components/Profile/MyProfile/MyProfile";
import UpdateProfile from "./components/Profile/MyProfile/UpdateProfile";
import Queuedetails from "./pages/FligthInformation/Queuedetails";
import Control from "./components/Admin/Control/Control";
import Ledger from "./components/Profile/Ledger/Ledger";
import AddDeposit from "./components/Profile/Payment/AddDeposit";
import BookingPDF from "./components/AllPDF/BookingPDF";
import AdminPrivateRoute from "./components/PrivateRoute/AdminPrivateRoute";
import ComingSoon from "./components/HomeSearchBox/ComingSoon";
import Qdetails from "./pages/Admin/Qdetails";

// import MyProfile from "./components/Profile/ProfileTabbar/MyProfile/MyProfile";
function App() {
  return (
    <Box>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/comingsoon" element={<ComingSoon />} />

            {/* Profile start hare */}
            {/* <PrivateRoute> */}
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <ProfileTabbar />
                </PrivateRoute>
              }
            >
              <Route path="/profile" element={<MyProfile />} />
              <Route path="/booking" element={<MyBooking />} />
              <Route path="/paymennt" element={<Payment />} />
              <Route path="/ledger" element={<Ledger />} />
              <Route path="/adddeposit" element={<AddDeposit />} />
              {/* <Route path="/change" element={<ChangePassword />} /> */}
              <Route path="/updateprofile" element={<UpdateProfile />} />
            </Route>
            {/* </PrivateRoute> */}
            {/* Profile end hear */}
            {/* user dashboard  start */}
            {/* <Route
              path="/user"
              element={
                <PrivateRoute>
                  <UserDashboardSideBar />
                </PrivateRoute>
              }
            > */}
            <Route path="/user/dashboard" element={<Dashboard />} />
            {/* After searchResult start  */}
            <Route path="/user/searchresult" element={<SearchResult />} />
            <Route
              path="/user/roundsearchresult"
              element={<RoundSearchResult />}
            />
            <Route
              path="/user/multicityaftersearch"
              element={<MultiCitySearchResult />}
            />
            {/* After searchResult end  */}
            {/* After Booking start */}
            <Route
              path="/user/flightinformation"
              element={
                <PrivateRoute>
                  <FlightInformation />
                </PrivateRoute>
              }
            />
            <Route
              path="/user/roundflightinformation"
              element={
                <PrivateRoute>
                  <RoundFlightUserInfo />
                </PrivateRoute>
              }
            />

            {/* <Route
              path="/user/congratulation"
              element={
                <Congratulation />
              }
            /> */}
            {/* After Booking enduser/queudetails */}

            {/* Home Search Box Route  */}
            {/* <Route path="/" element={<SearchBoxHeading />}>
              <Route path="/" element={<FlightSearchBox />} />
              <Route path="/hotels" element={<HotelSearchBox />} />
              <Route path="/tours" element={<TourSearchBox />} />
              <Route path="/umrhas" element={<UmrhaSearchBox />} />
              <Route path="/visas" element={<VisaSearchBox />} />
              <Route path="/mores" element={<MoreSearchBox />} />
            </Route> */}
            {/* Home Search Box Route  */}

            <Route path="user/test" element={<BookingPDF />} />
            <Route
              path="user/queudetails"
              element={
                <PrivateRoute>
                  <Queuedetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/user/bookingroute"
              element={
                <PrivateRoute>
                  <BookingRoute />
                </PrivateRoute>
              }
            />
            <Route
              path="/user/bookingdetails"
              element={
                <PrivateRoute>
                  <BookingDetails />
                </PrivateRoute>
              }
            />
            {/* <Route path="/user/traveller" element={<Traveller />} /> */}
            {/* <Route path="/user/addtraveller" element={<AddTraveller />} /> */}

            {/* <Route path="/user/mystaff" element={<MyStaff />} /> */}
            {/* <Route path="/user/others" element={<Others />} /> */}
            {/* <Route path="/user/account" element={<Account />} /> */}
            {/* <Route path="/user/bankaccount" element={<BankAccount />} /> */}
            {/* <Route path="/user/addbankaccount" element={<AddBank />} /> */}
            <Route path="/user/deposit" element={<Deposite />} />
            <Route path="/user/adddeposit" element={<AddDeposite />} />

            {/* <Route path="/user/transection" element={<TransectionReport />} />
            <Route path="/user/ledger" element={<LedgerReport />} /> */}
            {/* </Route> */}
            {/* user dashboard  end */}

            {/* admin Dashboard start for Travel Sense */}
            <Route path="/admin" element={<AdminLogin />} />
            <Route
              path="/admin"
              element={
                <AdminPrivateRoute>
                  <AdminDashboardSideBar />
                </AdminPrivateRoute>
              }
            >
              <Route path="/admin/dashboard" element={<Dashboard />} />
              <Route path="/admin/customer" element={<Customer />} />
              <Route path="/admin/bookingroute" element={<BookingRoute />} />
              <Route path="/admin/qdetails" element={<Qdetails />} />
              <Route
                path="/admin/paymentmanagement"
                element={<PaymentManagement />}
              />
              <Route
                path="/admin/transection"
                element={<AdminTransectionReport />}
              />

              {/* ----------------------------------------------------------- */}
              <Route path="/admin/control" element={<Control />} />
              {/* <Route path="/admin/controlpanel" element={<ControlPanel />} /> */}
              <Route path="/admin/markupdetails" element={<MarkUpDetails />} />
              <Route path="/admin/markup" element={<Markup />} />
              <Route path="/admin/ledger" element={<AdminLedgerReport />} />

              {/* <Route
                path="/admin/searchcount"
                element={<SearchCountParent />}
              /> */}

              <Route path="/admin/account" element={<AdminAccount />} />
              {/* <Route path="/admin/staffinfo" element={<AgentAccount />} /> */}
              {/* <Route path="/admin/addstaff" element={<AddStaff />} /> */}

              <Route path="/admin/addbank" element={<AdminAddBank />} />
            </Route>

            {/* user dashboard  hg*/}

            {/* <Route path="/user" element={<UserDashboardSideBar />}>
              {/* admin Dashboard start for Travel Sense */}
            {/* <Route path="/adminlogin" element={<AdminLogin />} />
            <Route path="/admin" element={<AdminDashboardSideBar />}>
              <Route path="/admin/dashboard" element={<Dashboard />} />
              <Route path="/admin/customer" element={<Customer />} />
              <Route path="/admin/bookingroute" element={<BookingRoute />} />
              <Route
                path="/admin/paymentmanagement"
                element={<PaymentManagement />}
              />
              <Route
                path="/admin/transection"
                element={<AdminTransectionReport />}
              />
              <Route path="/admin/control" element={<Control />} /> */}

            {/* ----------------------------------------------------------- */}

            {/* <Route path="/admin/controlpanel" element={<ControlPanel />} />
              <Route path="/admin/markupdetails" element={<MarkUpDetails />} />
              <Route path="/admin/markup" element={<Markup />} />
              <Route path="/admin/ledger" element={<AdminLedgerReport />} />

              <Route
                path="/admin/searchcount"
                element={<SearchCountParent />}
              />

              <Route path="/admin/account" element={<AdminAccount />} />
              <Route path="/admin/staffinfo" element={<AgentAccount />} />
              <Route path="/admin/addstaff" element={<AddStaff />} />

              <Route path="/admin/addbank" element={<AdminAddBank />} />
            </Route> */}

            {/* user dashboard  hg*/}

            {/* <Route path="/user" element={<UserDashboardSideBar />}>
              <Route path="/user/dashboard" element={<Dashboard />} />
              <Route path="/user/bookingroute" element={<BookingRoute />} />
              <Route path="/user/bookingdetails" element={<BookingDetails />} />
              <Route path="/user/traveller" element={<Traveller />} />
              <Route path="/user/mystaff" element={<MyStaff />} />
            </Route>

            <Route path="/test" element={<Test />} /> */}

            {/* //todo:home page */}

            {/* Quotes menu */}

            {/* <Route
              path="/account"
              element={
                <PrivateRoute>
                  <AdminAccount />
                </PrivateRoute>
              }
            />

            <Route
              path="/flightdetail"
              element={
                <PrivateRoute>
                  <FlightDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/mystaff"
              element={
                <PrivateRoute>
                  <MyStaff />
                </PrivateRoute>
              }
            />
            <Route
              path="/addstaff"
              element={
                <PrivateRoute>
                  <AddStaff />
                </PrivateRoute>
              }
            />

            <Route
              path="/bankaccount"
              element={
                <PrivateRoute>
                  <BankAccount />
                </PrivateRoute>
              }
            />
            <Route
              path="/addbankaccount"
              element={
                <PrivateRoute>
                  <AddBank />
                </PrivateRoute>
              }
            />

            <Route
              path="/congratulation"
              element={
                <PrivateRoute>
                  <Congratulation />
                </PrivateRoute>
              }
            /> */}
            {/* <Route
              path="/dashboard/queues/cancelqueues"
              element={<CancelQueues />}
            />
            <Route path="/dashboard/queues/others" element={<Others />} />
            <Route
              path="/dashboard/queues/queuesdetails"
              element={<QueuesDetail />}
            />

            <Route path="/dashboard/queues/onhold" element={<OnHold />} />
            <Route path="/dashboard/queues/pending" element={<Pending />} />
            <Route
              path="/dashboard/queues/InProcess"
              element={<InProcess />}
            />
            <Route
              path="/dashboard/queues/ticket"
              element={<Ticketed />}
            />
            <Route
              path="/dashboard/queues/expired"
              element={<Expired />}
            />
            <Route
              path="/dashboard/queues/cancelled"
              element={<Cancelled />}
            />
            <Route
              path="/dashboard/queues/unconfirmed"
              element={<Unconfirmed />}
            />
            <Route
              path="/dashboard/queues/RefundManagement"
              element={<RefundManagement />}
            />
            <Route
              path="/dashboard/queues/VoidManagement"
              element={<VoidManagement />}
            />
            <Route
              path="/dashboard/queues/ReissueManagement"
              element={<ReissueManagement />}
            /> */}

            {/* //todo:admin route */}

            {/* <Route
              path="/admin/dashboard"
              element={
                <PrivateRoute>
                  <Admin />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/agentManagement"
              element={<AgentManagement />}
            />
            <Route path="/admin/bookingManagement" element={<BookingRoute />} />
            <Route
              path="/admin/paymentManagement"
              element={<PaymentManagement />}
            /> */}
            {/*  //todo:search management section*/}
            {/* <Route
              path="/admin/searchManagement"
              element={<SearchCountParent />}
            />
            <Route
              path="/admin/generalledgerroute"
              element={<GeneralLedgerRoute />}
            /> */}
            <Route path="*" element={<NotFound />}></Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </Box>
  );
}

export default App;
