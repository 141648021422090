import {
  Button,
  Collapse,
  Grid,
  Stack,
  SwipeableDrawer,
  Tab,
  Tabs,
  // ToggleButton,
  // ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useState } from "react";
import seat1 from "../../images/Icon/bag.svg";
import bag from "../../images/Icon/seat.svg";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { useNavigate } from "react-router-dom";
import toimg from "../../images/Icon/to.svg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CircleIcon from "@mui/icons-material/Circle";
import FlightIcon from "@mui/icons-material/Flight";
import commaNumber from "comma-number";
import { format } from "date-fns";
import secureLocalStorage from "react-secure-storage";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import WorkIcon from "@mui/icons-material/Work";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import MuiToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";

import moment from "moment";
import Transit from "../SingleFlight/Transit";

const ToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: selectedColor,
  },
}));

const HtmlTooltip = styled(({ className, ...propss }) => (
  <Tooltip {...propss} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "var(--gray)",
    maxWidth: 220,
    fontSize: "5px",
    padding: "10px",
  },
}));
const SingleMultiCityItem = (props) => {
  const [value, setValue] = useState("1");
  const [flightDetails, setFlightDetails] = useState(false);
  const [allFlights, setAllFlight] = useState(true);
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [toggleBtn, setToggleBtn] = useState("depart");
  const handleChangeToggleBtn = (event, newValue) => {
    setToggleBtn(newValue);
  };

  const {
    backarrival,
    backarrivalDate,
    backarrivalTime,
    backdeparture,
    backdepartureDate,
    backdepartureTime,
    backflightduration,
    bags,
    career,
    careerName,
    goarrival,
    goarrivalDate,
    goarrivalTime,
    godeparture,
    godepartureDate,
    godepartureTime,
    goflightduration,
    refundable,
    price,
    Taxes,
    seat,
    segment,
    segments,
    stop,
    system,
    transit,
    bookingcode,
    BasePrice,
    pricebreakdown,
  } = props.flightData;
  const { from, to, fromAddress, toAddress } = props;
  const flightData = props.flightData;

  const {
    adultCount,
    childCount,
    infant,
    tripType,

    agentFarePrice,
    setAgentFarePrice,
    commisionFarePrice,
    setCommisionFarePrice,
    customerFare,
    setCustomerFare,
  } = props;

  //todo:CF AF CM variable are here
  const [toggledrawer, setToggledrawer] = useState(false);
  const [state, setState] = useState({
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };
  const commissionData = secureLocalStorage.getItem("commissionData");
  const clientPrice = Math.round(
    parseInt(props.flightData.clientPrice || props.flightData.baseprice)
  );
  const percentRate = parseInt(7) / 100;
  const clientFare = parseInt(clientPrice);
  const agentFare = Math.round(parseInt(price));
  const commission = Math.round(clientFare - agentFare);
  //todo:end of CM AF CM variables
  //todo: booking functional work here

  const FlightInformation = (id) => {
    console.log("id", id);
    const OfferId = secureLocalStorage.setItem("Offerid", {
      offerId: id,
      adultCount,
      childCount,
      infant,
      fromAddress,
      toAddress,
      tripType: "Multicity",
      from,
      to,
    });
    navigate("/user/flightinformation", {
      state: {
        flightData,
        adultCount,
        childCount,
        infant,
        tripType: "Multicity",
        fromAddress,
        toAddress,
        from,
        to,
      },
    });
  };

  const transitCalculation = (date1, date2) => {
    const duration = moment.duration(moment(date1).diff(moment(date2)));

    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) - hours * 60;
    const transit = `${Math.abs(hours)}h:${Math.abs(minutes)}min`;
    return transit;
  };
  const depTime = flightData?.Segments[0][0]?.DepTime;
  const arrTime =
    flightData?.Segments[0][flightData?.Segments[0].length - 1]?.ArrTime;
  const backdepTime = flightData?.Segments[1][0]?.DepTime;
  const backarrTime =
    flightData?.Segments[1][flightData?.Segments[1].length - 1]?.ArrTime;

  return (
    <Box
      mb={2}
      sx={{
        overflow: "hidden",
        boxShadow:
          "-0.452679px 4.97947px 36px rgba(0, 0, 0, 0.09), -0.0905357px 0.995893px 5.85px rgba(0, 0, 0, 0.045)",

        borderRadius: "10px",
        bgcolor: "var(--bgcolor)",
      }}
    >
      {/* //todo: Desktop Design Section */}
      <Grid container>
        <Grid
          item
          xs={12}
          sm={10}
          md={10}
          p={{ xs: 1, sm: 2, md: "15px 10px 15px 15px" }}
        >
          {/* Go Start  */}
          <Box>
            {flightData?.Segments?.map((data, i, arr) => (
              <Box my={1}>
                <Grid container columnSpacing={2}>
                  <Grid item xs={6} sm={2.5} md={2.5}>
                    <Box>
                      <Box className="career-image-logo">
                        <img
                          src={`https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/${data[0]?.MarkettingCarrier}.svg`}
                          alt={`${data[0]?.MarkettingCarrierName}`}
                          width="100%"
                          height="100%"
                        />
                      </Box>
                      <Tooltip title={`${data[0]?.MarkettingCarrierName}`}>
                        <Typography
                          fontSize={{ xs: "10px", md: "13px" }}
                          sx={{
                            width: "100%",
                            cursor: "pointer",
                            color: "var(--white)",
                          }}
                          noWrap
                        >
                          {`${data[0]?.MarkettingCarrierName}`}
                        </Typography>
                      </Tooltip>
                      <Typography
                        sx={{
                          color: "var(--primary-color )",
                          fontSize: 12,
                          textTransform: "capitalize",
                        }}
                      >
                        {flightData?.CabinClass}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    sm={3.5}
                    md={3.5}
                    textAlign={{ xs: "right", sm: "left" }}
                  >
                    <Box>
                      <Tooltip title={`${data[0]?.DepCountry?.split(" ,")[0]}`}>
                        <Typography
                          sx={{
                            color: "var(--primary-color)",
                            fontWeight: 500,
                            fontSize: {
                              xs: "12px",
                              sm: "13px",
                              md: "15px",
                              lg: "20px",
                            },
                          }}
                        >
                          {data[0]?.DepTime?.slice(11, 16)}
                          {" - "}
                          <span style={{ color: "var(--white)" }}>
                            {data[0]?.DepCountry?.split(" ,")[0]}
                          </span>
                        </Typography>
                      </Tooltip>

                      <Tooltip
                        title={`${data[0]?.DepAirport}${", "}${
                          data[0]?.DepFrom
                        }`}
                      >
                        <Typography
                          sx={{
                            width: "100%",
                            cursor: "pointer",
                            color: "var(--white)",
                            fontWeight: 400,
                            fontSize: {
                              xs: "12px",
                              sm: "12px",
                              md: "14px",
                            },
                          }}
                          noWrap
                        >
                          {data[0]?.DepAirport}
                          {", "}
                          {data[0]?.DepFrom}
                        </Typography>
                      </Tooltip>
                      <Typography
                        sx={{
                          color: "var(--white)",
                          fontWeight: 400,
                          fontSize: {
                            xs: "11px",
                            sm: "11px",
                            md: "13px",
                          },
                        }}
                      >
                        {new Date(data[0]?.DepTime).toLocaleString("en-uk", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: false,
                        })}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid xs={6} sm={2.5} item md={2.5} mt={{ xs: 1.5, sm: "0" }}>
                    <Box textAlign={"center"} pr={1}>
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            {data?.map((item, index, arr) => (
                              <Box
                                sx={{
                                  display: `${
                                    index === arr?.length - 1 ? "none" : "flex"
                                  }`,
                                }}
                              >
                                <Box
                                  borderBottom={
                                    index === arr?.length - 2
                                      ? "0"
                                      : "2px solid var(--secondary-color)"
                                  }
                                  px={1}
                                >
                                  <Typography
                                    sx={{
                                      color: "var(--secondary-color)",
                                      fontSize: "12px",
                                      textAlign: "center",
                                      fontWeight: 500,
                                    }}
                                  >
                                    Layover Time:{" "}
                                    {transitCalculation(
                                      data[index + 1]?.DepTime,
                                      data[index - 1 + 1]?.ArrTime
                                    )}
                                    <br />
                                    <span
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {data[index + 1]?.DepFrom}{" "}
                                    </span>
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: "var(--secondary-color)",
                                      fontSize: "11px",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {data[index + 1]?.DepCountry} <br />
                                    {data[index + 1]?.MarkettingCarrier}{" "}
                                    {data[index + 1]?.MarkettingCarrierNumber}
                                    <br />
                                    {new Date(
                                      data[index + 1]?.DepTime
                                    ).toLocaleString("en-uk", {
                                      day: "numeric",
                                      month: "short",
                                      year: "numeric",
                                      hour: "numeric",
                                      minute: "numeric",
                                      hour12: false,
                                    })}
                                  </Typography>
                                </Box>
                              </Box>
                            ))}
                          </React.Fragment>
                        }
                        followCursor
                      >
                        <Box>
                          <Typography
                            sx={{
                              color: "var(--gray)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "10px",
                                md: "12px",
                              },
                            }}
                          >
                            {/* {transitCalculation(
                              data[i]?.DepTime,
                              data[i - 1]?.ArrTime
                            )} */}
                          </Typography>
                          <Box className="stop-bar-parent">
                            <CircleIcon
                              sx={{
                                color: "var(--transit)",
                                fontSize: "14px",
                                zIndex: 2,
                              }}
                            />

                            {data?.map((d, k, carr) => (
                              <CircleIcon
                                sx={{
                                  display: `${
                                    k === carr?.length - 1 ? "none" : "block"
                                  }`,
                                  color: "var(--primary-color)",
                                  fontSize: "12px",
                                  zIndex: 2,
                                }}
                              />
                            ))}

                            <CircleIcon
                              sx={{
                                color: "var(--transit)",
                                fontSize: "14px",
                                zIndex: 2,
                              }}
                            />
                            <Box className="stop-bar-line"></Box>
                          </Box>
                          <Typography
                            sx={{
                              color: "var(--gray)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "10px",
                                md: "12px",
                              },
                            }}
                          >
                            {`${data?.length - 1} Stop`}
                          </Typography>
                        </Box>
                      </HtmlTooltip>
                    </Box>
                    {/* ------------ */}
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={3.5}
                    md={3.5}
                    textAlign={{ xs: "right", sm: "left" }}
                  >
                    <Box>
                      <Tooltip
                        title={`${
                          data[data?.length - 1]?.ArrCountry?.split(" ,")[0]
                        }`}
                      >
                        <Typography
                          sx={{
                            color: "var(--primary-color)",
                            fontWeight: 500,
                            fontSize: {
                              xs: "12px",
                              sm: "13px",
                              md: "15px",
                              lg: "20px",
                            },
                          }}
                          noWrap
                        >
                          {data?.[data?.length - 1]?.ArrTime?.split(
                            "T"
                          )[1]?.slice(0, 5)}
                          {" - "}
                          <span style={{ color: "var(--white)" }}>
                            {
                              data?.[data?.length - 1]?.ArrCountry?.split(
                                " ,"
                              )[0]
                            }
                          </span>
                        </Typography>
                      </Tooltip>

                      <Tooltip
                        title={`${data?.[data?.length - 1]?.ArrAirport}${", "}${
                          data?.[data?.length - 1]?.ArrTo
                        }`}
                      >
                        <Typography
                          sx={{
                            width: "100%",
                            cursor: "pointer",
                            color: "var(--white)",
                            fontWeight: 400,
                            fontSize: {
                              xs: "12px",
                              sm: "12px",
                              md: "14px",
                            },
                          }}
                          noWrap
                        >
                          {data?.[data?.length - 1]?.ArrAirport}
                          {", "}
                          {data?.[data?.length - 1]?.ArrTo}
                        </Typography>
                      </Tooltip>
                      <Typography
                        sx={{
                          color: "var(--white)",
                          fontWeight: 400,
                          fontSize: {
                            xs: "11px",
                            sm: "11px",
                            md: "13px",
                          },
                        }}
                      >
                        {new Date(
                          data?.[data?.length - 1]?.ArrTime
                        ).toLocaleString("en-uk", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: false,
                        })}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    display: `${i === arr?.length - 1 ? "none" : "block"}`,
                    width: "100%",
                    height: "0.5px",
                    bgcolor: "var(--primary-color)",
                    position: "relative",
                    my: 3,
                  }}
                >
                  <Typography
                    sx={{
                      bgcolor: "var(--white)",
                      position: "absolute",
                      width: "fit-content",
                      px: 2,
                      top: "50%",
                      left: "50%",
                      translate: "-50%  -50%",
                      borderRadius: 2,
                      fontSize: 12,
                      color: "gray",
                    }}
                  >
                    Multicity
                  </Typography>
                </Box>
              </Box>
            ))}
            <Typography
              sx={{
                color: "var(--white)",
                fontSize: 12,
                textAlign: { xs: "right", sm: "left" },
              }}
            >
              {flightData?.FareRules?.refund_before_departure?.allowed === true
                ? "Refundable"
                : "Non Refundable"}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          py={2}
          pr={2}
          borderLeft={{ xs: "none", sm: "2px dashed var(--gray)" }}
          textAlign={{ xs: "center", sm: "right" }}
        >
          <Box height="100%">
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box
                mr={{ xs: "10px", sm: "0px" }}
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "var(--primary-color)",
                    fontWeight: 500,
                    textAlign: "right",
                  }}
                >
                  {flightData?.Currency} {commaNumber(flightData?.TotalFare)}
                </Typography>
              </Box>

              <Box mt={{ sm: 1, md: 1 }}>
                <Stack
                  direction="row"
                  justifyContent={{ sm: "flex-end", xs: "space-between" }}
                  paddingLeft={{ xs: 2, sm: 0 }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "var(--primary-color)",
                      fontWeight: 500,
                      display: { xs: "block", sm: "none" },
                    }}
                  >
                    {flightData?.Currency} {commaNumber(flightData?.TotalFare)}
                  </Typography>{" "}
                  <Button
                    size="small"
                    sx={{
                      color: "var(--white)",
                      fontWeight: 500,
                      backgroundColor: "var(--primary-color)",
                      borderRadius: "5px",
                      px: 1.5,
                    }}
                    onClick={() => FlightInformation(flightData?.OfferId)}
                  >
                    Lets Fly
                  </Button>{" "}
                </Stack>
                {["right"].map((anchor) => (
                  <Box key={anchor}>
                    <Button
                      size="small"
                      onClick={toggleDrawer(anchor, true)}
                      style={{
                        color: "var(--white)",
                        fontWeight: 500,
                        fontSize: "11px",
                        padding: 0,
                        marginTop: "5px",
                      }}
                    >
                      Flight Details
                      <PlayArrowIcon
                        style={{ padding: "0", fontSize: "18px" }}
                      />
                    </Button>
                    {/* ----new start flight details */}
                    <SwipeableDrawer
                      style={{ margin: "0px", padding: "0px" }}
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                      onOpen={toggleDrawer(anchor, true)}
                    >
                      <Box
                        bgcolor="var(--secondary-color)"
                        sx={{ width: { xs: "85vw", sm: "70vw", md: "50vw" } }}
                      >
                        <Box>
                          <Box margin={{ xs: "15px", md: "2vw 0 2vw 3vw" }}>
                            <Typography
                              sx={{ fontSize: { xs: "12px", sm: "20px" } }}
                              color="#fff"
                              fontWeight={500}
                            >
                              {
                                flightData?.Segments[0][0]?.DepCountry?.split(
                                  " ,"
                                )[0]
                              }{" "}
                              ({flightData?.Segments[0][0]?.DepFrom}){" - "}
                              {
                                flightData?.Segments[0][
                                  flightData?.Segments[0]?.length - 1
                                ]?.ArrCountry?.split(" ,")[0]
                              }{" "}
                              (
                              {
                                flightData?.Segments[0][
                                  flightData?.Segments[0]?.length - 1
                                ]?.ArrTo
                              }
                              )
                              <br />
                              {
                                flightData?.Segments[0][
                                  flightData?.Segments[0]?.length - 1
                                ]?.ArrCountry?.split(" ,")[0]
                              }{" "}
                              (
                              {
                                flightData?.Segments[0][
                                  flightData?.Segments[0]?.length - 1
                                ]?.ArrTo
                              }
                              ){" - "}
                              {
                                flightData?.Segments[0][0]?.DepCountry?.split(
                                  " ,"
                                )[0]
                              }{" "}
                              ({flightData?.Segments[0][0]?.DepFrom})
                            </Typography>
                            <Typography color="#fff">
                              {tripType === "oneway"
                                ? "One Way"
                                : tripType === "return"
                                ? "Return"
                                : "Multi City"}{" "}
                              Flight <span>|</span>{" "}
                              {adultCount > 0 && `Adult(${adultCount})`}
                              {childCount > 0 && `Children(${childCount})`}
                              {infant > 0 && `Infant(${infant})`} <span>|</span>{" "}
                              {format(
                                new Date(flightData?.Segments[0][0]?.DepTime),
                                "dd MMM yyyy"
                              )}
                              {" | "}
                              {format(
                                new Date(flightData?.Segments[0][0]?.ArrTime),
                                "dd MMM yyyy"
                              )}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              sx={{
                                backgroundColor: "var(--primary-color)",
                                padding: "4px 0 4px 3vw",
                                color: "var(--white)",
                                fontWeight: 500,
                                width: { xs: "100%", sm: "30%", md: "25%" },
                              }}
                            >
                              Flight Details
                            </Typography>
                            <Box
                              bgcolor="var(--bgcolor)"
                              margin={{ xs: "15px", md: "2vw 3vw" }}
                              padding="8px 20px"
                            >
                              <Box
                                sx={{
                                  width: "100%",
                                  height: "0.5px",
                                  bgcolor: "var(--primary-color)",
                                  position: "relative",
                                  my: 3,
                                }}
                              >
                                <Typography
                                  sx={{
                                    bgcolor: "var(--white)",
                                    position: "absolute",
                                    width: "fit-content",
                                    px: 2,
                                    top: "50%",
                                    left: "50%",
                                    translate: "-50%  -50%",
                                    borderRadius: 2,
                                    fontSize: 12,
                                  }}
                                >
                                  City 1
                                </Typography>
                              </Box>
                              {flightData?.Segments?.map((flight, i, arr) => (
                                <Box>
                                  {flight?.map((data, j, flightarr) => (
                                    <Box my={2}>
                                      <Grid container spacing={2}>
                                        <Grid item xs={5} sm={6} md={2.5}>
                                          <Box
                                            width={{ xs: "20px", sm: "40px" }}
                                            height={{ xs: "20px", sm: "40px" }}
                                          >
                                            <img
                                              src={`https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/${data?.MarkettingCarrier}.svg`}
                                              alt="flight logo"
                                              width="100%"
                                              height="100%"
                                            />
                                          </Box>
                                          <Typography
                                            sx={{
                                              color: "var(--primary-color)",
                                              fontWeight: 500,
                                              fontSize: "12px",
                                            }}
                                            noWrap
                                          >
                                            {data?.MarkettingCarrierName}
                                            <br />
                                            <span
                                              style={{
                                                color: "var(--fontcolor)",
                                              }}
                                            >
                                              {data?.MarkettingCarrier}
                                              {"-"}
                                              {data?.MarkettingCarrierNumber}
                                            </span>
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={7} sm={6} md={3.5}>
                                          <Box>
                                            <Typography
                                              sx={{
                                                color: "var(--primary-color)",
                                                fontSize: {
                                                  xs: 12,
                                                  sm: 16,
                                                  md: 20,
                                                },
                                              }}
                                            >
                                              {data?.DepCountry?.split(" ,")[0]}
                                              {", "}
                                              <span
                                                style={{ fontSize: "12px" }}
                                              >
                                                {data?.DepFrom}
                                              </span>
                                            </Typography>
                                            <Tooltip
                                              title={`${data?.DepAirport}`}
                                            >
                                              <Typography
                                                sx={{
                                                  color: "var(--white)",
                                                  fontSize: "13px",
                                                }}
                                                noWrap
                                              >
                                                {data?.DepAirport}
                                              </Typography>
                                            </Tooltip>

                                            <Typography
                                              sx={{
                                                color: "var(--fontcolor)",
                                                fontSize: "12px",
                                              }}
                                            >
                                              {new Date(
                                                data?.DepTime
                                              ).toLocaleString("en-uk", {
                                                day: "numeric",
                                                month: "short",
                                                year: "numeric",
                                                hour: "numeric",
                                                minute: "numeric",
                                                hour12: false,
                                              })}
                                            </Typography>
                                          </Box>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={5}
                                          sm={6}
                                          md={2.5}
                                          margin="auto"
                                        >
                                          <Box textAlign="center">
                                            <Tooltip title="Flight Duration">
                                              <Typography
                                                sx={{
                                                  color: "var(--primary-color)",
                                                  fontWeight: 500,
                                                  fontSize: {
                                                    xs: "10px",
                                                    md: "12px",
                                                  },
                                                }}
                                              >
                                                {data?.Duration?.split("PT")[1]}
                                              </Typography>
                                            </Tooltip>
                                            <Box className="stop-bar-parent">
                                              <CircleIcon
                                                sx={{
                                                  color: "var(--transit)",
                                                  fontSize: "15px",
                                                }}
                                              />
                                              <Box className="stop-bar-line-details"></Box>
                                              <CircleIcon
                                                sx={{
                                                  color: "var(--transit)",
                                                  fontSize: "15px",
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Grid>
                                        <Grid item xs={7} sm={6} md={3.5}>
                                          <Box>
                                            <Typography
                                              sx={{
                                                color: "var(--primary-color)",
                                                fontSize: {
                                                  xs: 12,
                                                  sm: 16,
                                                  md: 20,
                                                },
                                              }}
                                            >
                                              {data?.ArrCountry?.split(" ,")[0]}
                                              {", "}
                                              <span
                                                style={{ fontSize: "12px" }}
                                              >
                                                {data?.ArrTo}
                                              </span>
                                            </Typography>
                                            <Tooltip
                                              title={`${data?.ArrAirport}`}
                                            >
                                              <Typography
                                                sx={{
                                                  color: "var(--white)",
                                                  fontSize: "13px",
                                                }}
                                                noWrap
                                              >
                                                {data?.ArrAirport}
                                              </Typography>
                                            </Tooltip>

                                            <Typography
                                              sx={{
                                                color: "var(--fontcolor)",
                                                fontSize: "12px",
                                              }}
                                            >
                                              {new Date(
                                                data?.ArrTime
                                              ).toLocaleString("en-uk", {
                                                day: "numeric",
                                                month: "short",
                                                year: "numeric",
                                                hour: "numeric",
                                                minute: "numeric",
                                                hour12: false,
                                              })}
                                            </Typography>
                                          </Box>
                                        </Grid>
                                      </Grid>

                                      <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                          <Typography
                                            sx={{
                                              color: "var(--white)",
                                              fontSize: 10,
                                              textAlign: "left",
                                            }}
                                          >
                                            Model: {data?.AircraftModel}
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={6}
                                          textAlign={{
                                            xs: "left",
                                            sm: "right",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              color: "var(--white)",
                                              fontSize: 10,
                                            }}
                                          >
                                            operated by{": "}
                                            {data?.OperatingCarrierName}
                                          </Typography>{" "}
                                        </Grid>
                                      </Grid>

                                      <Box
                                        sx={{
                                          display: `${
                                            j - (flightarr?.length - 1)
                                              ? "block"
                                              : "none"
                                          }`,

                                          position: "relative",
                                          py: 2,
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            bgcolor: "var(--white)",
                                            position: "absolute",
                                            px: 2,
                                            width: { xs: "80%", sm: "50%" },
                                            top: "50%",
                                            left: "50%",
                                            translate: "-50%  -50%",
                                            borderRadius: 2,
                                            fontSize: 12,
                                            textAlign: "center",
                                          }}
                                        >
                                          Layover&nbsp;Time&nbsp;
                                          {transitCalculation(
                                            flight[j + 1]?.DepTime,
                                            flight[j - 1 + 1]?.ArrTime
                                          )}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  ))}
                                  <Box
                                    sx={{
                                      display: `${
                                        i === arr?.length - 1 ? "none" : "block"
                                      }`,
                                      width: "100%",
                                      height: "0.5px",
                                      bgcolor: "var(--primary-color)",
                                      position: "relative",
                                      my: 3,
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        bgcolor: "var(--white)",
                                        position: "absolute",
                                        width: "fit-content",
                                        px: 2,
                                        top: "50%",
                                        left: "50%",
                                        translate: "-50%  -50%",
                                        borderRadius: 2,
                                        fontSize: 12,
                                      }}
                                    >
                                      City {i + 2}
                                    </Typography>
                                  </Box>
                                </Box>
                              ))}
                            </Box>
                          </Box>
                          <Box>
                            <Typography
                              sx={{
                                backgroundColor: "var(--primary-color)",
                                padding: "4px 0 4px 3vw",
                                color: "var(--white)",
                                fontWeight: 500,
                                width: { xs: "100%", sm: "30%", md: "25%" },
                              }}
                            >
                              Baggage
                            </Typography>
                            <Box
                              bgcolor="var(--bgcolor)"
                              margin={{ xs: "15px", md: "2vw 3vw" }}
                              padding="8px 20px"
                            >
                              <Box className="flight-search-table">
                                <table
                                  style={{
                                    borderCollapse: "collapse",
                                    width: "100%",
                                  }}
                                >
                                  <tr>
                                    <th>Baggage</th>
                                    <th>Check-In</th>
                                    <th>Cabin</th>
                                  </tr>

                                  <tr>
                                    <td>Adult</td>
                                    <td>
                                      {flightData?.Segments[0][0]?.Baggage[0]
                                        ?.type || ""}{" "}
                                      {flightData?.Segments[0][0]?.Baggage[0]
                                        .quantity || "1"}
                                      {"p"}
                                    </td>
                                    <td>7 Kg</td>
                                  </tr>
                                  {childCount > 0 && (
                                    <tr>
                                      <td>Child</td>
                                      <td>
                                        {flightData?.Segments[0][0]?.Baggage[0]
                                          ?.type || ""}{" "}
                                        {flightData?.Segments[0][0]?.Baggage[0]
                                          .quantity || "1"}
                                        {"p"}
                                      </td>
                                      <td>7 Kg</td>
                                    </tr>
                                  )}
                                  {infant > 0 && (
                                    <tr>
                                      <td>Infant</td>
                                      <td>
                                        {/* {flightData?.Segments[0]?.Baggage[0]
                                      ?.type || ""} */}
                                        {/* {flightData?.Segments[0].Baggage[0]
                                      .quantity || ""} */}
                                        0 {" p"}
                                      </td>
                                      <td>7 Kg</td>
                                    </tr>
                                  )}
                                </table>
                              </Box>
                            </Box>
                          </Box>

                          <Box>
                            <Typography
                              sx={{
                                backgroundColor: "var(--primary-color)",
                                padding: "4px 0 4px 3vw",
                                color: "var(--white)",
                                fontWeight: 500,
                                width: { xs: "100%", sm: "30%", md: "25%" },
                              }}
                            >
                              Fare Policy
                            </Typography>
                            <Box
                              bgcolor="var(--bgcolor)"
                              margin={{ xs: "15px", md: "2vw 3vw" }}
                              padding="8px 20px"
                            >
                              <Box mt={2}>
                                <Box
                                  style={{
                                    width: "130px",
                                    background: "var(--transit)",
                                    padding: "5px 15px",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontSize: "13px",
                                      color: "var(--primary-color)",
                                      fontFamily: "poppins",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Cancellation
                                  </Typography>
                                </Box>
                                <Typography
                                  style={{
                                    fontSize: "12px",
                                    color: "var(--gray)",
                                    fontFamily: "poppins",
                                    fontWeight: "500",
                                    marginTop: "5px",
                                    paddingLeft: "15px",
                                  }}
                                >
                                  Refund Amount = Paid Amount - Airline
                                  Cancellation Fee
                                </Typography>
                              </Box>
                              <Box mt={2}>
                                <Box
                                  style={{
                                    width: "130px",
                                    background: "var(--transit)",
                                    padding: "5px 15px",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontSize: "13px",
                                      color: "var(--primary-color)",
                                      fontFamily: "poppins",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Re-issue
                                  </Typography>
                                </Box>
                                <Typography
                                  style={{
                                    fontSize: "12px",
                                    color: "var(--gray)",
                                    fontFamily: "poppins",
                                    fontWeight: "500",
                                    marginTop: "5px",
                                    paddingLeft: "15px",
                                  }}
                                >
                                  Re-issue Fee = Airline’s Fee + Fare Difference
                                </Typography>
                              </Box>
                              <Box mt={2}>
                                <Box
                                  style={{
                                    width: "130px",
                                    background: "var(--transit)",
                                    padding: "5px 15px",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontSize: "13px",
                                      color: "var(--primary-color)",
                                      fontFamily: "poppins",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Refund
                                  </Typography>
                                </Box>
                                <Typography
                                  style={{
                                    fontSize: "12px",
                                    color: "var(--gray)",
                                    fontFamily: "poppins",
                                    fontWeight: "500",
                                    marginTop: "5px",
                                    paddingLeft: "15px",
                                  }}
                                >
                                  Refund Amount = Paid Amount - Airline
                                  Cancellation Fee
                                </Typography>
                              </Box>
                              <Box mt={2}>
                                <Box
                                  style={{
                                    width: "130px",
                                    background: "var(--transit)",
                                    padding: "5px 15px",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontSize: "13px",
                                      color: "var(--primary-color)",
                                      fontFamily: "poppins",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Void
                                  </Typography>
                                </Box>
                                <Typography
                                  style={{
                                    fontSize: "12px",
                                    color: "var(--gray)",
                                    fontFamily: "poppins",
                                    fontWeight: "500",
                                    marginTop: "5px",
                                    paddingLeft: "15px",
                                  }}
                                >
                                  Re-issue Fee = Airline’s Fee + Fare Difference
                                </Typography>
                              </Box>
                            </Box>
                          </Box>

                          {/* -------new end */}
                        </Box>
                      </Box>
                    </SwipeableDrawer>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* //todo: Desktop Desing Section */}
    </Box>
  );
};

export default SingleMultiCityItem;
