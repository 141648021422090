import { Container, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import Tab from "@mui/material/Tab";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import FlightIcon from "@mui/icons-material/Flight";
import GroupsIcon from "@mui/icons-material/Groups";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
import { addDays, format } from "date-fns";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./HomeSearchBox.css";

import { NavLink, Outlet } from "react-router-dom";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import TravelExploreOutlinedIcon from "@mui/icons-material/TravelExploreOutlined";
import MenuBookTwoToneIcon from "@mui/icons-material/MenuBookTwoTone";

import FlightSearchBox from "./../FlightSearchBox/FlightSearchBox";

const HomeSearchBox = ({ color, backgroundColor }) => {
  const navigate = useNavigate();
  //todo: state for retrigger useEffect
  const [changeState, setChangeState] = useState(null);
  //todo: End for retrigger useEffect
  //todo: state for from date change
  const [changeFrom, setChangeFrom] = useState(false);
  //todo: End state for from date change
  const [type, setType] = React.useState("flight");
  const [value, setValue] = React.useState("oneway");
  const [fromSearchText, setFromSearchText] = useState(
    "Suvarnabhumi Intl Airport (BKK)"
  );
  const [toSearchText, setToSearchText] = useState("Perth Intl Airport (PER)");

  const [departureDate, setDepartureDate] = useState(
    format(addDays(new Date(), 1), "dd MMM yy")
  );
  const [returningDate, setReturningDate] = useState(
    format(addDays(new Date(departureDate), 3), "dd MMM yy")
  );
  const [travelDate, setTravelDate] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 3),
      key: "selection",
    },
  ]);
  // for multiCity

  const now = useRef(new Date());
  const [from, setFrom] = useState(addDays(now.current, 1));
  const [to, setTo] = useState(addDays(now.current, 3));
  const [faddress, setfaddress] = useState("Bangkok,THAILAND");
  const [toAddress, setToAddress] = useState("Perth,AUSTRALIA");
  const [fromSendData, setFromSendData] = useState("BKK");
  const [toSendData, setToSendData] = useState("PER");
  const [adultCount, setAdultCount] = useState(1);
  const [childCount, setChildCount] = useState(0);
  const [infant, setInfant] = useState(0);
  const [result, setResult] = useState(1);
  const [className, setClassName] = useState("Economy");

  const handleTypeChange = (event, newValue) => {
    setType(newValue);
  };

  // For Oneway
  // For return

  return (
    <Box className="home-search-box-wrapper">
      <Container>
        <Box
          className="searchBar-header"
          sx={{
            mt: 5,
            bgcolor: "#2F2F31",
            py: 5,
            px: { xs: 0, sm: 2, md: 4 },
            borderRadius: "5px",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                width: { xs: "95%", sm: "80%", md: "70%" },
                justifyContent: { x: "", md: "space-between" },
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                margin: "auto",
                borderRadius: "10px",
                background: "var(--primary-color)",
                // position: "relative",
                zIndex: 1000,
                mb: 2.5,
                overflow: "hidden",
              }}
            >
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? "active-links" : "links"
                }
                style={{ fontSize: "14px" }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <FlightIcon sx={{ fontSize: 30, rotate: "30deg" }} />{" "}
                  <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                    Flight
                  </Typography>
                </Stack>
              </NavLink>
              <NavLink
                to="/comingsoon"
                className={({ isActive }) =>
                  isActive ? "active-links" : "links"
                }
                style={{ fontSize: "14px" }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <HomeWorkOutlinedIcon sx={{ fontSize: 30 }} />{" "}
                  <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                    Hotel
                  </Typography>
                </Stack>
              </NavLink>
              <NavLink
                to="/comingsoon"
                className={({ isActive }) =>
                  isActive ? "active-links" : "links"
                }
                style={{ fontSize: "14px" }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <TravelExploreOutlinedIcon
                    sx={{ fontSize: 30, rotate: "30deg" }}
                  />
                  <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                    Tour
                  </Typography>
                </Stack>
              </NavLink>

              <NavLink
                to="/comingsoon"
                className={({ isActive }) =>
                  isActive ? "active-links" : "links"
                }
                style={{ fontSize: "14px" }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <MenuBookTwoToneIcon sx={{ fontSize: 30 }} />
                  <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                    Visa
                  </Typography>
                </Stack>
              </NavLink>
            </Box>
            <Outlet></Outlet>
          </Box>
          <FlightSearchBox
            type={type}
            setType={setType}
            value={value}
            setValue={setValue}
            fromSearchText={fromSearchText}
            setFromSearchText={setFromSearchText}
            toSearchText={toSearchText}
            setToSearchText={setToSearchText}
            departureDate={departureDate}
            setDepartureDate={setDepartureDate}
            returningDate={returningDate}
            setReturningDate={setReturningDate}
            travelDate={travelDate}
            setTravelDate={setTravelDate}
            from={from}
            setFrom={setFrom}
            to={to}
            setTo={setTo}
            faddress={faddress}
            setfaddress={setfaddress}
            toAddress={toAddress}
            setToAddress={setToAddress}
            fromSendData={fromSendData}
            setFromSendData={setFromSendData}
            toSendData={toSendData}
            setToSendData={setToSendData}
            adultCount={adultCount}
            setAdultCount={setAdultCount}
            childCount={childCount}
            setChildCount={setChildCount}
            infant={infant}
            setInfant={setInfant}
            result={result}
            setResult={setResult}
            className={className}
            setClassName={setClassName}
            setChangeState={setChangeState}
            changeState={changeState}
            changeFrom={changeFrom}
            setChangeFrom={setChangeFrom}
            // searchData={searchData}
            // setSearchData={setSearchData}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default HomeSearchBox;
