import React, { useState, useEffect, useRef } from "react";
import { Container } from "@mui/system";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Slider from "@mui/material/Slider";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import WbTwilightOutlinedIcon from "@mui/icons-material/WbTwilightOutlined";
import { AiFillCaretDown } from "react-icons/ai";
import LocalFloristSharpIcon from "@mui/icons-material/LocalFloristSharp";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "../pages/SearchReslut/SearchResult.css";

import {
  Box,
  Checkbox,
  Grid,
  MenuItem,
  Button,
  FormGroup,
  FormControl,
  FormControlLabel,
  Select,
  Typography,
  Drawer,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import commaNumber from "comma-number";
import AirlinesNameSlider from "./AirlinesNameSlider/AirlinesNameSlider";

const OneWayFilter = ({
  filteredData,
  setfilteredData,
  data,
  setData,
  noData,
  setNoData,
  departureDate,
  setFrom,
}) => {
  const location = useLocation();
  let arr = [];
  let flightprice = data;
  flightprice.map((dat) => {
    arr.push(dat?.TotalFare);
    return arr;
  });

  const maxPrice = Math.max(...arr);
  const minPrice = Math.min(...arr);

  const [selectPrice, setSelectPrice] = useState([minPrice, maxPrice]);
  const [refundable, setRefundable] = useState(false);
  const [nonRefundable, setNonRefundable] = useState(false);
  const [directFlight, setDirectFlight] = useState(false);
  const [oneStopFlight, setOneStopFlight] = useState(false);
  const [multiStopFlight, setMultiStopFlight] = useState(false);

  const handleRefundable = (e) => {
    let updatedflight = data;
    setNonRefundable(false);
    setRefundable(e.target.checked);
    if (e.target.checked && selectPrice) {
      updatedflight = updatedflight.filter(
        (item) => item?.FareRules?.refund_before_departure?.allowed === true
      );
      setfilteredData(updatedflight);
    } else {
      setNoData(noData);
    }
  };

  const handleNonRefundable = (e) => {
    let updatedflight = data;
    setRefundable(false);
    setNonRefundable(e.target.checked);
    if (e.target.checked) {
      updatedflight = updatedflight.filter(
        (item) => item.FareRules?.refund_before_departure?.allowed !== true
      );
      setfilteredData(updatedflight);
    } else {
      setNoData(noData);
    }
  };
  const handleChangePrice = (event, newPrice) => {
    setSelectPrice(newPrice);
    let updatedflight = data;
    const selectMinPrice = selectPrice[0];
    const selectMaxPrice = selectPrice[1];
    if (selectPrice) {
      updatedflight = updatedflight.filter(
        (item) =>
          item.TotalFare >= selectMinPrice && item.TotalFare <= selectMaxPrice
      );
      setfilteredData(updatedflight);
    } else if (selectPrice && refundable) {
      setRefundable(true);
      updatedflight = updatedflight.filter(
        (item) =>
          item.TotalFare >= selectMinPrice &&
          item.TotalFare <= selectMaxPrice &&
          item.refundable === "Refundable"
      );
      setfilteredData(updatedflight);
    } else if (selectPrice && nonRefundable) {
      setNonRefundable(true);
      updatedflight = updatedflight.filter(
        (item) =>
          item.TotalFare >= selectMinPrice &&
          item.TotalFare <= selectMaxPrice &&
          item.refundable === "Nonrefundable"
      );
      setfilteredData(updatedflight);
    } else {
      setNoData(noData);
    }
  };

  // --------
  const handleDirectFlight = (e) => {
    let updatedflight = data;
    setDirectFlight(e.target.checked);
    setOneStopFlight(false);
    setMultiStopFlight(false);
    if (e.target.checked) {
      updatedflight = updatedflight.filter(
        (item) => item.Segments.length === 1
      );
      setfilteredData(updatedflight);
    } else if (e.target.checked && refundable) {
      updatedflight = updatedflight.filter(
        (item) =>
          item.Segments.length === "1" &&
          item.FareRules?.refund_before_departure?.allowed === true
      );
      setfilteredData(updatedflight);
    } else if (e.target.checked && nonRefundable) {
      updatedflight = updatedflight.filter(
        (item) =>
          item.Segments.length === "1" &&
          item.FareRules?.refund_before_departure?.allowed !== true
      );
      setfilteredData(updatedflight);
    } else {
      setNoData(noData);
    }
  };
  const handleOneStopFlight = (e) => {
    let updatedflight = data;
    setDirectFlight(false);
    setOneStopFlight(e.target.checked);
    setMultiStopFlight(false);
    if (e.target.checked) {
      updatedflight = updatedflight.filter(
        (item) => item.Segments.length === 2
      );
      setfilteredData(updatedflight);
    } else if (e.target.checked && refundable) {
      updatedflight = updatedflight.filter(
        (item) =>
          item.Segments.length === "2" &&
          item.FareRules?.refund_before_departure?.allowed === true
      );
      setfilteredData(updatedflight);
    } else if (e.target.checked && nonRefundable) {
      updatedflight = updatedflight.filter(
        (item) =>
          item.Segments.length === "2" &&
          item.FareRules?.refund_before_departure?.allowed !== true
      );
      setfilteredData(updatedflight);
    } else {
      setNoData(noData);
    }
  };
  const handleMultiStopFlight = (e) => {
    let updatedflight = data;
    setMultiStopFlight(e.target.checked);
    setDirectFlight(false);
    setOneStopFlight(false);
    if (e.target.checked) {
      updatedflight = updatedflight.filter(
        (item) => item.Segments.length === 3
      );
      setfilteredData(updatedflight);
    } else if (e.target.checked && refundable) {
      updatedflight = updatedflight.filter(
        (item) =>
          item.Segments.length === "3" &&
          item.FareRules?.refund_before_departure?.allowed === true
      );
      setfilteredData(updatedflight);
    } else if (e.target.checked && nonRefundable) {
      updatedflight = updatedflight.filter(
        (item) =>
          item.Segments.length === "3" &&
          item.FareRules?.refund_before_departure?.allowed !== true
      );
      setfilteredData(updatedflight);
    } else {
      setNoData(noData);
    }
  };
  const [carearFlight, setCarearFlight] = useState([]);
  let carearArray = [];
  data.map((name) => {
    carearArray.push(name.Caree);
    return carearArray;
  });
  let carear = [];
  carearArray.forEach((i) => (carear[i] = false));
  let totalCarear = Object.keys(carear).map((item) => ({ name: item }));

  useEffect(() => {
    setCarearFlight(totalCarear);
  }, []);
  const [check, setCheck] = useState(false);
  const handleflightCarear = (e) => {
    let updatedflight = data;
    const { name, check } = e.target;
    if (name) {
      updatedflight = updatedflight.filter((item) => item.Caree === name);
      setCheck(check);
      setfilteredData(updatedflight);
    }
  };

  const handleResetBtn = () => {
    setSelectPrice([minPrice, maxPrice]);
    setRefundable(false);
    setNonRefundable(false);
    setDirectFlight(false);
    setOneStopFlight(false);
    setMultiStopFlight(false);
    setfilteredData(data);
  };

  return (
    <Box
      className="flight-filter1 filter-side-0"
      sx={{
        boxShadow:
          "-0.452679px 4.97947px 36px rgba(0, 0, 0, 0.09), -0.0905357px 0.995893px 5.85px rgba(0, 0, 0, 0.045)",
        width: "100%",
        bgcolor: "var(--bgcolor)",
      }}
      p={2}
    >
      <Box
        display="flex"
        justifyContent={"space-between"}
        alignItems={"center"}
        padding="15px 10px 10px 0px"
      >
        <Typography
          sx={{
            color: "var(--white)",
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          FILTER
        </Typography>
        <Typography
          onClick={handleResetBtn}
          sx={{
            cursor: "pointer",
            color: "var(--white)",
            p: "5px 10px",
            borderRadius: "5px",
          }}
        >
          Reset
        </Typography>
      </Box>

      <Accordion
        defaultExpanded={true}
        style={{
          margin: "0",
          boxShadow: "none",
          backgroundColor: "transparent",
        }}
      >
        <AccordionSummary
          expandIcon={<AiFillCaretDown color="var(--white)" />}
          id="panel1a-header"
          style={{ margin: "0", padding: "0px" }}
        >
          <Typography style={{ color: "var(--white)", fontWeight: 500 }}>
            Price Range
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          className="AccordionDetails22"
          style={{ margin: "0", paddingLeft: "12px" }}
        >
          <Box>
            <Grid item className="price-slider-line">
              <Slider
                value={selectPrice}
                onChange={handleChangePrice}
                valueLabelDisplay="auto"
                min={minPrice}
                max={maxPrice}
              />
            </Grid>
            <Grid container justifyContent={"space-between"}>
              <Typography
                sx={{
                  color: "var(--white)",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                {commaNumber(minPrice)}
              </Typography>
              <Typography
                sx={{
                  color: "var(--white)",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                {" "}
                {commaNumber(maxPrice)}
              </Typography>
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        defaultExpanded={true}
        style={{
          margin: "0",
          boxShadow: "none",
          backgroundColor: "transparent",
        }}
      >
        <AccordionSummary
          style={{ margin: "0", padding: "0px" }}
          expandIcon={<AiFillCaretDown color="var(--white)" />}
          id="panel2a-header"
        >
          <Typography
            sx={{
              color: "var(--white)",
              fontSize: "16px",
              fontWeight: 500,
            }}
          >
            Fare Type
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ margin: "0", padding: "0" }}>
          <Box>
            <FormGroup>
              <FormControlLabel
                value={"refundable"}
                control={<Checkbox sx={{ color: "var(--white)" }} />}
                checked={refundable}
                onChange={handleRefundable}
                label={
                  <Typography sx={{ color: "var(--white)", fontSize: 14 }}>
                    Refundable
                  </Typography>
                }
              />
              <FormControlLabel
                value={"NonRefundable"}
                control={<Checkbox sx={{ color: "var(--white)" }} />}
                checked={nonRefundable}
                onChange={handleNonRefundable}
                label={
                  <Typography sx={{ color: "var(--white)", fontSize: 14 }}>
                    Non Refundable
                  </Typography>
                }
              />
            </FormGroup>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        defaultExpanded={true}
        style={{
          margin: "0",
          boxShadow: "none",
          backgroundColor: "transparent",
        }}
      >
        <AccordionSummary
          expandIcon={<AiFillCaretDown color="var(--white)" />}
          id="panel2a-header"
          style={{ margin: "0", padding: "0" }}
        >
          <Typography
            sx={{
              color: "var(--white)",
              fontSize: "16px",
              fontWeight: 500,
            }}
          >
            Stops
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ margin: "0", padding: "0" }}>
          <Box sx={{ width: "100%" }}>
            <FormGroup>
              <FormControlLabel
                onChange={handleDirectFlight}
                checked={directFlight}
                control={<Checkbox sx={{ color: "var(--white)" }} />}
                label={
                  <Typography sx={{ color: "var(--white)", fontSize: 14 }}>
                    Non Stop{" "}
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox sx={{ color: "var(--white)" }} />}
                checked={oneStopFlight}
                onChange={handleOneStopFlight}
                label={
                  <Typography sx={{ color: "var(--white)", fontSize: 14 }}>
                    One Stops{" "}
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox sx={{ color: "var(--white)" }} />}
                checked={multiStopFlight}
                onChange={handleMultiStopFlight}
                label={
                  <Typography sx={{ color: "var(--white)", fontSize: 14 }}>
                    One Plus Stops{" "}
                  </Typography>
                }
              />
            </FormGroup>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        defaultExpanded={true}
        style={{
          margin: "0",
          boxShadow: "none",
          backgroundColor: "transparent",
        }}
      >
        <AccordionSummary
          expandIcon={<AiFillCaretDown color="var(--white)" />}
          id="panel2a-header"
          style={{ margin: "0", padding: "0" }}
        >
          <Typography
            sx={{
              color: "var(--white)",
              fontSize: "16px",
              fontWeight: 500,
            }}
          >
            Airlines
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ margin: "0", padding: "0" }}>
          <Box sx={{ width: "100%" }}>
            <AirlinesNameSlider
              data={data}
              setData={setData}
              filteredData={filteredData}
              setfilteredData={setfilteredData}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default OneWayFilter;
