import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import BookingAll from "../BookingPage/BookingAll";
import BookingHold from "../BookingPage/BookingHold";
import IssueRequest from "../BookingPage/IssueRequest";
import Ticketed from "../BookingPage/Ticketed";
import IssueRejected from "../BookingPage/IssueRejected";
import "./BookingRoute.css";
import ReissueRequest from "../BookingPage/ReissueRequest";
import Reissued from "../BookingPage/Reissued";
import ReissueRejected from "../BookingPage/ReissueRejected";
import RefundRequest from "../BookingPage/RefundRequest";
import Refund from "../BookingPage/Refund";
import RefundRejected from "../BookingPage/RefundRejected";
import VoidRequest from "../BookingPage/VoidRequest";
import Voided from "../BookingPage/Voided";
import VoidedRejected from "../BookingPage/VoidedRejected";
import BookingFailed from "../BookingPage/BookingFailed";
import BookingCancelled from "../BookingPage/BookingCancelled";
import { useNavigate } from "react-router-dom";
import Logo from "../../../images/Logo.PNG";

const BookingRoute = () => {
  const navigate = useNavigate();
  const [optionValue, setOptionValue] = useState("");

  const handleChangeOption = (e) => {
    setOptionValue(e.target.value);
  };

  return (
    <Box style={{ backgroundColor: "var(--secondary-color)" }}>
      <Container>
        {/*----------- header -------------*/}
        <Box py={4}>
          <Grid
            container
            spaceing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Box
                sx={{
                  background: "transparent",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/")}
              >
                <img src={Logo} alt="Travel Sense" width="180px" />
              </Box>
            </Grid>
            <Grid item>
              <Box>
                <Typography
                  style={{
                    color: "#fff",
                    background: "var(--bgcolor)",
                    padding: "8px 10px",
                    width: "200px",
                  }}
                >
                  Booking Management
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography
              variant="span"
              sx={{
                fontWeight: 500,
                fontSize: "20px",
                margin: "10px 0px",
                color: "var(--fontcolor)",
                fontFamily: "poppins",
              }}
            >
              Air Ticket Live Booking
            </Typography>
          </Box>
          <Box>
            <select
              name="transection"
              id="transectopn"
              style={{
                background: "var(--sweet-color)",
                border: "none",
                color: "#fff",
                width: "130px",
                height: "30px",
                padding: "5px",
              }}
              onChange={handleChangeOption}
            >
              <option value="all">&nbsp;Show All</option>
              <option value="hold">&nbsp;Hold</option>
              <option value="Issue In Processing">&nbsp;Issue Request</option>
              <option value="Ticketed">&nbsp;Ticketed</option>
              {/* <option value="Issue Rejected">&nbsp;Issue Rejected</option>
              <option value="Reissue In Processing">
                &nbsp;Reissue Request
              </option>
              <option value="Reissued">&nbsp;Reissued</option>
              <option value="Reissue Rejected">&nbsp;Reissue Rejected</option>
              <option value="Refund In Processing">&nbsp;Refund Request</option>
              <option value="Refunded">&nbsp;Refunded</option>
              <option value="Refund Rejected">&nbsp;Refund Rejected</option>
              <option value="Void In Processing">&nbsp;Void Request</option>
              <option value="Voided">&nbsp;Voided</option>
              <option value="Void Rejected">&nbsp;Void Rejected</option>
              <option value="Booking Failed">&nbsp;Booking Failed</option> */}
              <option value="cancelled">&nbsp;Cancelled</option>
            </select>
          </Box>
        </Box>

        {(optionValue === "all" || optionValue === "") && <BookingAll />}
        {optionValue === "hold" && <BookingHold />}
        {optionValue === "Issue In Processing" && <IssueRequest />}
        {optionValue === "Ticketed" && <Ticketed />}
        {/* {optionValue === "Issue Rejected" && <IssueRejected />}
        {optionValue === "Reissue In Processing" && <ReissueRequest />}
        {optionValue === "Reissued" && <Reissued />}
        {optionValue === "Reissue Rejected" && <ReissueRejected />}
        {optionValue === "Refund In Processing" && <RefundRequest />}
        {optionValue === "Refunded" && <Refund />}
        {optionValue === "Refund Rejected" && <RefundRejected />}
        {optionValue === "Void In Processing" && <VoidRequest />}
        {optionValue === "Voided" && <Voided />}
        {optionValue === "Void Rejected" && <VoidedRejected />}
        {optionValue === "Booking Failed" && <BookingFailed />} */}
        {optionValue === "cancelled" && <BookingCancelled />}
      </Container>
    </Box>
  );
};

export default BookingRoute;
